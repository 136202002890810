import { notification } from 'antd'
import API from '@aws-amplify/api'
// import moment from 'moment'
import { apiPaths, urls } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  GET_DEVICE_SESSIONS: 'device/GET_DEVICE_SESSIONS',
  SET_STATE: 'device/SET_STATE',
  GET_DEVICES: 'device/GET_DEVICES',
  GET_DEVICE_ACTIVITY: 'device/GET_DEVICE_ACTIVITY',
  GET_CLINIC_DEVICE_ACTIVITY: 'device/GET_CLINIC_DEVICE_ACTIVITY',
  ADD_DEVICE: 'device/ADD_DEVICE',
  GET_INVENTORY_DEVICES: 'device/GET_INVENTORY_DEVICES',
  UPDATE_DEVICE: 'device/UPDATE_DEVICE',
  ADD_DEVICE_INVENTORY: 'device/ADD_DEVICE_INVENTORY',
  DELETE_INVENTORY_DEVICE: 'device/DELETE_INVENTORY_DEVICE',
  ASSIGN_DEVICE_TO_USER: 'device/ASSIGN_DEVICE_TO_USER',
  GET_DEVICE_DETAIL: 'device/GET_DEVICE_DETAIL',
  GET_DEVICE_SLEEP_STAGES_HISTORY: 'device/GET_DEVICE_SLEEP_STAGES_HISTORY',
  DEVICE_BODY_CLOCK: 'device/DEVICE_BODY_CLOCK',
  GET_NIGHT_BY_NIGHT_DATA: 'device/GET_NIGHT_BY_NIGHT_DATA',
  GET_DEVICE_AVG_SLEEP_METRICS: 'device/GET_DEVICE_AVG_SLEEP_METRICS',
  CHANGE_DEVICE_NAME: 'device/CHANGE_DEVICE_NAME',
  RETURN_DEVICE: 'device/RETURN_DEVICE',
  GET_DEVICE_TRENDS: 'device/GET_DEVICE_TRENDS',
  LINK_PATIENT: 'device/LINK_PATIENT',
  GET_PATIENT_SESSION_DATES: 'device/GET_PATIENT_SESSION_DATES',
  GET_DEVICE_PATIENTS: 'device/GET_DEVICE_PATIENTS',
}

function getMyInIt(deviceID) {
  const checkObjorString = isObject(deviceID)

  let myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      device_id: deviceID,
    },
  }
  if (checkObjorString) {
    myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      queryStringParameters: deviceID,
    }
  }
  return myInit
}
function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object
}
/* function bodyClockInit(Obj) {
  const myInit = {
    headers: {},
    queryStringParameters: {
      user_id: Obj.userUID,
      graph: Obj.graph,
    },
  }
  return myInit
} */
function SleepStagesHistory(deviceID, date) {
  const checkObjorString = isObject(deviceID)

  let myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      device_id: deviceID,
      date_of_sleep: date,
    },
  }
  if (checkObjorString) {
    deviceID.date_of_sleep = date
    myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      queryStringParameters: deviceID,
    }
  }
  return myInit
}
// API call to get a List of All Devices filtered by the current loggeed in Provider if a Provider is logged in, or unfiltered if Superadmin is logged in.
export const getAllDevices = async () =>
  API.get('virtualClinic', '/virtualclinic-temp/v1/allDevices')
    .then((res) => res)
    .catch((err) => {
      console.log('deviceList', err)
      return []
    })

// API call to get a List of a Specified Device Activity
export const getDeviceActivity = async (deviceID) =>
  API.get('virtualClinic', '/virtualclinic-temp/v1/deviceActivity', {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      device_uid: deviceID,
    },
  })
    .then((res) => res)
    .catch((err) => {
      console.log('deviceList', err)
      return []
    })

export const getClinicDeviceActivity = async (deviceID) =>
  API.get('virtualClinic', apiPaths.Clinic.apiClinicDeviceActivity, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      device_uid: deviceID,
    },
  })
    .then((res) => res)
    .catch((err) => {
      notification.error({
        message: `Someting went wrong`,
        description: `Someting went wrong ${err}`,
      })
      return []
    })

// API call to add a new Device (SuperAdmin Only)
export const addDevice = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiDeviceCreate, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then((res) => {
      notification.success({
        message: `Success`,
        description: 'Device created successfully',
      })
      return res
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return []
    })

// API call to update a specific Device (SuperAdmin Only)
export const updateDevice = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiDeviceUpdate, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then((res) => {
      notification.success({
        message: `Success`,
        description: 'Device updated successfully',
      })
      return res
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? err.response.data : err}`,
      })
      return []
    })

// API call to get a Assign Devices to a Specific Provider (SuperAdmin Only)
export const assignDeviceToProvider = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiDeviceAssign, {
    headers: {},
    body: dataObj,
  })
    .then((res) => {
      notification.success({
        message: `Success`,
        description: 'Device Assigned successfully',
      })
      return res
    })
    .catch((err) => {
      notification.error({
        message: `Someting went wrong`,
        description: `Someting went wrong ${err}`,
      })
      return []
    })

// API call to add a specific Assigned Device back in Inventory (SuperAdmin Only)
export const addDeviceInInventory = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiDeviceAddInStock, {
    // Optional
    headers: {},
    body: dataObj,
  })
    .then((res) => {
      notification.success({
        message: `Success`,
        description: 'Device updated successfully',
      })
      return res
    })
    .catch((err) => {
      notification.error({
        message: `Someting went wrong`,
        description: `${err.response && err.response.data ? err.response.data : err}`,
      })
      return []
    })

// API call to get a List of all the devices available in the current logged in Users Inventory
export const getAllInventoryDevices = async (role, adminId, status) =>
  API.get('virtualClinic', apiPaths[role] ? apiPaths[role].apiDeviceDevices : '', {
    headers: {},
    queryStringParameters: {
      status,
      admin_id: adminId,
    },
  })
    .then((res) => res)
    .catch((err) => {
      console.log('deviceList', err)
      return []
    })

// API call to delete a specific Device
export const deleteDevice = (role, dataObj) =>
  API.post('virtualClinic', apiPaths[role].apiDeviceDelete, {
    // Optional
    headers: {},
    body: dataObj,
  })
    .then((res) => {
      notification.success({
        message: `Success`,
        description: 'Device deleted successfully',
      })
      return res
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return err
    })

/* CLINIC MANAGEMENT SYSTEM DEVICE PROFILE APIs */
export const getDeviceDetail = (deviceID = false) =>
  API.get('virtualClinic', urls.deviceAPIs.deviceDetail, deviceID ? getMyInIt(deviceID) : '')
    .then((res) => res)
    .catch((err) => {
      console.log('device Details', err)
      return []
    })

export const getDeviceMetricsHistory = (data) =>
  API.get('virtualClinic', urls.deviceAPIs.deviceMetricsHistory, {
    headers: {},
    queryStringParameters: data,
  })
    .then((res) => res)
    .catch((err) => {
      console.log('Error in deviceMetricsHistory ', err)
      return []
    })

export const returnDevice = (data) =>
  API.post('virtualClinic', urls.deviceAPIs.returnDevice, {
    headers: {},
    body: data,
  })
    .then((res) => {
      notification.success({
        message: 'Success',
        description: 'Device Removed Successfully',
      })
      return res
    })
    .catch((err) => {
      const { response } = err
      notification.warning({
        message: 'Something went wrong',
        description: `${response && response.data ? response.data : err}`,
      })
      console.log('returnDevice ', err)
      return {}
    })

export const getClinicDevices = (data) =>
  API.get('virtualClinic', urls.deviceAPIs.deviceDetail, {
    headers: {},
    queryStringParameters: data,
  })
    .then((res) => res)
    .catch((err) => {
      console.log('device Details', err)
      return []
    })

export const getDeviceSession = (deviceID, date = false) =>
  API.get(
    'virtualClinic',
    urls.deviceAPIs.deviceSession,
    date ? SleepStagesHistory(deviceID, date) : getMyInIt(deviceID),
  )
    .then((res) => res)
    .catch((err) => {
      const { response } = err
      console.log('error getDeviceSession', !response ? err : response)
      return {}
    })

export const getDeviceSessions = (deviceID) =>
  API.get('virtualClinic', urls.deviceAPIs.allDeviceSessions, getMyInIt(deviceID))
    .then((res) => res)
    .catch((err) => {
      const { response } = err
      console.log('getDeviceSessions', !response ? err : response)
      return []
    })

export const getLastSleepStagesHistory = (userUID) =>
  API.get('virtualClinic', apiPaths.All.apiMemberSleepStages, {
    // OPTIONAL
    queryStringParameters: {
      user_uid: userUID,
    },
    headers: {}, // OPTIONAL
  })
    .then((res) => res)
    .catch((err) => {
      const { response } = err
      console.log('error getLastSleepStagesHistory', !response ? err : response)
      return { status: 404 }
    })

export const getDeviceAVGSleepMetrics = (memberID) =>
  API.get('virtualClinic', apiPaths.All.apiAVGSLeepMetrics, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      member_id: memberID,
    },
  })
    .then((res) => res)
    .catch((err) => {
      console.log('AVG sleep metrcis error: ', err)
      return []
    })

export const getDeviceBodyClock = (dataObj) =>
  API.get('virtualClinic', apiPaths.Clinic.apiClinicDeviceBodyClock, {
    headers: {},
    queryStringParameters: dataObj,
  })
    .then((res) => {
      const { img } = res
      const { graph } = dataObj

      if (graph === 'process_c') {
        return res
      }
      if (img) {
        return img
      }

      return res
    })
    .catch((err) => {
      console.log('bodyclocktimeline', err)
      return {}
    })

export const linkPatient = (data) =>
  API.post('virtualClinic', urls.deviceAPIs.linkPatient, {
    headers: {},
    body: data,
  })
    .then((res) => {
      notification.success({
        message: 'Success',
        description: 'Patient Linked Successfully',
      })
      return res
    })
    .catch((err) => {
      const { response } = err
      notification.warning({
        message: 'Something went wrong',
        description: `${response && response.data ? response.data : err}`,
      })
      console.log('linkedPatient ', err)
      return {}
    })

export const getPatientSessions = async (data) =>
  API.get('virtualClinic', urls.deviceAPIs.patientSessionsDate, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: data,
  })
    .then((res) => res)
    .catch((err) => {
      console.log('getPatientSessions', err)
      return []
    })

export const getDevicePatientsList = async (data) =>
  API.get('virtualClinic', urls.deviceAPIs.patientsList, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: data,
  })
    .then((res) => res)
    .catch((err) => {
      console.log('getDevicePatientList', err)
      return []
    })

export default actions
