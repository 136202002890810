import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import actions, {
  getAllMembers,
  getAllActiveMembers,
  addMember,
  updateMember,
  getMemberDetail,
  setStatus,
  deleteMember,
  getACMAPI,
  getTotalSleepSessions,
  getAccess,
  getSharedMembers,
} from './actions'

// Defined constant function to get user object stored in Redux Store
const getUser = state => state.user

// Function to get Profile Data of a specific Member from the Database and store it in Redux Store.
export function* GET_MEMBER_DETAIL({ payload }) {
  const { memberID } = payload
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const memberDetail = yield call(getMemberDetail, memberID)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: false,
      memberDetail,
      redirect: false,
    },
  })
  return true
}

// Function to get Profile Data of a specific Provider from the Database and store it in Redux Store.
export function* GET_PROVIDER_PROFILE({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      providerMembersloading: true,
    },
  })
  const { admin_id: memberID } = payload
  const providerDetail = yield call(getMemberDetail, memberID)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      providerDetail,
    },
  })
  yield call(GET_PROVIDER_MEMBERS, { payload: { data: payload } })

  return true
}

// Function to get a List of all the Members Registered under a specific Provider and to be stored in provider Variables of Redux Store
export function* GET_PROVIDER_MEMBERS({ payload }) {
  const { role } = yield select(getUser)
  const { data } = payload
  const sharedData = { coach_id: data.admin_id }
  let providerMembers = yield call(getAllMembers, data, role)
  providerMembers = providerMembers.data
  let providerActiveMembers = yield call(getAllActiveMembers, data, role)
  providerActiveMembers = providerActiveMembers.data
  const providerTotalSleepSessions = yield call(getTotalSleepSessions, data, role)
  let providerSharedMembers = yield call(getSharedMembers, sharedData, role)
  providerSharedMembers = providerSharedMembers.data
  yield put({
    type: 'member/SET_STATE',
    payload: {
      providerMembersloading: false,
      providerActiveMembers,
      providerMembers,
      providerSharedMembers,
      providerTotalSleepSessions,
    },
  })

  return {
    providerMembersloading: false,
    providerActiveMembers,
    providerMembers,
    providerSharedMembers,
    providerTotalSleepSessions,
  }
}

// Function to get a List of all the Members Registered under a specific Provider and to be stored in the Main Variables of Redux Store
export function* GET_MEMBERS({ payload }) {
  const { role, details } = yield select(getUser)
  let { data } = payload
  let { filteredType } = data
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
      lastFormValues: {},
    },
  })

  if (!data) {
    data = {}
  }
  const sharedData = {}
  if (role !== 'Superadmin') {
    data.admin_id = details.user_id
    sharedData.coach_id = details.user_id
  }
  if (!filteredType) {
    filteredType = ''
  }
  let members = yield call(getAllMembers, data, role)
  members = members.data
  let activeMembers = yield call(getAllActiveMembers, data, role)
  activeMembers = activeMembers.data
  const totalSleepSessions = yield call(getTotalSleepSessions, data, role)

  let sharedMembers = yield call(getSharedMembers, sharedData, role)
  sharedMembers = sharedMembers.data

  const payloadToBeSent = {
    loading: false,
    members,
    filteredType,
    redirect: false,
    sharedMembers,
  }

  // Saving allMembers in another variable in Reducer to prevent overwriting data on Filter

  if (data.vcstatus) {
    payloadToBeSent.allSharedMembers = sharedMembers
    payloadToBeSent.allMembers = members
    payloadToBeSent.totalSleepSessions = totalSleepSessions
    payloadToBeSent.activeMembers = activeMembers
  }

  yield put({
    type: 'member/SET_STATE',
    payload: payloadToBeSent,
  })

  return true
}

// Function to add a new Member, Provider or SuperAdmin to the Database and Refresh the members list in Redux store
export function* ADD_NEW_MEMBER({ payload }) {
  const { data, memberView } = payload
  yield put({
    type: 'member/SET_STATE',
    payload: {
      formLoading: true,
      redirect: false,
      newMembers: 'please wait',
    },
  })

  const { role } = yield select(getUser)
  const newMembers = yield call(addMember, data, role, memberView)

  yield put({
    type: 'member/SET_STATE',
    payload: {
      formLoading: false,
      redirect: true,
      newMembers,
    },
  })
  yield call(GET_MEMBERS, { payload: { data: { vcstatus: true } } })

  return true
}

// Function to update the data of a Specific Member and Refresh the members list in Redux Store
export function* UPDATE_MEMBER({ payload }) {
  const { data, memberView } = payload
  const { role } = yield select(getUser)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      formLoading: true,
      redirect: false,
      memberUpdated: 'please wait',
    },
  })
  const memberUpdated = yield call(updateMember, data, role, memberView)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      formLoading: false,
      redirect: true,
      memberUpdated,
    },
  })
  yield call(GET_MEMBERS, { payload: { data: { vcstatus: true } } })

  return true
}

// Function to update the status of the member and Refresh the members list in Redux Store
export function* SET_STATUS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      setStatusLoading: true,
    },
  })

  const { role } = yield select(getUser)
  yield call(setStatus, payload, role)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      setStatusLoading: false,
    },
  })
}

// Function to delete a member from the database and Refresh the members list in Redux Store
export function* DELETE_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      deleteMemberLoading: true,
    },
  })
  const { data, memberView } = payload
  yield call(deleteMember, data, memberView)
  yield call(GET_MEMBERS, { payload: { data: { filteredType: '' } } })
  yield put({
    type: 'member/SET_STATE',
    payload: {
      deleteMemberLoading: false,
      redirect: false,
    },
  })

  return true
}

// Function to get all the API's available on Server
export function* GET_ACM_APIS() {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      acmLoading: true,
      acmAPIs: [],
      saAccess: [],
      vcAdminAccess: [],
      memberAccess: [],
    },
  })

  const acmAPIs = yield call(getACMAPI)
  const saAccess = yield call(getAccess, 'sa')
  const vcAdminAccess = yield call(getAccess, 'vc-admin')
  const memberAccess = yield call(getAccess, 'member')
  yield put({
    type: 'member/SET_STATE',
    payload: {
      acmLoading: false,
      acmAPIs,
      saAccess,
      vcAdminAccess,
      memberAccess,
      redirect: false,
    },
  })
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_MEMBER_DETAIL, GET_MEMBER_DETAIL),
    takeEvery(actions.GET_MEMBERS, GET_MEMBERS),
    takeEvery(actions.ADD_NEW_MEMBER, ADD_NEW_MEMBER),
    takeEvery(actions.UPDATE_MEMBER, UPDATE_MEMBER),
    takeEvery(actions.SET_STATUS, SET_STATUS),
    takeEvery(actions.DELETE_MEMBER, DELETE_MEMBER),
    takeEvery(actions.GET_ACM_APIS, GET_ACM_APIS),
    takeEvery(actions.GET_PROVIDER_PROFILE, GET_PROVIDER_PROFILE),
  ])
}
