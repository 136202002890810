import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { notification } from 'antd'
import actions, {
  getClinicPatients,
  addClinicPatient,
  linkSessionToPatient,
  getPatientSleepSessions,
  getPatientSleepStagesHistory,
  getPatientTrendsData,
  getPatientCircadianAnalysis,
  getAvailableDevices,
  unassignDeviceFromPatient,
  updatePatient,
  reAdmitPatient,
  deletePatient,
  getPatientIdsForDevice,
  dischargePatient,
} from './actions'
import { LINK_PATIENT, GET_CLINIC_DEVICES } from '../device/sagas'
import { getOffsetFromRecommendation } from '../profile/actions'

import {
  formatTrendsData,
  formatSleepStagesHistory,
  formatCProcessData,
  formatTimelineData,
  formatBodyClockSummary,
} from '../profile/sagas'

const getUser = (state) => state.user
const getClinic = (state) => state.clinic
const getDevice = (state) => state.device

// Function to get A List of Invoices from Server and Save it in Redux Store
export function* GET_CLINIC_PATIENTS({ payload }) {
  let { data } = payload
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientLoading: true,
      clinicPatientsList: [],
      clinicDischargePatientsList: [],
    },
  })
  const { role } = yield select(getUser)
  const { userClinic } = yield select(getClinic)
  data = {
    clinic_id: userClinic.id,
    status: 1,
    ...data,
  }
  const clinicPatientsList = yield call(getClinicPatients, role, data)
  data.status = 2
  const clinicDischargePatientsList = yield call(getClinicPatients, role, data)
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientLoading: false,
      clinicPatientsList,
      clinicDischargePatientsList,
    },
  })
}

export function* LINK_SESSION_TO_PATIENT({ payload }) {
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientLoading: true,
    },
  })
  const { data } = payload
  const { role } = yield select(getUser)

  yield call(linkSessionToPatient, data, role)

  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientLoading: false,
      redirectActivity: true,
    },
  })
}

export function* ADD_CLINIC_PATIENT({ payload }) {
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientAddLoading: true,
    },
  })
  const { role } = yield select(getUser)
  const { userClinic } = yield select(getClinic)

  // eslint-disable-next-line prefer-const
  let { data } = payload

  const dataTobeSent = {
    name: data.name,
    mr_no: data.mr_no,
    height_cm: data.height_cm,
    weight_kg: data.weight_kg,
    age: data.age,
    date_of_admission: data.date_of_admission,
    date_of_discharge: data.date_of_discharge,
    clinic_id: userClinic.id,
  }
  const { device_id: deviceId } = data
  let response = yield call(addClinicPatient, dataTobeSent, role)
  if (!response.success) {
    return yield put({
      type: 'clinicPatient/SET_STATE',
      payload: {
        clinicPatientAddLoading: false,
      },
    })
  }
  if (deviceId) {
    response = yield call(LINK_PATIENT, {
      payload: { data: { device_id: deviceId, patient_id: data.mr_no } },
    })
  }
  yield call(GET_CLINIC_PATIENTS, { payload: {} })
  return yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientAddLoading: false,
      redirect: true,
    },
  })
}

export function* UPDATE_PATIENT({ payload }) {
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientAddLoading: true,
    },
  })
  const { role } = yield select(getUser)
  const { userClinic } = yield select(getClinic)
  let { data } = payload
  data = {
    ...data,
    clinic_id: userClinic.id,
  }
  const response = yield call(updatePatient, role, data)
  if (!response.success) {
    return yield put({
      type: 'clinicPatient/SET_STATE',
      payload: {
        clinicPatientAddLoading: false,
      },
    })
  }
  yield call(GET_CLINIC_PATIENTS, { payload: {} })

  return yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientAddLoading: false,
      redirect: true,
    },
  })
}

export function* GET_PATIENT_DETAIL({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      patientProfileLoading: true,
    },
  })

  const { role } = yield select(getUser)

  const { data } = payload
  const { patientId } = data
  const { userClinic } = yield select(getClinic)

  const [patientDetail] = yield call(getClinicPatients, role, {
    patient_id: patientId,
    clinic_id: userClinic.id,
  })

  const { inventoryDevices } = yield select(getDevice)

  if (inventoryDevices.length > 0) {
    inventoryDevices.map(({ device_id: deviceId, device_name: name }) => {
      if (deviceId === patientDetail.device_id) {
        patientDetail.deviceName = name
      }
      return false
    })
  }

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      patientProfileLoading: false,
      patientDetail,
    },
  })
}

export function* GET_NIGHT_BY_NIGHT_DATA({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      nightByNightLoading: true,
    },
  }) /* 
  const { role } = yield select(getUser) */
  const patientSleepStagesHistory = yield call(getPatientSleepStagesHistory, payload)
  const timezone = parseInt(patientSleepStagesHistory.tz_diff, 10)
  const {
    hypnogramData,
    noiseGraphData,
    motionGraphData,
    respiratoryGraphData,
    respiratoryVariableGraphData,
    absence,
    lightAMChartData,
    presenceData,
  } = formatSleepStagesHistory(patientSleepStagesHistory, timezone, 'number')

  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      lastTimezone: timezone,
    },
  })
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      memberSleepStagesHistory: patientSleepStagesHistory,
      noiseGraphData,
      hypnogramData,
      motionGraphData,
      deviceID: patientSleepStagesHistory.device_id,
      respiratoryGraphData,
      respiratoryVariableGraphData,
      absence,
      calendarDate: patientSleepStagesHistory.date_of_sleep,
      lightAMChartData,
      presenceData,
      nightByNightLoading: false,
    },
  })
}

export function* GET_PATIENT_PROFILE({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      nightByNightLoading: true,
    },
  })

  const { data, date } = payload
  const { patientId } = data
  const { userClinic } = yield select(getClinic)

  const { role } = yield select(getUser)

  const [patientDetail] = yield call(getClinicPatients, role, {
    patient_id: patientId,
    clinic_id: userClinic.id,
  })

  const patientSleepSessions = yield call(getPatientSleepSessions, role, {
    patient_id: patientId,
    clinic_id: userClinic.id,
  })

  let lastSession = patientSleepSessions.length > 0 ? patientSleepSessions[0] : { device_id: '' }
  if (date) {
    patientSleepSessions.map((val) => {
      if (val.date_of_sleep === date) {
        lastSession = val
        return true
      }
      return false
    })
  }
  const { device_id: deviceID } = lastSession
  const patientSleepStagesHistory = yield call(getPatientSleepStagesHistory, {
    patient_id: patientId,
    device_id: deviceID,
    date_of_sleep: lastSession.date_of_sleep,
  })
  const timezone = parseInt(patientSleepStagesHistory.tz_diff, 10)
  const {
    hypnogramData,
    noiseGraphData,
    motionGraphData,
    respiratoryGraphData,
    respiratoryVariableGraphData,
    absence,
    lightAMChartData,
    presenceData,
  } = formatSleepStagesHistory(patientSleepStagesHistory, timezone, 'number')

  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      lastTimezone: timezone,
    },
  })
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      memberDetail: patientDetail,
      memberSleepSessions: patientSleepSessions,
      memberSleepStagesHistory: patientSleepStagesHistory,
      noiseGraphData,
      hypnogramData,
      motionGraphData,
      deviceID: patientSleepStagesHistory.device_id,
      respiratoryGraphData,
      respiratoryVariableGraphData,
      absence,
      calendarDate: lastSession.date_of_sleep,
      lightAMChartData,
      presenceData,
      nightByNightLoading: false,
    },
  })

  const patientTrendsData = yield call(getPatientTrendsData, {
    patient_id: patientId,
    date: lastSession.date_of_sleep,
  })

  const { weeklyTrendsData, monthlyTrendsData, fortnightTrendsData } = formatTrendsData(
    patientTrendsData,
  )

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      monthlyTrendsData,
      weeklyTrendsData,
      fortnightTrendsData,
    },
  })

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      nightByNightLoading: false,
    },
  })
}

export function* GET_PATIENT_BODY_CLOCK({ payload }) {
  const { role } = yield select(getUser)

  const bodyClockObj = {
    ...payload,
    graph: 'bodyclock_points',
  }
  const bodyClockImageObj = {
    ...payload,
    graph: 'bodyclock',
  }
  const bodyClockProccessCObj = {
    ...payload,
    graph: 'process_c',
  }
  const bodyClockPRCObj = {
    ...payload,
    graph: 'prc',
  }
  const [
    memberBodyClockData,
    memberPRCImage,
    memberBodyClockImage,
    memberProcessCRequest,
  ] = yield all([
    call(getPatientCircadianAnalysis, role, bodyClockObj),
    call(getPatientCircadianAnalysis, role, bodyClockPRCObj),
    call(getPatientCircadianAnalysis, role, bodyClockImageObj),
    call(getPatientCircadianAnalysis, role, bodyClockProccessCObj),
  ])
  const memberProcessCImage = memberProcessCRequest.img ? memberProcessCRequest.img : ''
  const recommendation = memberProcessCRequest.recommendation
    ? memberProcessCRequest.recommendation
    : ''
  const bodyClockOffset = getOffsetFromRecommendation(recommendation)
  const CProcessData = yield call(formatCProcessData, memberBodyClockData)
  const timelineData = yield call(formatTimelineData, memberBodyClockData)
  const bodyclockSummary = yield call(formatBodyClockSummary, memberBodyClockData, bodyClockOffset)

  const formattedBodyClockData = { datasets: timelineData.concat(CProcessData) }

  const { datasets } = formattedBodyClockData
  if (Object.keys(datasets).length === 0) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        showFieldsForBodyClock: true,
      },
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      memberBodyClockData,
      bodyclockSummary,
      memberBodyClockImage,
      memberProcessCImage,
      memberPRCImage,
      showFieldsForBodyClock: false,
      memberBodyClockGraphData: formattedBodyClockData,
      tabLoading: false,
    },
  })
}

export function* GET_AVAILABLE_DEVICES() {
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: { availableDevicesLoading: true },
  })

  const { userClinic } = yield select(getClinic)
  const data = {
    clinic_id: userClinic.id,
  }

  const { role } = yield select(getUser)
  const availableDevices = yield call(getAvailableDevices, role, data)

  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: { availableDevices, availableDevicesLoading: false },
  })
}

export function* UNASSIGN_DEVICE({ payload }) {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: true,
    },
  })
  const { data } = payload
  const { role } = yield select(getUser)

  const response = yield call(unassignDeviceFromPatient, role, data)

  if (!response.success) {
    return yield put({
      type: 'device/SET_STATE',
      payload: {
        inventoryLoading: false,
      },
    })
  }
  yield call(GET_CLINIC_PATIENTS, { payload: {} })
  yield call(GET_CLINIC_DEVICES, { payload: {} })
  yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: false,
      modalVisible: false,
    },
  })
  const { isButton = false } = payload
  if (isButton) {
    return true
  }
  return yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      redirect: true,
    },
  })
}

export function* DELETE_PATIENT({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      patientProfileLoading: true,
    },
  })
  const { data } = payload

  const { userClinic } = yield select(getClinic)
  data.clinic_id = userClinic.id
  const { role } = yield select(getUser)

  let response = yield call(deletePatient, role, data)

  if (!response.success) {
    return yield put({
      type: 'profile/SET_STATE',
      payload: {
        patientProfileLoading: false,
      },
    })
  }
  response = yield call(unassignDeviceFromPatient, role, data)

  if (!response.success) {
    return yield put({
      type: 'profile/SET_STATE',
      payload: {
        patientProfileLoading: false,
      },
    })
  }

  yield call(GET_CLINIC_PATIENTS, { payload: {} })
  return yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientAddLoading: false,
      redirect: true,
    },
  })
}

export function* DISCHARGE_PATIENT({ payload }) {
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientsLoading: true,
    },
  })
  const { data } = payload
  const { role } = yield select(getUser)

  let response = {}
  if (data.device_id) {
    response = yield call(
      unassignDeviceFromPatient,
      role,
      { device_id: data.device_id, clinic_id: data.clinic_id, patient_id: data.mr_no },
      false,
    )
  } else {
    response.success = true
  }

  if (response.success) {
    response = yield call(dischargePatient, role, data, false)
    if (response.success) {
      notification.success({
        message: `Success`,
        description: 'Patient Discharged Successfully',
      })
      yield call(GET_CLINIC_PATIENTS, { payload: {} })

      yield call(GET_CLINIC_DEVICES, { payload: {} })
    }
  }

  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientsLoading: false,
    },
  })
}

export function* READMIT_PATIENT({ payload }) {
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientsLoading: true,
    },
  })
  const { data } = payload
  const { role } = yield select(getUser)

  const response = yield call(reAdmitPatient, role, data, false)
  if (response.success) {
    notification.success({
      message: `Success`,
      description: 'Patient readmitted successfully',
    })
    yield call(GET_CLINIC_PATIENTS, { payload: {} })
  }

  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      clinicPatientsLoading: false,
    },
  })
}

export function* GET_PATIENT_IDS({ payload }) {
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: {
      patientIdsLoading: true,
      patientIds: [],
    },
  })
  // const { data } = payload
  // console.log('payload', payload)
  const { role } = yield select(getUser)

  const { response: patientIds, success } = yield call(getPatientIdsForDevice, role, payload)
  let payloadToBeSent = {
    patientIdsLoading: false,
    patientIds,
  }
  if (!success) {
    payloadToBeSent = {
      patientIdsLoading: false,
      patientIds: [],
    }
  }
  yield put({
    type: 'clinicPatient/SET_STATE',
    payload: payloadToBeSent,
  })
}

export function* GET_PATIENT_SESSIONS({ payload }) {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
      reportDeviceSessions: [],
    },
  })

  const { role } = yield select(getUser)

  const reportDeviceSessions = yield call(getPatientSleepSessions, role, payload)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      reportDeviceSessions,
      loading: false,
    },
  })
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([
    takeEvery(actions.DISCHARGE_PATIENT, DISCHARGE_PATIENT),
    takeEvery(actions.GET_CLINIC_PATIENTS, GET_CLINIC_PATIENTS),
    takeEvery(actions.GET_NIGHT_BY_NIGHT_DATA, GET_NIGHT_BY_NIGHT_DATA),
    takeEvery(actions.LINK_SESSION_TO_PATIENT, LINK_SESSION_TO_PATIENT),
    takeEvery(actions.ADD_CLINIC_PATIENT, ADD_CLINIC_PATIENT),
    takeEvery(actions.GET_PATIENT_IDS, GET_PATIENT_IDS),
    takeEvery(actions.GET_PATIENT_SESSIONS, GET_PATIENT_SESSIONS),
    takeEvery(actions.READMIT_PATIENT, READMIT_PATIENT),
    takeEvery(actions.GET_PATIENT_PROFILE, GET_PATIENT_PROFILE),
    takeEvery(actions.GET_PATIENT_BODY_CLOCK, GET_PATIENT_BODY_CLOCK),
    takeEvery(actions.GET_AVAILABLE_DEVICES, GET_AVAILABLE_DEVICES),
    takeEvery(actions.UNASSIGN_DEVICE, UNASSIGN_DEVICE),
    takeEvery(actions.GET_PATIENT_DETAIL, GET_PATIENT_DETAIL),
    takeEvery(actions.UPDATE_PATIENT, UPDATE_PATIENT),
    takeEvery(actions.DELETE_PATIENT, DELETE_PATIENT),
  ])
}
