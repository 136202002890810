import { notification } from 'antd'
import Auth from '@aws-amplify/auth'
import { firstTimeLogin } from '../redux/user/actions'
// function OnlyCircadiaUsers(email) {
//   const regex = /(circadiatech.com)/g // Insert whatever phrase or character you want to find
//   return regex.test(email) // => true
// }
export default async function login(username, password, payload) {
  try {
    return await Auth.signIn(username, password)
      .then(async user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          if (payload.newPassword !== undefined) {
            const { newPassword } = payload
            const email = username
            await Auth.completeNewPassword(
              user, // the Cognito User Object
              newPassword, // the new password
              // OPTIONAL, the required attributes
              {
                email,
              },
            )
            await firstTimeLogin()

            return {
              success: true,
              type: 'Coach',
            }
          }
          notification.warning({
            message: 'Change your password',
            description: 'New password required',
          })
          return {
            newPassReq: true,
            userDetails: {
              username,
              password,
            },
            error: 'New Password Required',
          }
        }
        return {
          success: true,
          type: 'Coach',
        }
      })
      .catch(e => {
        notification.warning({
          message: 'Invalid Login',
          description: e.message,
        })
        return {
          success: false,
          error: 'invalid Username or Password',
        }
      })
  } catch (e) {
    notification.warning({
      message: 'Invalid Login',
      description: e.message,
    })
    return {
      success: false,
      error: 'invalid Username or Password',
    }
  }
}
export async function currentAccount() {
  try {
    let firstName = ''
    let lastName = ''
    let userID = ''
    let CoachImage = 'Doctor.png'
    await Auth.currentSession().then(async () =>
      Auth.currentUserInfo().then(user => {
        firstName = user.attributes['custom:first_name']
        lastName = user.attributes['custom:last_name']
        userID = user.attributes.sub
        if (userID === '6748ab69-bbce-47fc-ae7b-93e9a94af76c') {
          CoachImage = 'MaxKirsten.png'
        }
      }),
    )
    return await Auth.currentSession().then(async ses =>
      Auth.currentUserCredentials().then((/* user */) => {
        /*         const { accessKeyId, secretAccessKey, sessionToken } = user
        console.log('accessKeyId', accessKeyId)
        console.log('secretAccessKey', secretAccessKey)
        console.log('sessionToken', sessionToken)
        */
        const { idToken } = ses /* 
        console.log('id token is ', idToken) */
        const { jwtToken } = idToken
        const groups = idToken.payload['cognito:groups']

        return {
          name: 'Coach',
          role: 'Coach',
          CoachImage,
          groups,
          userID,
          fullName: `${firstName} ${lastName}`,
          jwtToken,
          status: true,
        }
      }),
    )
  } catch (e) {
    console.log('Error Loading current Account', e)
    return {
      status: false,
    }
  }
}
export async function logout() {
  await Auth.signOut().then(() => true)
}
