export default {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api2.circadia.io',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_WLAItiF9J',
    APP_CLIENT_ID: '2ifse5bi9qktofuoqli0p7lol5',
    IDENTITY_POOL_ID: 'us-east-1:7a239b88-07d4-4548-8b7a-a80a2b70efb0',
    /* WILL HAVE TO USE THESE CREDENTIALS IN FUTURE  
    IDENTITY_POOL_ID: us-east-1:a409dd63-e481-4bc2-9f73-10fdc7ceefee 
    WILL HAVE TO USE THESE CREDENTIALS IN FUTURE */
  },
}
export const urls = {
  profile: {
    memberDetail: '/virtualclinic-temp/v1/memberDetail',
    memberSleepSessions: '/virtualclinic-temp/v1/memberSleepSessions',
    memberCSIHistory: '/virtualclinic-temp/v1/memberCSIHistory',
    memberSleepStages: '/sleep-session-v1/memberSleepStages',
    memberCircadianAnalysisGraphs: '/vc-computation/circadia_analysis_graphs',
    memberMetricsHistory: '/virtualclinic-temp/v1/memberMetricsHistory',
    notes: {
      create: '/notes/v1/create',
      update: '/notes/v1/update',
      list: '/notes/v1/list',
    },
    memberDiary: '/diary/v1/view',
    memberAVGSleepMetrics: '/vc-sleep-session/v1/memberAVGSleepMetrics',
  },
  member: {
    memberListing: '/virtualclinic-temp/v1/allMembers',
  },
  deviceAPIs: {
    patientsList: '/clinic-inventory/v1/getPatients',
    linkPatient: '/clinic-inventory-dev/v1/addPatientID',
    deviceDetail: '/clinic-inventory-dev/v1/list', // one API used for multi purposes
    deviceSession: '/clinic-sleep-session-dev/v1/deviceSession',
    allDeviceSessions: '/clinic-sleep-session-dev/v1/allDeviceSessions',
    returnDevice: '/clinic-inventory/v1/returnDevice',
    deviceMetricsHistory: '/clinic-sleep-session-dev/v1/metricsHistory',
    patientSessionsDate: '/clinic-sleep-session-dev/v1/getPatientSessionsDate',
  },
  referrals: {
    appSessions: '/app-sessions/v1/appSessions',
  },
  patient: {},
}

export const apiPaths = {
  Provider: {
    // Coach Profile APIS
    apiGetTotalSleepSessions: '/vc-sleep-session/v1/sleepSessionCount',

    // Member APIs
    apiMemberListing: '/virtualclinic-temp/v1/allMembers',
    apiMemberCreate: '/vc-user/v1/create',
    apiMemberUpdate: '/vc-user/v1/update',
    apiMemberStatusUpdate: '/vc-user/v1/setStatus',
    apiActiveMemberListing: '/vc-sleep-session/v1/memberRecentSleepSessions',
    apiInviteMember: '/vc-invite-user/v1/create',
    apiSharedMemberListing: '/vc-share-member/v1/getSharedMembers',
    apiLinkedProvidersListing: '/vc-share-member/v1/showProviders',

    // Device APIs
    apiDeviceDevices: '/vc-inventory/v1/devices',
    apiDeviceAddInStock: '/vc-inventory/v1/addInStock',
    apiSessionCreate: '/vc-sleep-session/v1/create',

    // Package APIs
    apiPackageList: '/vc-assign-pkg/v1/assigned-pkg',
    apiPackageAdd: '/vc-assign-pkg/v1/create',
    apiPackageDisable: '/vc-assign-pkg/v1/disable-pkg',
    apiPackageUpdate: '/vc-assign-pkg/v1/update',
    apiPackageDelete: '/vc-assign-pkg/v1/delete',
  },
  Superadmin: {
    // Coach Profile APIS
    apiGetTotalSleepSessions: '/vc-sleep-session/v1/sleepSessionCount',

    // Member APIs
    apiMemberListing: '/virtualclinic-temp/v1/allMembers',
    apiMemberCreate: '/sa-user/v1/create',
    apiMemberUpdate: '/sa-user/v1/update',
    apiMemberStatusUpdate: '/sa-user/v1/setStatus',
    apiActiveMemberListing: '/vc-sleep-session/v1/memberRecentSleepSessions',
    apiInviteMember: '/vc-invite-user/v1/create',
    apiAssignMemberToProvider: '/vc-invite-user/v1/assignMemberToProvider',
    apiSharedMemberListing: '/vc-share-member/v1/getSharedMembers',
    apiShareMember: '/vc-share-member/v1/create',
    apiRevokeProvider: '/vc-invite-user/v1/unLinkMember',
    apiLinkedProvidersListing: '/vc-share-member/v1/showProviders',
    apiUnlinkProvider: '/vc-share-member/v1/delete',

    // Clinics APIs
    apiClinicsListing: '/clinic/v1/list',
    apiClinicCreate: '/clinic/v1/create',
    apiClinicUpdate: '/clinic/v1/update',
    apiClinicMetrics: '/clinic-metrics/v1/admin',

    // Device APIs
    apiDeviceCreate: '/vc-inventory/v1/create',
    apiDeviceUpdate: '/vc-inventory/v1/update',
    apiDeviceAddInStock: '/vc-inventory/v1/addInStock',
    apiDeviceDevices: '/vc-inventory/v1/devices',
    apiDeviceDelete: '/vc-inventory/v1/delete', // for temporary use only
    apiDeviceAssign: '/vc-assign-pkg/v1/assignDevicesToProvider',
    apiSessionCreate: '/vc-sleep-session/v1/create',

    // Package APIs
    apiPackageList: '/vc-assign-pkg/v1/assigned-pkg',
    apiPackageDisable: '/vc-assign-pkg/v1/disable-pkg',

    // Invoice APIs
    apiInvoiceList: '/vc-invoice/v1/list',

    // Referral APIs
    apiReferralList: '/consumer-web-dev/referralList',

    apiWeeklyReportsList: '/report/v1/weekly',
    apiSleepSessionsList: '/vc-sleep-session/v1/sleep_sessions',
  },
  Clinic: {
    // Coach Profile APIS
    apiGetTotalSleepSessions: '/vc-sleep-session-dev/v1/sleepSessionCount',

    // Member APIs
    apiMemberListing: '/virtualclinic-temp-dev/v1/allMembers',
    apiMemberCreate: '/sa-user-dev/v1/create',
    apiMemberUpdate: '/sa-user-dev/v1/update',
    apiMemberStatusUpdate: '/sa-user-dev/v1/setStatus',
    apiActiveMemberListing: '/vc-sleep-session-dev/v1/memberRecentSleepSessions',
    apiGetClinicUsers: '/vc-profile-report-dev/v1/getClinicUsers',
    apiSendDownloadReport: '/vc-profile-report-dev/v1/downloadReport',

    // Device APIs
    apiDeviceCreate: '/vc-inventory-dev/v1/create',
    apiDeviceUpdate: '/vc-inventory-dev/v1/update',
    apiDeviceAddInStock: '/vc-inventory-dev/v1/addInStock',
    apiDeviceDevices: '/vc-inventory-dev/v1/devices',
    apiDeviceDelete: '/vc-inventory-dev/v1/delete', // for temporary use only
    apiSessionCreate: '/clinic-sleep-session-dev/v1/create',
    apiClinicMetrics: '/clinic-metrics/v1/admin',

    // Invoice APIs
    apiInvoiceList: '/vc-invoice-dev/v1/list',

    // Referral APIs
    apiReferralList: '/consumer-web-dev/referralList',

    apiWeeklyReportsList: '/report-dev/v1/weekly',
    apiSleepSessionsList: '/vc-sleep-session/v1/sleep_sessions',
    apiEmailReport: '/vc-profile-report-dev/v1/sendEmail',

    apiClinicPatientsListing: '/vc-clinic-patient-dev/v1/list',
    apiAddClinicPatient: '/vc-clinic-patient-dev/v1/add',
    apiLinkSessionToPatient: '/vc-clinic-patient-dev/v1/sessionLink',

    apiPatientSleepSessions: '/vc-clinic-patient-dev/v1/patientSessions',
    apiPatientCircadianAnalysis: '/vc-clinic-patient-dev/v1/circadianAnalysis',
    apiAvailableDevicesList: '/vc-clinic-patient-dev/v1/availableClinicDevices',
    apiUnassignDevice: '/vc-clinic-patient/v1/unlinkDevice',
    apiPatientUpdate: '/vc-clinic-patient-dev/v1/update',
    apiPatientDischarge: '/vc-clinic-patient-dev/v1/discharge',
    apiPatientDelete: '/vc-clinic-patient/v1/delete',
    apiPatientReAdmit: '/vc-clinic-patient-dev/v1/reAdmit',
    apiPatientIds: '/vc-clinic-patient/v1/getPatientIds',

    apiClinicDeviceActivity: '/vc-clinic-patient-dev/v1/deviceActivity',
    apiClinicDeviceBodyClock: '/clinic-sleep-session-dev/v1/circadianAnalysis',
  },
  All: {
    apiPatientTrends: '/vc-clinic-patient-dev/v1/metricsHistory',
    apiPatientSleepStagesHistory: '/vc-clinic-patient-dev/v1/patientSession',
    apiBreathingPattern: '/vc-sleep-session/v1/getRespirationCurve',
    apiMemberSleepSessions: '/virtualclinic-temp/v1/memberSleepSessions',
    apiMemberDetail: '/virtualclinic-temp/v1/memberDetail',
    apiAVGSLeepMetrics: '/vc-sleep-session/v1/memberAVGSleepMetrics',
    apiMemberCSIHistory: '/virtualclinic-temp/v1/memberCSIHistory',
    apiMemberSleepStages: '/sleep-session-v1/memberSleepStages',
    apiCircadiaAnalysisGraphs: '/vc-computation/circadia_analysis_graphs',
    apiMemberDiary: `/diary/v1/view`,
    apiMetricsHistory: '/virtualclinic-temp/v1/memberMetricsHistory',
    apiNotificationList: '/vc-notification/v1/list',
  },
}
