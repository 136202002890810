import moment from 'moment-timezone'
import { convertTimeOffset } from '../redux/profile/actions'

const formattingFunctions = {}

// function to generate status from sleepSessions status numbaric value to readable text
export const generateStatus = status => {
  let data
  if (status === 0) {
    data = 'Sleep On'
  } else if (status === 1) {
    data = 'Valid'
  } else if (status === 2) {
    data = 'In-valid'
  } else if (status === 3) {
    data = 'Missing data'
  } else if (status === 4) {
    data = 'Duplicate'
  }

  return data
}

export const formatMemberType = value => {
  let memberType = value
  if (value === 'member') memberType = 'Member'

  if (value === 'vc-admin') memberType = 'Provider'

  if (value === 'sa') memberType = 'Superadmin'

  if (value === 'clinic') memberType = 'Clinic Admin'

  return memberType
}

// function to convert utc time to users local time with using their timezone value
export const convertTime = (date, timezone = 'Europe/London', format = 'MM/DD/YYYY HH:mm:ss') => {
  const m = moment.utc(date) // parse input as UTC
  // let newDate = m.format('YYYY-MM-DD HH:mm:ss')
  if (timezone === undefined || timezone === null) {
    const local = m.local().format(format)
    return local
  }
  const newDate = m.tz(timezone).format(format)
  return newDate
}

// This function is to convert minutes into hours and minutes return formate will be 3h 20m
export const convertMinsToHrsMins = mins => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)

  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
  }
  const h = (mins / 60) | 0 // eslint-disable-line no-bitwise
  const m = mins % 60 | 0 // eslint-disable-line no-bitwise
  // if (mins > 30) {
  //   h += 1
  // }
  if (mins === 0) {
    return '0'
  }
  if (h === 0) {
    return `0h ${moment
      .utc()
      .minutes(m)
      .format('mm[m]')}`
  }
  return moment
    .utc()
    .hours(h)
    .minutes(m)
    .format('h[h] mm[m]')
}

// function to genrate sleep metrics to display in night by night and report in form of table
export const generateSleepMetricsForTable = (
  metrics,
  profile = 'member',
  type = 'nightByNight',
) => {
  if (type === 'nightByNight') {
    const dataMetrics = []
    let color = 'none'

    if (
      parseInt(metrics.TotalSleepTime, 10) >= 7 * 60 &&
      parseInt(metrics.TotalSleepTime, 10) <= 9 * 60
    ) {
      color = 'table-success'
    } else if (parseInt(metrics.TotalSleepTime, 10) < 4 * 60) {
      color = 'table-error'
    } else {
      color = 'none'
    }
    dataMetrics.push({
      metric: 'Total Sleep Time [hours]',
      value: convertMinsToHrsMins(metrics.TotalSleepTime),
      color,
      reference: '7-9h',
    })
    if (parseInt(metrics.SleepEfficiency, 10) > 85) {
      color = 'table-success'
    } else if (parseInt(metrics.SleepEfficiency, 10) < 80) {
      color = 'table-danger'
    } else {
      color = 'none'
    }
    dataMetrics.push({
      metric: 'Sleep Efficiency [%]',
      value: Math.round(metrics.SleepEfficiency),
      color,
      reference: '>85%',
    })
    if (
      parseInt(metrics.SleepOnsetLatency, 10) >= 15 &&
      parseInt(metrics.SleepOnsetLatency, 10) <= 20
    ) {
      color = 'table-success'
    } else if (parseInt(metrics.SleepOnsetLatency, 10) > 15) {
      color = 'table-danger'
    } else {
      color = 'none'
    }
    dataMetrics.push({
      metric: 'Sleep Latency [minutes]',
      value: Math.round(metrics.SleepOnsetLatency),
      color,
      reference: '15-20m',
    })

    if (parseInt(metrics.StageRLatency, 10) <= 150 && parseInt(metrics.StageRLatency, 10) >= 50) {
      color = 'table-success'
    } else {
      color = 'none'
    }
    dataMetrics.push({
      metric: 'REM onset Latency [minutes]',
      value: Math.round(metrics.StageRLatency),
      color,
      reference: '50-150m',
    })
    if (parseInt(metrics.TotalSleepTime, 10) + 30 === parseInt(metrics.TimeInBed, 10)) {
      color = 'table-success'
    } else if (parseInt(metrics.TotalSleepTime, 10) + 30 < parseInt(metrics.TimeInBed, 10)) {
      color = 'table-danger'
    } else {
      color = 'none'
    }

    dataMetrics.push({
      metric: 'Time in Bed [hours]',
      value: convertMinsToHrsMins(metrics.TimeInBed),
      color,
      reference: 'TST + 30m',
    })
    if (parseInt(metrics.WASO, 10) <= 30) {
      color = 'table-success'
    } else if (parseInt(metrics.WASO, 10) > 30) {
      color = 'table-danger'
    } else {
      color = 'none'
    }
    dataMetrics.push({
      metric: 'Wake After Sleep Onset [minutes]',
      value: Math.round(metrics.WASO),
      color,
      reference: '<30m',
    })
    color = 'none'
    dataMetrics.push({
      metric: 'Absence [minutes]',
      value: metrics.absence ? Math.round(metrics.absence) : '0',
      color,
      reference: '-',
    })

    if (profile === 'member') {
      dataMetrics.push({
        metric: 'Circadia Sleep Index [%]',
        value: Math.round(metrics.csi),
      })
    }
    return dataMetrics
  }

  const dataMetrics = []
  let color = 'none'
  if (
    parseInt(metrics.TotalSleepTime, 10) >= 7 * 60 &&
    parseInt(metrics.TotalSleepTime, 10) <= 9 * 60
  ) {
    color = 'table-success'
  } else if (parseInt(metrics.TotalSleepTime, 10) < 4 * 60) {
    color = 'table-error'
  } else {
    color = 'none'
  }
  dataMetrics.push({
    metric: 'Total Sleep Time [hours]',
    value: convertMinsToHrsMins(metrics.TotalSleepTime),
    color,
  })
  if (parseInt(metrics.tta, 10) < 50) {
    color = 'table-success'
  } else if (parseInt(metrics.tta, 10) > 60) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  dataMetrics.push({
    metric: 'Total Times Awaken',
    value: Math.round(metrics.tta),
    color,
  })

  if (parseInt(metrics.SleepEfficiency, 10) > 85) {
    color = 'table-success'
  } else if (parseInt(metrics.SleepEfficiency, 10) < 80) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  dataMetrics.push({
    metric: 'Sleep Efficiency [%]',
    value: Math.round(metrics.SleepEfficiency),
    color,
  })

  if (
    parseInt(metrics.SleepOnsetLatency, 10) >= 15 &&
    parseInt(metrics.SleepOnsetLatency, 10) <= 20
  ) {
    color = 'table-success'
  } else if (parseInt(metrics.SleepOnsetLatency, 10) > 15) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  dataMetrics.push({
    metric: 'Sleep Latency [minutes]',
    value: Math.round(metrics.SleepOnsetLatency),
    color,
  })

  if (parseInt(metrics.StageRLatency, 10) <= 150 && parseInt(metrics.StageRLatency, 10) >= 50) {
    color = 'table-success'
  } else {
    color = 'none'
  }
  dataMetrics.push({
    metric: 'REM onset Latency [minutes]',
    value: Math.round(metrics.StageRLatency),
    color,
  })
  color = 'none'
  dataMetrics.push({
    metric: 'Sleep Start Time',
    value: moment(metrics.startTime).format('HH:mm'),
    color,
  })
  color = 'none'
  dataMetrics.push({
    metric: 'Sleep End Time',
    value: moment(metrics.endTime).format('HH:mm'),
  })
  if (parseInt(metrics.TotalSleepTime, 10) + 30 === parseInt(metrics.TimeInBed, 10)) {
    color = 'table-success'
  } else if (parseInt(metrics.TotalSleepTime, 10) + 30 < parseInt(metrics.TimeInBed, 10)) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  dataMetrics.push({
    metric: 'Time in Bed [hours]',
    value: convertMinsToHrsMins(metrics.TimeInBed),
    color,
  })
  if (parseInt(metrics.WASO, 10) <= 30) {
    color = 'table-success'
  } else if (parseInt(metrics.WASO, 10) > 30) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  dataMetrics.push({
    metric: 'Wake After Sleep Onset [minutes]',
    value: Math.round(metrics.WASO),
    color,
  })

  color = 'none'
  dataMetrics.push({
    metric: 'Absence [minutes]',
    value: metrics.absence ? Math.round(metrics.absence) : '0',
    color,
  })
  if (profile === 'member') {
    dataMetrics.push({
      metric: 'Circadia Sleep Index [%]',
      value: Math.round(metrics.csi),
    })
  }
  return dataMetrics
}

export function removeDuplicateObjects(arr, comp) {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e])
    .map(e => arr[e])

  return unique
}

export const formatWeeklyReportData = (memberStagesData, userTimezone, dates, getReferences) => {
  const weeklyReportMetrics = {
    'Total Sleep Time': [],
    'Sleep Efficiency': [],
    'Sleep Onset Latency': [],
    'REM Onset Latency': [],
    'Time in Bed': [],
    'Time Awake': [],
    Absence: [],
  }
  const weeklyReportData = { Wake: [], REM: [], Light: [], Deep: [] }
  const reportSleepSessions = {}
  memberStagesData.map((deviceSleepStagesHistory, index) => {
    let absence = 0

    if (Object.keys(deviceSleepStagesHistory).length === 0) {
      weeklyReportData.Wake.push({ value: 0, view: '-' })
      weeklyReportData.REM.push({ value: 0, view: '-' })
      weeklyReportData.Light.push({ value: 0, view: '-' })
      weeklyReportData.Deep.push({ value: 0, view: '-' })
      reportSleepSessions[dates[index]] = {
        count: index,
        sleep: { startTime: null, endTime: null },
      }
      weeklyReportMetrics['REM Onset Latency'].push({
        view: '-',
        value: 0,
      })
      weeklyReportMetrics['Time in Bed'].push({
        view: '-',
        value: 0,
      })
      weeklyReportMetrics['Total Sleep Time'].push({
        view: '-',
        value: 0,
      })
      weeklyReportMetrics['Sleep Onset Latency'].push({
        view: '-',
        value: 0,
      })
      weeklyReportMetrics['Sleep Efficiency'].push({
        view: '-',
        value: 0,
      })
      weeklyReportMetrics['Time Awake'].push({
        view: '-',
        value: 0,
      })
      weeklyReportMetrics.Absence.push({
        view: '-',
        value: 0,
      })
    } else {
      const { levels } = deviceSleepStagesHistory
      const { env } = levels
      let lastPresenceDate
      const reportPresenceData = []

      env.map((datum, count) => {
        const dateTime = new Date(convertTimeOffset(datum.packet_dt, userTimezone))
        if (datum.presence === 0) {
          absence += 30
          if (!lastPresenceDate) {
            lastPresenceDate = dateTime
          }
          if (lastPresenceDate !== null && count === env.length - 1) {
            let endDate = new Date(convertTimeOffset(env[count - 1].packet_dt, userTimezone))

            if (lastPresenceDate === endDate) {
              endDate += 15000
            }
            reportPresenceData.push({
              startDate: lastPresenceDate,
              endDate,
            })
            lastPresenceDate = null
          }
          return true
        }
        if (lastPresenceDate) {
          let endDate = new Date(convertTimeOffset(env[count - 1].packet_dt, userTimezone))

          if (lastPresenceDate === endDate) {
            endDate += 15000
          }
          reportPresenceData.push({
            startDate: lastPresenceDate,
            endDate,
          })
          lastPresenceDate = null
        }
        return true
      })
      let color = 'none'

      weeklyReportData.Wake.push({
        value: Math.round(
          deviceSleepStagesHistory.levels.summary.W.avg > 0
            ? deviceSleepStagesHistory.levels.summary.W.avg
            : 0,
        ),
        view:
          deviceSleepStagesHistory.levels.summary.W.avg > 0
            ? Math.round(deviceSleepStagesHistory.levels.summary.W.avg)
            : '-',
      })
      if (
        deviceSleepStagesHistory.levels.summary.R.avg >= 20 &&
        deviceSleepStagesHistory.levels.summary.R.avg <= 20
      ) {
        color = 'table-success'
      } else if (deviceSleepStagesHistory.levels.summary.R.avg < 20) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportData.REM.push({
        value: Math.round(
          deviceSleepStagesHistory.levels.summary.R.avg > 0
            ? deviceSleepStagesHistory.levels.summary.R.avg
            : 0,
        ),
        view:
          deviceSleepStagesHistory.levels.summary.R.avg > 0
            ? Math.round(deviceSleepStagesHistory.levels.summary.R.avg)
            : '-',
        color,
      })
      if (
        deviceSleepStagesHistory.levels.summary.N.avg >= 40 &&
        deviceSleepStagesHistory.levels.summary.N.avg <= 45
      ) {
        color = 'table-success'
      } else if (deviceSleepStagesHistory.levels.summary.N.avg > 50) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportData.Light.push({
        value: Math.round(
          deviceSleepStagesHistory.levels.summary.N.avg > 0
            ? deviceSleepStagesHistory.levels.summary.N.avg
            : 0,
        ),
        view:
          deviceSleepStagesHistory.levels.summary.N.avg > 0
            ? Math.round(deviceSleepStagesHistory.levels.summary.N.avg)
            : '-',
        color,
      })

      if (
        deviceSleepStagesHistory.levels.summary.D.avg >= 20 &&
        deviceSleepStagesHistory.levels.summary.D.avg <= 25
      ) {
        color = 'table-success'
      } else if (deviceSleepStagesHistory.levels.summary.D.avg < 20) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportData.Deep.push({
        value: Math.round(
          deviceSleepStagesHistory.levels.summary.D.avg > 0
            ? deviceSleepStagesHistory.levels.summary.D.avg
            : 0,
        ),
        view:
          deviceSleepStagesHistory.levels.summary.D.avg > 0
            ? Math.round(deviceSleepStagesHistory.levels.summary.D.avg)
            : '-',
        color,
      })

      absence = Math.trunc(absence / 60)

      reportSleepSessions[deviceSleepStagesHistory.date_of_sleep] = {
        count: index,
        sleep: {
          startTime: deviceSleepStagesHistory.startTime,
          endTime: deviceSleepStagesHistory.endTime,
        },
      }

      const { metrics } = levels
      color = 'none'

      if (parseInt(metrics.StageRLatency, 10) <= 150 && parseInt(metrics.StageRLatency, 10) >= 50) {
        color = 'table-success'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['REM Onset Latency'].push({
        view: metrics.StageRLatency > 0 ? `${Math.round(metrics.StageRLatency)}m` : '-',
        color,
        value: metrics.StageRLatency,
      })
      if (parseInt(metrics.TotalSleepTime, 10) + 30 === parseInt(metrics.TimeInBed, 10)) {
        color = 'table-success'
      } else if (parseInt(metrics.TotalSleepTime, 10) + 30 < parseInt(metrics.TimeInBed, 10)) {
        color = 'table-danger'
      } else {
        color = 'none'
      }

      weeklyReportMetrics['Time in Bed'].push({
        view: metrics.TimeInBed > 0 ? convertMinsToHrsMins(metrics.TimeInBed) : '-',
        color,
        value: metrics.TimeInBed,
      })

      if (
        parseInt(metrics.TotalSleepTime, 10) >= 7 * 60 &&
        parseInt(metrics.TotalSleepTime, 10) <= 9 * 60
      ) {
        color = 'table-success'
      } else if (parseInt(metrics.TotalSleepTime, 10) < 4 * 60) {
        color = 'table-error'
      } else {
        color = 'none'
      }

      weeklyReportMetrics['Total Sleep Time'].push({
        view: metrics.TotalSleepTime > 0 ? convertMinsToHrsMins(metrics.TotalSleepTime) : '-',
        value: metrics.TotalSleepTime,
        color,
      })

      if (
        parseInt(metrics.SleepOnsetLatency, 10) >= 15 &&
        parseInt(metrics.SleepOnsetLatency, 10) <= 20
      ) {
        color = 'table-success'
      } else if (parseInt(metrics.SleepOnsetLatency, 10) > 15) {
        color = 'table-danger'
      } else {
        color = 'none'
      }

      weeklyReportMetrics['Sleep Onset Latency'].push({
        view: metrics.SleepOnsetLatency > 0 ? `${Math.round(metrics.SleepOnsetLatency)}m` : '-',
        value: metrics.SleepOnsetLatency,
        color,
      })

      if (parseInt(metrics.SleepEfficiency, 10) > 85) {
        color = 'table-success'
      } else if (parseInt(metrics.SleepEfficiency, 10) < 80) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['Sleep Efficiency'].push({
        view: metrics.SleepEfficiency ? `${Math.round(metrics.SleepEfficiency)}%` : '-',
        value: metrics.SleepEfficiency,
        color,
      })
      if (parseInt(metrics.WASO, 10) <= 30) {
        color = 'table-success'
      } else if (parseInt(metrics.WASO, 10) > 30) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['Time Awake'].push({
        view: metrics.WASO > 0 ? `${Math.round(metrics.WASO)}m` : '-',
        value: metrics.WASO,
        color,
      })

      color = 'none'

      weeklyReportMetrics.Absence.push({
        view: absence > 0 ? `${Math.round(absence)}m` : '-',
        value: absence,
      })
    }
    return true
  })

  const avgTST =
    weeklyReportMetrics['Total Sleep Time'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Total Sleep Time'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['Total Sleep Time'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgSE =
    weeklyReportMetrics['Sleep Efficiency'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Sleep Efficiency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['Sleep Efficiency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgSOL =
    weeklyReportMetrics['Sleep Onset Latency'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Sleep Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['Sleep Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgROL =
    weeklyReportMetrics['REM Onset Latency'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['REM Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['REM Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgTimeInBed =
    weeklyReportMetrics['Time in Bed'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Time in Bed'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 0
      : weeklyReportMetrics['Time in Bed'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgWASO =
    weeklyReportMetrics['Time Awake'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Time Awake'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['Time Awake'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgAbsence =
    weeklyReportMetrics.Absence.reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics.Absence.reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics.Absence.reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))

  let color = 'none'
  if (parseInt(avgROL, 10) <= 150 && parseInt(avgROL, 10) >= 50) {
    color = 'table-success'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['REM Onset Latency'].push({
    view: avgROL > 0 ? `${Math.round(avgROL)}m` : '-',
    value: avgROL,
    color,
  })

  if (parseInt(avgTST, 10) + 30 === parseInt(avgTimeInBed, 10)) {
    color = 'table-success'
  } else if (parseInt(avgTST, 10) + 30 < parseInt(avgTimeInBed, 10)) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['Time in Bed'].push({
    view: avgTimeInBed > 0 ? convertMinsToHrsMins(avgTimeInBed) : '-',
    value: avgTimeInBed,
    color,
  })

  if (parseInt(avgTST, 10) >= 7 * 60 && parseInt(avgTST, 10) <= 9 * 60) {
    color = 'table-success'
  } else if (parseInt(avgTST, 10) < 4 * 60) {
    color = 'table-error'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['Total Sleep Time'].push({
    view: avgTST > 0 ? convertMinsToHrsMins(avgTST) : '-',
    color,
    value: avgTST,
  })
  if (parseInt(avgSOL, 10) >= 15 && parseInt(avgSOL, 10) <= 20) {
    color = 'table-success'
  } else if (parseInt(avgSOL, 10) > 15) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportMetrics['Sleep Onset Latency'].push({
    view: avgSOL > 0 ? `${Math.round(avgSOL)}m` : '-',
    value: avgSOL,
    color,
  })

  if (parseInt(avgSE, 10) > 85) {
    color = 'table-success'
  } else if (parseInt(avgSE, 10) < 80) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportMetrics['Sleep Efficiency'].push({
    view: avgSE > 0 ? `${Math.round(avgSE)}%` : '-',
    color,
    value: avgSE,
  })
  if (parseInt(avgWASO, 10) <= 30) {
    color = 'table-success'
  } else if (parseInt(avgWASO, 10) > 30) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['Time Awake'].push({
    view: avgWASO > 0 ? `${Math.round(avgWASO)}m` : '-',
    color,
    value: avgWASO,
  })
  color = 'none'
  weeklyReportMetrics.Absence.push({
    view: avgAbsence > 0 ? `${Math.round(avgAbsence)}m` : '-',
    value: avgAbsence,
    color,
  })

  const WakeAvg =
    (weeklyReportData.Wake.reduce((a, { value: b }) => a + b, 0) === 0
      ? 0
      : weeklyReportData.Wake.reduce((a, { value: b }) => a + b, 0)) /
    (weeklyReportData.Wake.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Wake.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  const LightAvg =
    weeklyReportData.Light.reduce((a, { value: b }) => a + b, 0) /
    (weeklyReportData.Light.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Light.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  const DeepAvg =
    weeklyReportData.Deep.reduce((a, { value: b }) => a + b, 0) /
    (weeklyReportData.Deep.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Deep.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  const REMAvg =
    weeklyReportData.REM.reduce((a, { value: b }) => a + b, 0) /
    (weeklyReportData.REM.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.REM.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  weeklyReportData.Wake.push({
    value: WakeAvg > 0 ? Math.round(WakeAvg) : 0,
    view: WakeAvg > 0 ? Math.round(WakeAvg) : '-',
    color,
  })
  if (REMAvg >= 20 && REMAvg <= 25) {
    color = 'table-success'
  } else if (REMAvg < 20) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  weeklyReportData.REM.push({
    value: REMAvg > 0 ? Math.round(REMAvg) : 0,
    view: REMAvg > 0 ? Math.round(REMAvg) : '-',
    color,
  })

  if (LightAvg >= 40 && LightAvg <= 45) {
    color = 'table-success'
  } else if (LightAvg > 50) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportData.Light.push({
    value: LightAvg > 0 ? Math.round(LightAvg) : 0,
    view: LightAvg > 0 ? Math.round(LightAvg) : '-',
    color,
  })

  if (DeepAvg >= 20 && DeepAvg <= 25) {
    color = 'table-success'
  } else if (DeepAvg < 20) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportData.Deep.push({
    value: DeepAvg > 0 ? Math.round(DeepAvg) : 0,
    view: DeepAvg > 0 ? Math.round(DeepAvg) : '-',
    color,
  })

  if (getReferences) {
    weeklyReportMetrics['REM Onset Latency'].unshift({
      view: '50-150m',
      value: 0,
      color,
    })

    weeklyReportMetrics['Time in Bed'].unshift({
      view: 'TST + 30m',
      value: '0',
      color,
    })
    weeklyReportMetrics['Total Sleep Time'].unshift({
      view: '7-9h',
      color,
      value: 0,
    })
    weeklyReportMetrics['Sleep Onset Latency'].unshift({
      view: '15-20m',
      value: '0',
      color,
    })
    weeklyReportMetrics['Sleep Efficiency'].unshift({
      view: `>85%`,
      color,
      value: 0,
    })

    weeklyReportMetrics['Time Awake'].unshift({
      view: '<30m',
      color,
      value: avgWASO,
    })

    weeklyReportMetrics.Absence.unshift({
      view: '-',
      value: avgAbsence,
      color,
    })

    weeklyReportData.Wake.unshift({
      value: WakeAvg > 0 ? Math.round(WakeAvg) : 0,
      view: '-',
      color,
    })

    weeklyReportData.REM.unshift({
      value: REMAvg > 0 ? Math.round(REMAvg) : 0,
      view: '20-25',
      color,
    })
    weeklyReportData.Light.unshift({
      value: LightAvg > 0 ? Math.round(LightAvg) : 0,
      view: '40-45',
      color,
    })
    weeklyReportData.Deep.unshift({
      value: DeepAvg > 0 ? Math.round(DeepAvg) : 0,
      view: '20-25',
      color,
    })
  }

  return { weeklyReportMetrics, weeklyReportData, reportSleepSessions }
}

export default formattingFunctions
