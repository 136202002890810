import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import Amplify from '@aws-amplify/core'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { createHashHistory } from 'history'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'
import * as serviceWorker from './serviceWorker'
import config from './config'
// app styles
import './global.scss'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]

if (process.env.NODE_ENV === 'development' && true) {
  middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: 'vc-profile',
      region: config.cognito.REGION,
    },
  },
  API: {
    endpoints: [
      {
        name: 'virtualClinic',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: true,
  },
})
sagaMiddleware.run(sagas)

function initializeReactGA() {
  const { location } = window
  const { hostname } = location
  if (hostname === 'cns.virtualsleepclinic.com') {
    ReactGA.initialize('UA-90936728-9')
    console.log('Google Analytics initialized')
    ReactGA.pageview('/homepage')
  }
}

initializeReactGA()
ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)
serviceWorker.register()
export { store, history }
