import { notification } from 'antd'
import API from '@aws-amplify/api'

import { apiPaths } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'packages/SET_STATE',
  GET_PACKAGES: 'packages/GET_PACKAGES',
  ADD_PACKAGE: 'packages/ADD_PACKAGE',
  GET_ALL_PACKAGES: 'packages/GET_ALL_PACKAGES',
  UPDATE_PACKAGE: 'packages/UPDATE_PACKAGE',
  DISABLE_PACKAGE: 'packages/DISABLE_PACKAGE',
}

export const getPackages = (role, adminId, status) =>
  API.get('virtualClinic', apiPaths[role].apiPackageList, {
    headers: {},
    queryStringParameters: {
      admin_id: adminId,
      status,
    },
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      console.log('getPakages error', err)
      notification.warning({
        message: 'No Packages Found',
        description: `${response && response.data ? response.data : err}`,
      })
      return {
        pkgs: [],
        devices: [],
      }
    })

export const getAllPackages = (role, adminId, status) =>
  API.get('virtualClinic', apiPaths[role].apiPackageList, {
    headers: {},
    queryStringParameters: {
      admin_id: adminId,
      status,
    },
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      if (response) {
        if (response.status === 404) {
          notification.warning({
            message: 'No Packages Found',
            description: `${response && response.data ? response.data : err}`,
          })
        }
      } else {
        notification.warning({
          message: 'Something Went wrong',
          description: err.message,
        })
      }
      console.log('err', err)
      return {
        pkgs: [],
        devices: [],
      }
    })

export const addPackage = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiPackageAdd, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success',
        description: 'Package Assigned Successfully',
      })
      return res
    })
    .catch(err => {
      const { response } = err
      notification.warning({
        message: 'Error Adding Package',
        description: `${response && response.data ? response.data : err}`,
      })
      return err
    })

export const updatePackage = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiPackageUpdate, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success',
        description: 'Package Updated Successfully',
      })
      return res
    })
    .catch(err => {
      const { response } = err
      console.log('response', response, err, err.message)
      notification.warning({
        message: 'Something went wrong',
        description: response ? response.data : err.message,
      })
      return err
    })

export const deletePackage = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiPackageDelete, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success',
        description: 'Package Deleted Successfully',
      })
      return res
    })
    .catch(err => {
      const { response } = err
      notification.warning({
        message: 'Error Deleting Package',
        description: `${response && response.data ? response.data : err}`,
      })
      return err
    })

export const disablePackage = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiPackageDisable, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success',
        description: 'Package Disabled Successfully',
      })
      return res
    })
    .catch(err => {
      const { response } = err
      notification.warning({
        message: 'Error Disabling Package',
        description: `${response && response.data ? response.data : err}`,
      })
      return err
    })

export default actions
