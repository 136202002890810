import { notification } from 'antd'
import API from '@aws-amplify/api'

import { apiPaths } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'member/SET_STATE',
  GET_MEMBERS: 'member/GET_MEMBERS',
  ADD_NEW_MEMBER: 'member/ADD_NEW_MEMBER',
  UPDATE_MEMBER: 'member/UPDATE_MEMBER',
  GET_MEMBER_DETAIL: 'member/GET_MEMBER_DETAIL',
  SET_STATUS: 'member/SET_STATUS',
  DELETE_MEMBER: 'member/DELETE_MEMBER',
  GET_ACM_APIS: 'member/GET_ACM_APIS',
  GET_PROVIDER_PROFILE: 'member/GET_PROVIDER_PROFILE',
}

// API call to get Details of a specific Member
export const getMemberDetail = userUID =>
  API.get('virtualClinic', `/virtualclinic-temp/v1/memberDetail`, {
    // OPTIONAL
    headers: {},
    queryStringParameters: {
      // OPTIONAL
      user_uid: userUID,
    },
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return {}
    })

// API call to get a List of all the members assigned to a provider or all the members in case of Super Admin
export const getAllMembers = (dataObj, role) =>
  API.get('virtualClinic', apiPaths[role] ? apiPaths[role].apiMemberListing : '', {
    headers: {},
    queryStringParameters: dataObj,
  })
    .then(res => ({ data: res }))
    .catch(err => {
      const { response } = err
      const { status } = response
      notification.error({
        message: `${status === 404 ? 'Not Found' : 'Someting went wrong'}`,
        description: `${response && response.data ? response.data : err}`,
      })
      return {
        err: `${response && response.data ? response.data : err}`,
        data: [],
      }
    })

// API call to get a List of all the shared Members
export const getSharedMembers = (dataObj, role) =>
  API.get('virtualClinic', apiPaths[role] ? apiPaths[role].apiSharedMemberListing : '', {
    headers: {},
    queryStringParameters: dataObj,
  })
    .then(res => ({ data: res }))
    .catch(err => {
      const { response } = err
      console.log('err', response, err)

      return { err, data: [] }
    })

// API call to add a New Member under the logged In Provider or Incase of Super Admin, add a New Member, Provider or Super Admin
export const addMember = (dataObj, role, memberType) =>
  API.post('virtualClinic', apiPaths[role] ? apiPaths[role].apiMemberCreate : '', {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: `Success`,
        description: `${memberType} created successfully`,
      })
      return res
    })
    .catch(err => {
      const { response } = err

      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return []
    })

// API call to update the details of a Specific member as a Provider, and in case of SuperAdmin, Provider and Superadmins also
export const updateMember = (dataObj, role, memberType) =>
  API.post('virtualClinic', apiPaths[role] ? apiPaths[role].apiMemberUpdate : '', {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: `Success`,
        description: `${memberType} updated successfully`,
      })
      return res
    })
    .catch(err => {
      const { response } = err
      const data = response ? response.data : 'No response from server'
      notification.error({
        message: `Someting went wrong`,
        description: `${data}`,
      })
      return []
    })

// API call to update the Status of a specific member registered under the logged in provider.
export const setStatus = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role] ? apiPaths[role].apiMemberStatusUpdate : '', {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: `Success`,
        description: 'Member status updated',
      })
      return res
    })
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response.data}`,
      })
      return []
    })

// API call to get the sum of all Sleep Sesssions done by all the Members registered under the logged In Provider, or All Members in case of SuperAdmin.
export const getTotalSleepSessions = (dataObj, role) =>
  API.get('virtualClinic', apiPaths[role] ? apiPaths[role].apiGetTotalSleepSessions : '', {
    headers: {},
    queryStringParameters: dataObj,
  })
    .then(res => (res[0] ? res[0].sleep_count : '-'))
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return '-'
    })

// API call to get delete a specific member registered under the logged in Provider
export const deleteMember = dataObj =>
  API.post('virtualClinic', '/sa-user/v1/delete', {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: `Success`,
        description: `User deleted successfully`,
      })
      return res
    })
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response.data}`,
      })
      return []
    })

// API call to get a List of all the Active Members registered under the logged in Provider.
export const getAllActiveMembers = (dataObj, role) =>
  API.get('virtualClinic', apiPaths[role] ? apiPaths[role].apiActiveMemberListing : '', {
    headers: {},
    queryStringParameters: dataObj,
  })
    .then(res => ({ data: res }))
    .catch(err => ({ err, data: [] }))

// API call to get a List of API's
export const getACMAPI = () =>
  API.get('virtualClinic', '/sa-acm/v1/getAllApis')
    .then(res => res)
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return []
    })

// API call to get a List of API's the Specified role is authorized to access.
export const getAccess = group =>
  API.get('virtualClinic', '/sa-acm/v1/getAllApisOfRole', {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      group,
    },
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return []
    })

export default actions
