import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import moment from 'moment'
import actions, {
  getAllDevices,
  getDeviceActivity,
  getClinicDeviceActivity,
  addDevice,
  getAllInventoryDevices,
  updateDevice,
  addDeviceInInventory,
  assignDeviceToProvider,
  deleteDevice,
  // CMS DEVICE APIs
  getDeviceSessions,
  getDeviceSession,
  getDeviceDetail,
  returnDevice,
  getDeviceMetricsHistory,
  getDeviceBodyClock,
  linkPatient,
  getPatientSessions,
  getDevicePatientsList,
  // changeDeviceName,
  // getDeviceAVGSleepMetrics,
  // getDeviceCSIHistory,
  // getLastSleepStagesHistory,
} from './actions'

import {
  formatSleepStagesHistory,
  formatTrendsData,
  formatCProcessData,
  formatTimelineData,
  formatBodyClockSummary,
} from '../profile/sagas'
import { getMemberDetail, getOffsetFromRecommendation } from '../profile/actions'
import { GET_CLINIC_PATIENTS } from '../clinicPatient/sagas'

const getUser = (state) => state.user
const getClinic = (state) => state.clinic

export function* GET_DEVICE_SESSIONS({ payload }) {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const reportDeviceSessions = yield call(getDeviceSessions, payload)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      reportDeviceSessions,
      loading: false,
    },
  })
}

// Function to get A List of Devices from Server and Save it in Redux Store
export function* GET_DEVICES() {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const devices = yield call(getAllDevices)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: false,
      devices,
    },
  })
}

// Function to get Device Activity of a specific device and Save it in Redux Store
export function* GET_DEVICE_ACTIVITY({ payload }) {
  const { deviceID } = payload
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const deviceActivity = yield call(getDeviceActivity, deviceID)
  let deviceMember = {}
  if (deviceActivity.length > 0) {
    deviceMember = yield call(getMemberDetail, deviceActivity[0].user_id)
  }
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: false,
      deviceMember,
      deviceActivity,
    },
  })
}

// Function to get CLINIC Device Activity of a specific device and Save it in Redux Store
export function* GET_CLINIC_DEVICE_ACTIVITY({ payload }) {
  const { deviceID } = payload
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const deviceActivity = yield call(getClinicDeviceActivity, deviceID)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: false,
      deviceActivity,
    },
  })
}

// Function to add a New Device in Database and refresh the List Saved in Redux Store
export function* ADD_DEVICE(payload) {
  const { data } = payload
  yield put({
    type: 'device/SET_STATE',
    payload: {
      formLoading: true,
      redirect: false,
      deviceForm: 'please wait',
    },
  })

  const { role } = yield select(getUser)
  const deviceForm = yield call(addDevice, data, role)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      formLoading: false,
      redirect: true,
      deviceForm,
    },
  })
}

export function* GET_DEVICE_TRENDS({ payload }) {
  // const { deviceID } = payload
  payload.date = moment().format('YYYY-MM-DD')
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      tabLoading: true,
    },
  })
  const trendsData = yield call(getDeviceMetricsHistory, payload)
  const { weeklyTrendsData, monthlyTrendsData, fortnightTrendsData } = formatTrendsData(trendsData)

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      monthlyTrendsData,
      weeklyTrendsData,
      fortnightTrendsData,
      tabLoading: false,
    },
  })
}

// Function to update a specific Device in Database and refresh the List Saved in Redux Store
export function* UPDATE_DEVICE(payload) {
  const { data } = payload
  yield put({
    type: 'device/SET_STATE',
    payload: {
      formLoading: true,
      redirect: false,
      deviceForm: 'please wait',
    },
  })

  const { role } = yield select(getUser)
  const deviceForm = yield call(updateDevice, data, role)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      formLoading: false,
      redirect: true,
      deviceForm,
    },
  })
}

// Function to add a New Device in Inventory and refresh the List Saved in Redux Store
export function* ADD_DEVICE_INVENTORY(payload) {
  const { data } = payload
  const { role } = yield select(getUser)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      modalLoading: true,
    },
  })
  yield call(addDeviceInInventory, data, role)
  yield call(GET_INVENTORY_DEVICES, {
    payload: {
      status: 1,
    },
  })

  yield put({
    type: 'device/SET_STATE',
    payload: {
      modalLoading: false,
      modalVisible: false,
    },
  })
}

// Function to assign a specific device to a specific User and Refresh the Inventory in Redux Store
export function* ASSIGN_DEVICE_TO_USER({ payload }) {
  const { data } = payload
  const { role } = yield select(getUser)

  yield put({
    type: 'device/SET_STATE',
    payload: {
      assignModalLoading: true,
    },
  })

  yield call(assignDeviceToProvider, data, role)

  yield call(GET_INVENTORY_DEVICES, {
    payload: {
      status: 1,
    },
  })

  yield put({
    type: 'device/SET_STATE',
    payload: {
      assignModalLoading: false,
      assignModalVisible: false,
    },
  })
}

// Function to get a List of All the devices in Inventory Database and store it in Redux Store
export function* GET_INVENTORY_DEVICES({ payload }) {
  const { status } = payload
  yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: true,
      inventoryDevices: [],
    },
  })
  const { role, details } = yield select(getUser)
  const recID = role === 'Superadmin' ? details.admin_id : details.user_id

  const inventoryDevices = yield call(getAllInventoryDevices, role, recID, status)

  let payloadToBeSent = {
    inventoryLoading: false,
    inventoryDevices,
  }
  if (status === 1) {
    payloadToBeSent = {
      inventoryLoading: false,
      inventoryDevices,
      inventoryDevicesList: inventoryDevices,
    }
  }
  yield put({
    type: 'device/SET_STATE',
    payload: payloadToBeSent,
  })
}

// Function to delete a Specific device from the DATABASE and refresh the list stored in Redux Store
export function* DELETE_INVENTORY_DEVICE({ payload }) {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: true,
    },
  })
  const { data } = payload
  const { role } = yield select(getUser)
  yield call(deleteDevice, role, data)

  yield call(GET_INVENTORY_DEVICES, { payload: {} })
}

/* Clinic Management Device Profile */
export function* GET_CLINIC_DEVICES() {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: true,
      loading: true,
    },
  })

  const { userClinic } = yield select(getClinic)
  const { id } = userClinic

  // const inventoryDevices = yield call(getDeviceDetail)
  const inventoryDevices = yield call(getDeviceDetail, { clinic_id: id })
  inventoryDevices.map((item) => {
    console.log('inventoryDevices', item.device_id, item.mr_no, item.patinet_id, item)
    return true
  })
  yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: false,
      loading: false,
      inventoryDevices,
    },
  })
}
export function* GET_DEVICE_DETAIL({ payload }) {
  const { device_id: deviceID } = yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: false,
      modalVisible: false,
    },
  })
  // const device = '30aea4c68778'

  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
      deviceDetail: [],
      deviceSession: {},
      deviceSessions: [],
    },
  })
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      nightByNightLoading: true,
    },
  })
  const [deviceDetail, deviceSessions] = yield all([
    call(getDeviceDetail, payload),
    call(getDeviceSessions, payload),
    call(GET_DEVICE_PATIENTS, { payload: { data: { device_id: deviceID } } }),
  ])
  let { date } = payload
  if (!date) {
    if (deviceSessions.length > 0) {
      date = deviceSessions[0].date_of_sleep
    } else {
      date = moment().format('YYYY-MM-DD')
    }
  }
  payload.date = date
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: false,
      deviceDetail,
      deviceSessions,
    },
  })
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      memberSleepSessions: deviceSessions,
    },
  })
  const deviceSession = yield call(GET_NIGHT_BY_NIGHT_DATA, {
    payload,
  })
  yield put({
    type: 'device/SET_STATE',
    payload: {
      lastTimezone: deviceSession.tz_diff,
    },
  })
  yield call(GET_DEVICE_TRENDS, { payload })
}

export function* GET_NIGHT_BY_NIGHT_DATA({ payload }) {
  const { device_id: deviceID, date } = payload
  if (!deviceID) {
    return false
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      nightByNightLoading: true,
      calendarDate: date,
    },
  })
  const deviceSession = date
    ? yield call(getDeviceSession, payload, date)
    : yield call(getDeviceSession, payload)
  let { tz_diff: tzDiff } = deviceSession

  tzDiff = parseInt(tzDiff, 10)

  if (Object.keys(deviceSession).length > 0) {
    const {
      hypnogramData,
      noiseGraphData,
      motionGraphData,
      respiratoryGraphData,
      respiratoryVariableGraphData,
      absence,
      lightAMChartData,
      presenceData,
    } = formatSleepStagesHistory(deviceSession, tzDiff, 'number')

    /* yield put({
      type: 'device/SET_STATE',
      payload: {
        calendarDate: deviceSession.date_of_sleep,
        loading: false,
        deviceSession,
        hypnogramData,
        noiseGraphData,
        motionGraphData,
        respiratoryGraphData,
        respiratoryVariableGraphData,
        absence,
        lightAMChartData,
        presenceData,
        nightByNightLoading: false,
      },
    }) */
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        calendarDate: !deviceSession.date_of_sleep ? date : deviceSession.date_of_sleep,
        loading: false,
        memberSleepStagesHistory: deviceSession,
        hypnogramData,
        noiseGraphData,
        motionGraphData,
        respiratoryGraphData,
        respiratoryVariableGraphData,
        absence,
        lightAMChartData,
        presenceData,
        nightByNightLoading: false,
      },
    })
  } else {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
        deviceSession: {},
        noiseGraphData: [],
        memberSleepStagesHistory: {},
        respiratoryGraphData: [],
        respiratoryVariableGraphData: [],
        motionGraphData: [],
        lightAMChartData: [],
        presenceData: [],
        hypnogramData: [],
        nightByNightLoading: false,
      },
    })
  }
  return deviceSession
}

export function* CHANGE_DEVICE_NAME({ data }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      deviceNameModalLoading: true,
    },
  })
  // const deviceSession = yield call(changeDeviceName, payload)
  console.log('CHANGE_DEVICE_NAME', data)
}

export function* RETURN_DEVICE({ payload }) {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      inventoryLoading: true,
    },
  })
  const { data } = payload

  yield call(returnDevice, data)

  yield call(GET_CLINIC_DEVICES)
}

export function* LINK_PATIENT({ payload }) {
  const { data } = payload
  yield put({
    type: 'device/SET_STATE',
    payload: {
      patientLinkLoading: true,
    },
  })
  yield call(linkPatient, data)
  yield call(GET_CLINIC_DEVICES)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      patientLinkLoading: false,
      patientModalVisible: false,
    },
  })
  yield call(GET_CLINIC_PATIENTS, { payload: {} })
}

export function* DEVICE_BODY_CLOCK({ payload }) {
  const bodyClockObj = payload
  bodyClockObj.graph = 'bodyclock_points'
  const bodyClockImageObj = payload
  bodyClockImageObj.graph = 'bodyclock'
  const bodyClockProccessCObj = payload
  bodyClockProccessCObj.graph = 'process_c'
  const bodyClockPRCObj = payload
  bodyClockPRCObj.graph = 'prc'
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      tabLoading: true,
      memberBodyClockData: [],
      bodyclockSummary: [],
      memberBodyClockImage: '',
      memberProcessCImage: '',
      memberPRCImage: '',
      memberBodyClockGraphData: [],
    },
  })
  const [
    memberBodyClockData,
    memberPRCImage,
    memberBodyClockImage,
    memberProcessCRequest,
  ] = yield all([
    call(getDeviceBodyClock, bodyClockObj),
    call(getDeviceBodyClock, bodyClockPRCObj),
    call(getDeviceBodyClock, bodyClockImageObj),
    call(getDeviceBodyClock, bodyClockProccessCObj),
  ])
  const memberProcessCImage = memberProcessCRequest.img ? memberProcessCRequest.img : ''
  const recommendation = memberProcessCRequest.recommendation
    ? memberProcessCRequest.recommendation
    : ''
  const bodyClockOffset = getOffsetFromRecommendation(recommendation)
  const CProcessData = yield call(formatCProcessData, memberBodyClockData)
  const timelineData = yield call(formatTimelineData, memberBodyClockData)
  const bodyclockSummary = yield call(formatBodyClockSummary, memberBodyClockData, bodyClockOffset)

  const formattedBodyClockData = { datasets: timelineData.concat(CProcessData) }

  const { datasets } = formattedBodyClockData
  if (Object.keys(datasets).length === 0) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        showFieldsForBodyClock: true,
      },
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      memberBodyClockData,
      bodyclockSummary,
      memberBodyClockImage,
      memberProcessCImage,
      memberPRCImage,
      showFieldsForBodyClock: false,
      memberBodyClockGraphData: formattedBodyClockData,
      tabLoading: false,
    },
  })
  return bodyclockSummary
}

export function* GET_PATIENT_SESSION_DATES({ payload }) {
  const { data } = payload

  yield put({
    type: 'device/SET_STATE',
    payload: {
      patientSessionsLoading: true,
    },
  })

  const patientSessionDates = yield call(getPatientSessions, data)
  return yield put({
    type: 'device/SET_STATE',
    payload: {
      patientSessionsLoading: false,
      patientSessionDates,
    },
  })
}
export function* GET_DEVICE_PATIENTS({ payload }) {
  const { data } = payload

  yield put({
    type: 'device/SET_STATE',
    payload: {
      devicePatientsLoading: true,
    },
  })
  const devicePatients = yield call(getDevicePatientsList, data)

  return yield put({
    type: 'device/SET_STATE',
    payload: {
      devicePatientsLoading: false,
      devicePatients,
    },
  })
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DEVICES, GET_DEVICES),
    takeEvery(actions.ASSIGN_DEVICE_TO_USER, ASSIGN_DEVICE_TO_USER),
    takeEvery(actions.GET_DEVICE_ACTIVITY, GET_DEVICE_ACTIVITY),
    takeEvery(actions.GET_CLINIC_DEVICE_ACTIVITY, GET_CLINIC_DEVICE_ACTIVITY),
    takeEvery(actions.ADD_DEVICE, ADD_DEVICE),
    takeEvery(actions.ADD_DEVICE_INVENTORY, ADD_DEVICE_INVENTORY),
    takeEvery(actions.UPDATE_DEVICE, UPDATE_DEVICE),
    takeEvery(actions.DELETE_INVENTORY_DEVICE, DELETE_INVENTORY_DEVICE),
    takeEvery(actions.GET_INVENTORY_DEVICES, GET_INVENTORY_DEVICES),
    takeEvery(actions.GET_DEVICE_SESSIONS, GET_DEVICE_SESSIONS),

    // CLINIC MANAGEMENT DEVICE PROFILE APIs
    takeEvery(actions.GET_DEVICE_DETAIL, GET_DEVICE_DETAIL),
    takeEvery(actions.RETURN_DEVICE, RETURN_DEVICE),
    takeEvery(actions.GET_DEVICE_TRENDS, GET_DEVICE_TRENDS),
    // takeEvery(actions.GET_DEVICE_SLEEP_STAGES_HISTORY, GET_DEVICE_SLEEP_STAGES_HISTORY),
    takeEvery(actions.DEVICE_BODY_CLOCK, DEVICE_BODY_CLOCK),
    takeEvery(actions.GET_NIGHT_BY_NIGHT_DATA, GET_NIGHT_BY_NIGHT_DATA),
    takeEvery(actions.CHANGE_DEVICE_NAME, CHANGE_DEVICE_NAME),
    takeEvery(actions.LINK_PATIENT, LINK_PATIENT),
    takeEvery(actions.GET_PATIENT_SESSION_DATES, GET_PATIENT_SESSION_DATES),
    takeEvery(actions.GET_DEVICE_PATIENTS, GET_DEVICE_PATIENTS),
    // takeEvery(actions.GET_DEVICE_AVG_SLEEP_METRICS, GET_DEVICE_AVG_SLEEP_METRICS),
  ])
}
