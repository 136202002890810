import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import moment from 'moment'
import actions, { getReferrals, getWeeklyReports } from './actions'

const getUser = state => state.user

// Function to get A List of Invoices from Server and Save it in Redux Store
export function* GET_REFERRALS() {
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { role } = yield select(getUser)
  const referralList = yield call(getReferrals, role)
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      loading: false,
      referralList,
    },
  })
}

// Function to get a List of Weekly Report from Server and Save  it in Redux Store
export function* GET_WEEKLY_REPORTS({ payload }) {
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      weeklyReportsLoading: true,
    },
  })
  const { role } = yield select(getUser)

  const { data } = payload

  const weeklyReportsList = yield call(getWeeklyReports, role, data)
  let formattedWeeklyReports = []
  let totalSleepSessions = 0
  let newUsers = 0
  if (Object.keys(weeklyReportsList).length < 1) {
    return yield put({
      type: 'referral/SET_STATE',
      payload: {
        weeklyReportsLoading: false,
      },
    })
  }
  weeklyReportsList.week.map(datum => {
    totalSleepSessions += datum.ss_count
    formattedWeeklyReports.push(datum)
    return true
  })
  const temp = []

  weeklyReportsList.users.map(datum => {
    formattedWeeklyReports.map(datum2 => {
      if (datum2.user_uid === datum.user_id) {
        temp.push({ ...datum2, ...datum })
      }
      return true
    })

    return true
  })
  const newUsersList = []
  formattedWeeklyReports = temp
  formattedWeeklyReports = formattedWeeklyReports.filter(datum => {
    let isNewUser = false

    weeklyReportsList.all.map(datum2 => {
      if (datum.user_uid === datum2.user_uid) {
        if (datum.ss_count <= 7 && datum.ss_count === datum2.ss_count) {
          newUsers += 1
          datum.totalSleepSessions = datum2.ss_count

          const firstSleepSession = moment(datum2.fss, 'YYYY-MM-DD')

          const lastSleepSession = moment()
          datum.fss = datum2.fss
          datum.lss = datum2.lss

          let difference = lastSleepSession.diff(firstSleepSession, 'days')
          if (difference === 0) {
            difference = 1
          } else if (difference < datum.ss_count) {
            difference = datum.ss_count
          }

          datum.difference = difference

          if (difference > 7) {
            difference = 7
          }

          datum.rateOfSleep = ((datum.ss_count / difference) * 100).toFixed(0)
          datum.isNewUser = true
          newUsersList.push(datum)
          isNewUser = true
          return false
        }

        datum.rateOfSleep = ((datum.ss_count / 7) * 100).toFixed(0)
        datum.isNewUser = false
        datum.totalSleepSessions = datum2.ss_count
        datum.fss = datum2.fss
        datum.lss = datum2.lss
      }
      return true
    })
    return !isNewUser
  })

  formattedWeeklyReports = newUsersList.concat(formattedWeeklyReports)
  let todaySleepSessions = 0
  formattedWeeklyReports.map(report => {
    if (report.lss === moment().format('YYYY-MM-DD')) {
      todaySleepSessions += 1
    }
    return true
  })
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      weeklyReportsList: formattedWeeklyReports,
      weeklyReportsLoading: false,
      totalSleepSessions,
      newUsers,
      todaySleepSessions,
    },
  })
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_REFERRALS, GET_REFERRALS),
    takeEvery(actions.GET_WEEKLY_REPORTS, GET_WEEKLY_REPORTS),
  ])
}
