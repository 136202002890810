import API from '@aws-amplify/api'
import { apiPaths } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'invoice/SET_STATE',
  GET_INVOICES: 'invoice/GET_INVOICES',
}

// API call to get a List of All Devices filtered by the current loggeed in Provider if a Provider is logged in, or unfiltered if Superadmin is logged in.
export const getInvoices = async role =>
  API.get('virtualClinic', apiPaths[role].apiInvoiceList)
    .then(res => res)
    .catch(err => {
      console.log('invoiceList', err)
      return []
    })

export default actions
