import API from '@aws-amplify/api'
import { apiPaths } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'referral/SET_STATE',
  GET_REFERRALS: 'referral/GET_REFERRALS',
  GET_WEEKLY_REPORTS: 'referral/GET_WEEKLY_REPORT',
}

// API call to get a List of All Devices filtered by the current logged in Provider if a Provider is logged in, or unfiltered if Superadmin is logged in.
export const getReferrals = async role =>
  API.get('virtualClinic', apiPaths[role].apiReferralList)
    .then(res => res)
    .catch(() => [])

// API call to get a List of all Weekly Reports
export const getWeeklyReports = async (role, data) =>
  API.get('virtualClinic', apiPaths[role].apiWeeklyReportsList, {
    queryStringParameters: data,
  })
    .then(res => res)
    .catch(err => {
      console.log('getWeeklyReports error', err)
      return {}
    })

export default actions
