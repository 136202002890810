import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import Storage from '@aws-amplify/storage'
import moment from 'moment'
import actions, {
  getClinics,
  addClinic,
  getClinicID,
  getClinicDetail,
  updateClinic,
  emailReport,
  getClinicUsers,
  sendDownloadReport,
} from './actions'

import { getClinicDevices } from '../device/actions'
import { GET_CLINIC_DEVICES } from '../device/sagas'
import { GET_CLINIC_PATIENTS } from '../clinicPatient/sagas'

const getUser = state => state.user

// Function to get A List of Invoices from Server and Save it in Redux Store
export function* GET_CLINICS() {
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: true,
      clinicsList: [],
    },
  })
  const { role } = yield select(getUser)
  const clinicsList = yield call(getClinics, role)
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: false,
      clinicsList,
    },
  })
}

export function* GET_CLINIC_COMPLETE_PROFILE({ payload }) {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
      clinicDevicesList: [],
    },
  })
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      clinicProfileLoading: true,
    },
  })
  const { role } = yield select(getUser)
  const clinicDetail = yield call(getClinicDetail, payload, role)
  const clinicDevicesList = yield call(getClinicDevices, payload)
  const { logo } = clinicDetail
  const userClinicImage = yield call(getImageFromS3, 'clinic', logo)
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      userClinic: clinicDetail,
      userClinicImage,
      clinicProfileLoading: false,
    },
  })
  yield put({
    type: 'device/SET_STATE',
    payload: {
      clinicDevicesList,
    },
  })
}

export function* GET_CLINIC_DETAIL({ payload }) {
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: true,
      clinicProfileLoading: true,
      clinicDetail: {},
    },
  })
  const { role } = yield select(getUser)
  const clinicDetail = yield call(getClinicDetail, payload, role)
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: false,
      clinicProfileLoading: false,
      clinicDetail,
    },
  })
}

export function* GET_CLINICID() {
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      userClinicLoading: true,
    },
  })
  const userClinic = yield call(getClinicID)
  console.log('userClinic', userClinic)
  if (userClinic.length > 0) {
    let selectedClinic = userClinic[0]
    // localStorage.removeItem('selectedClinic')
    if (typeof Storage !== 'undefined' && localStorage.getItem('selectedClinic')) {
      const id = localStorage.getItem('selectedClinic')
      console.log('local data ', JSON.stringify(localStorage.getItem('selectedClinic')), id)
      const [firstIndex] = userClinic.filter(item => item.id === parseInt(id, 10))
      selectedClinic = firstIndex
      console.log('local data', selectedClinic)
    }
    yield call(CHANGE_SELECTED_CLINIC, { payload: selectedClinic })
    // const { logo } = selectedClinic

    // const userClinicImage = yield call(getImageFromS3, 'clinic', logo)
    yield put({
      type: 'clinic/SET_STATE',
      payload: {
        // userClinicLoading: false,
        // userClinic: selectedClinic,
        clinicList: userClinic,
        // userClinicImage,
      },
    })
  }
}

export function* CHANGE_SELECTED_CLINIC({ payload }) {
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      userClinicLoading: true,
    },
  })
  if (typeof Storage !== 'undefined') {
    // Store
    console.log('payload', payload)
    const { id } = payload
    localStorage.setItem('selectedClinic', id)
    // localStorage.setItem('lastname', 'Smith')
    // console.log('local data ', localStorage.getItem('lastname'), id)
  }

  const { logo } = payload

  const userClinicImage = yield call(getImageFromS3, 'clinic', logo)
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      userClinicLoading: false,
      userClinic: payload,
      userClinicImage,
    },
  })
  yield call(GET_CLINIC_DEVICES)
  yield call(GET_CLINIC_PATIENTS, { payload: {} })
}

export function* ADD_CLINIC(dataPayload) {
  const { payload } = dataPayload
  const { data } = payload
  const { logo, ...formattedData } = data
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { role } = yield select(getUser)
  const newClinic = yield call(addClinic, formattedData, role)

  if (logo) {
    const fileName = yield call(
      uploadFile,
      'clinic',
      logo.originFileObj,
      `${newClinic}-${moment().format('YYYYMMDD-HHmm')}`,
    )

    const updateData = {
      ...formattedData,
      logo: fileName,
      user_type: 'sa',
      clinic_id: `${newClinic}`,
    }

    yield call(UPDATE_CLINIC, { payload: { data: updateData, showNotification: false } })
  }
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: false,
      newClinic,
      redirect: true,
    },
  })
  return yield call(GET_CLINICS)
}

export function* UPDATE_CLINIC(dataPayload) {
  const { payload } = dataPayload
  const { data, showNotification = true } = payload
  const { logo, isImageChanged, ...formattedData } = data
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { role } = yield select(getUser)
  const { clinic_id: clinicID } = formattedData
  if (isImageChanged) {
    const fileName = yield call(
      uploadFile,
      'clinic',
      logo.originFileObj,
      `${clinicID}-${moment().format('YYYYMMDD-HHmm')}`,
    )

    formattedData.logo = fileName
  } else {
    formattedData.logo = logo
  }
  const editedClinic = yield call(updateClinic, formattedData, role, showNotification)
  const clinicsList = yield call(getClinics, role)
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      loading: false,
      editedClinic,
      redirect: true,
      clinicsList,
    },
  })
  // return yield call(GET_CLINICS)
}

export function* GET_CLINIC_USERS({ payload }) {
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      clinicUsersloading: true,
      clinicUsers: [],
    },
  })
  const { role } = yield select(getUser)
  const { data } = payload
  const clinicUsers = yield call(getClinicUsers, role, data)
  yield put({
    type: 'clinic/SET_STATE',
    payload: {
      clinicUsersloading: false,
      clinicUsers,
    },
  })
}

export function* SEND_DOWNLOAD_REPORT({ payload }) {
  const { data } = payload
  const { role } = yield select(getUser)

  yield call(sendDownloadReport, role, data)
}

export function* SEND_EMAIL_REPORT({ payload }) {
  const { pdfFile, ...data } = payload.data
  const { memberDetail } = payload
  const { name: patientName } = memberDetail
  const { name, role } = yield select(getUser)

  const initials = name
    .split(' ')
    .map(val => val.split('')[0])
    .join('')
  const patientInitials = patientName
    .split(' ')
    .map(val => val.split('')[0])
    .join('')
  const fileName = `${initials}${moment().format('MMDD')}.pdf`
  const upload = yield call(uploadFile, 'vc-report', pdfFile, fileName, false, false)
  data.file_name = fileName
  data.sender_detail = `${initials}${moment().format('MMDD')}`
  data.url = 'circadia.health'
  data.details = {
    patient_id: data.patient_id,
    patient_name: patientInitials,
    date: moment().format('DD-MM-YYYY'),
    sender_name: name,
    password: `${initials}${moment().format('MMDD')}`,
  }
  yield call(emailReport, role, data)
  console.log(upload)
}

export function* EMAIL_REPORT_LINK({ payload }) {
  const { data } = payload
  const { role } = yield select(getUser)

  yield call(emailReport, role, data)
}

export const uploadFile = async (
  path,
  file,
  fileName,
  isExtension = true,
  useDefaultBucket = true,
) => {
  const ext = getFileExtension(file.name)
  let fileNameToBeSent = `${fileName}`
  if (isExtension) {
    fileNameToBeSent = `${path}/${fileName}.${ext}`
  }
  if (useDefaultBucket) {
    await Storage.put(fileNameToBeSent, file).then(callback => callback)
  } else {
    Storage.configure({
      AWSS3: {
        bucket: 'vc-report',
        region: 'us-east-1',
      },
    })
    await Storage.put(fileNameToBeSent, file)
      .then(callback => callback)
      .catch(e => console.log('error uploading file', e))

    Storage.configure({
      AWSS3: {
        bucket: 'vc-profile',
        region: 'us-east-1',
      },
    })
  }
  return `${fileName}.${ext}`
}

export const getFileExtension = filename => {
  const re = /(?:\.([^.]+))?$/
  const ext = re.exec(filename)[1]

  return ext
}

export const getImageFromS3 = async (path, fileName) => {
  const image = await Storage.get(`${path}/${fileName}`, {})
    .then(result => result)
    .catch(err => console.log('ImageFromS3 error ', err))
  return image
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CLINICS, GET_CLINICS),
    takeEvery(actions.ADD_CLINIC, ADD_CLINIC),
    takeEvery(actions.UPDATE_CLINIC, UPDATE_CLINIC),
    takeEvery(actions.GET_CLINICID, GET_CLINICID),
    takeEvery(actions.GET_CLINIC_DETAIL, GET_CLINIC_DETAIL),
    takeEvery(actions.GET_CLINIC_COMPLETE_PROFILE, GET_CLINIC_COMPLETE_PROFILE),
    takeEvery(actions.GET_CLINIC_USERS, GET_CLINIC_USERS),
    takeEvery(actions.SEND_DOWNLOAD_REPORT, SEND_DOWNLOAD_REPORT),
    takeEvery(actions.EMAIL_REPORT_LINK, EMAIL_REPORT_LINK),
    takeEvery(actions.SEND_EMAIL_REPORT, SEND_EMAIL_REPORT),
  ])
}
