import actions from './actions'

// Defining the Initial Variables to be saved in the Redux Store on initialize.
const initialState = {
  filteredType: '',
  activeMembers: [],
  members: [],
  allMembers: [],
  allSharedMembers: [],
  sharedMembers: [],
  loading: false,
  newMembers: '',
  memberUpdated: '',
  totalSleepSessions: '-',
  memberDetail: {},
  setStatusLoading: false,
  formLoading: false,
  redirect: false,
  acmAPIs: [],
  saAccess: [],
  vcAdminAccess: [],
  memberAccess: [],
  acmLoading: false,
  providerDetail: {},
  providerMembersloading: false,
  providerActiveMembers: [],
  providerMembers: [],
  providerSharedMembers: [],
  providerTotalSleepSessions: '-',
  lastFormValues: {},
}

export default function memberReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
