import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'

import { notification } from 'antd'
import { apiPaths } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGETPASSWORD_SEND_CODE: 'user/FORGETPASSWORD_SEND_CODE',
  FORGOTPASSWORD_SUBMIT: 'user/FORGOTPASSWORD_SUBMIT',
  EMAIL_VERIFY_SEND_CODE: 'user/EMAIL_VERIFY_SEND_CODE',
  EMAIL_VERIFY_SUBMIT: 'user/EMAIL_VERIFY_SUBMIT',
  INVITE_NEW_MEMBER: 'user/INVITE_NEW_MEMBER',
  ASSIGN_MEMBER_TO_PROVIDER: 'user/ASSIGN_MEMBER_TO_PROVIDER',
  SHARE_MEMBER: 'user/SHARE_MEMBER',
  GET_LINKED_PROVIDERS: 'user/GET_LINKED_PROVIDERS',
  UNLINK_PROVIDER: 'user/UNLINK_PROVIDER',
  REVOKE_PROVIDER: 'user/REVOKE_PROVIDER',
  EDIT_SLEEP_SESSION: 'user/EDIT_SLEEP_SESSION',
}

export const forgotPasswordSendCode = username =>
  Auth.forgotPassword(username)
    .then(data => ({ status: 200, data }))
    .catch(err => ({ status: 403, err }))

export const forgotPasswordSubmit = (username, code, newPassword) =>
  Auth.forgotPasswordSubmit(username, code, newPassword)
    .then(data => ({ status: 200, data }))
    .catch(err => ({ status: 403, err }))

export const verifyAttributeEmailCode = attr =>
  Auth.verifyCurrentUserAttribute(attr)
    .then(data => ({ status: 200, data }))
    .catch(err => ({ status: 403, err }))

export const verifyEmail = (attributes, code) =>
  Auth.verifyCurrentUserAttributeSubmit(attributes, code)
    .then(data => ({ status: 200, data }))
    .catch(err => ({ status: 403, err }))

// API call to Share Member
export const shareMember = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiShareMember, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success.',
        description: 'Member shared successfully',
      })

      return res
    })
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return ''
    })

export const sleepSessionCreate = (role, dataObj) =>
  API.post('virtualClinic', apiPaths[role].apiSessionCreate, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => res)
    .catch(err => {
      console.log('response is', response)
      const { response } = err
      notification.warning({
        message: 'Error creating sleep session',
        description: `${response ? response.data : 'Something went wrong'}`,
      })
      console.log('SleepSessionCreate error: ', err, response, err.code)
      return err
    })

// API call to Assign a Member to a Provider as a Super Admin
export const assignMemberToProvider = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiAssignMemberToProvider, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success.',
        description: 'Member assigned to Provider',
      })

      return res
    })
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return ''
    })

// API call to Revoke a Provider from a Member as a Super Admin
export const revokeProvider = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiRevokeProvider, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success.',
        description: 'Provider Revoked',
      })

      return res
    })
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return ''
    })

export const getLinkedProviders = (dataObj, role) =>
  API.get('virtualClinic', apiPaths[role].apiLinkedProvidersListing, {
    header: {},
    queryStringParameters: dataObj,
  })
    .then(res => res)
    .catch(err => {
      const { response } = err

      notification.warning({
        message: 'Something went wrong.',
        description: `${response && response.data ? response.data : err}`,
      })
    })

export const unlinkProvider = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiUnlinkProvider, {
    header: {},
    body: dataObj,
  })
    .then(res => res)
    .catch(err => {
      const { response } = err

      notification.warning({
        message: 'Something went wrong.',
        description: `${response && response.data ? response.data : err}`,
      })
    })

export const inviteMember = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiInviteMember, {
    headers: {},
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success.',
        description: 'Invitation Sent',
      })

      return res
    })
    .catch(err => {
      const { response } = err

      notification.warning({
        message: 'Something went wrong.',
        description: `${response && response.data ? response.data : err}`,
      })
    })

export const firstTimeLogin = () =>
  API.post('virtualClinic', `/user-agreement/userAgreement`, {
    headers: {},
    body: {},
  })
    .then(res => res)
    .catch(err => {
      console.log('First Time Login', err)
      return []
    })

export default actions
