import actions from './actions'

// Defining the Initial Variables to be saved in the Redux Store on initialize.
const initialState = {
  sleepSessionsListPageSize: 20,
  sleepSessionsListPageNumber: 1,
  totalSleepSessionRecords: 0,
  sleepSessionList: [],
  sleepSessionDevicesList: [],
  appSessions: {
    total: {},
    detail: [],
  },
  appSessionsList: [],
  totalAppSessions: 0,
  totalAppSessionsDuration: 0,
  appSessionsLoading: false,
  loading: false,
}

export default function referralReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
