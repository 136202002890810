import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Avatar, Menu, Dropdown, Badge } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

@connect(({ user, clinic }) => ({ user, clinic }))
class ProfileMenu extends React.Component {
  state = {
    count: 0,
  }

  logout = () => {
    const { dispatch } = this.props
    sessionStorage.setItem('mLogout', 'true')
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    const { count } = this.state
    this.setState({
      count,
    })
  }

  render() {
    const { user, clinic } = this.props
    const { role } = user
    const { userClinic } = clinic
    const { count } = this.state
    const { details } = user
    const profileLink = `/profile/${details.admin_id}`
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
          </strong>

          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {user.role}
          </div>
        </Menu.Item>

        <Menu.Divider />
        {user.role !== 'Clinic' && (
          <Menu.Item>
            <Link to={profileLink}>
              <i className={`${styles.menuIcon} icmn-user`} />
              Personal Data
            </Link>
          </Menu.Item>
        )}
        {user.role !== 'Clinic' && <Menu.Divider />}
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.addCount}>
        <div className={styles.dropdown}>
          <Badge count={count}>
            <Avatar className={styles.avatar} shape="square">
              {role !== 'Clinic' ? (
                <img
                  alt="aaa"
                  src={`resources/images/${user.CoachImage}`}
                  style={{ width: '100%' }}
                />
              ) : (
                <img
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                    borderRadius: '7px',
                  }}
                  src="resources/images/User-icon.png"
                  alt={userClinic.name}
                />
              )}
            </Avatar>
          </Badge>
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
