import moment from 'moment'
import actions from './actions'

const initialState = {
  showFieldsForBodyClock: false,
  memberDetail: {},
  memberCSIHistory: [],
  lastDeviceId: '',
  reportCSI: 0,
  weeklyReportData: {},
  deviceID: '',
  reportTTA: 0,
  memberSleepStagesHistory: {},
  reportMemberSleepStagesHistory: {},
  memberBodyClockData: {},
  memberBodyClockImage: '',
  memberProcessCImage: '',
  memberPRCImage: '',
  bodyclockSummary: [],
  patientProfileLoading: false,
  memberBodyClockGraphData: {},
  memberSleepSessions: [],
  weeklyTrendsData: [],
  reportSleepSessions: {},
  weeklyReportMetrics: {
    /*  'Start Time': [],
    'End Time': [], */
    'Total Sleep Time': [],
    'Sleep Efficiency': [],
    'Sleep Onset Latency': [],
    'REM Onset Latency': [],
    'Time in Bed': [],
    'Wake After Sleep Onset': [],
    Absence: [],
  },
  weeklyReportMetricsSummary: [],
  monthlyTrendsData: [],
  fortnightTrendsData: [],
  noiseGraphData: [],
  respiratoryGraphData: [],
  respiratoryVariableGraphData: [],
  motionGraphData: [],
  lightAMChartData: [],
  presenceData: [],
  hypnogramData: [],
  calendarDate: moment().format('YYYY-MM-DD'),
  reportCurrentDate: moment().format('YYYY-MM-DD'),
  historyStored: '',
  historyUpdated: '',
  getHistoryNotes: [],
  memberDiary: [],
  loading: false,
  nightByNightLoading: false,
  diaryLoading: false,
  noteLoading: false,
  noteListLoading: false,
  reportDataLoading: false,
  tabLoading: false,
  reportHypnogramData: [],
  reportPresenceData: [],
  reportMotionData: [],
  reportLightData: [],
  reportRespiratoryData: [],
  reportRespiratoryVData: [],
  reportNoiseData: [],
  reportDiaryData: [],
  reportAbsence: 0,
  memberAVGSleepMetrics: {},
}

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
