import { all, takeEvery, put, call, select, spawn } from 'redux-saga/effects'
import { notification } from 'antd'
import ReactGA from 'react-ga'
import login, { currentAccount, logout } from 'services/user'
import { getLeftMenuData } from 'services/menu'
import { GET_MEMBERS } from '../member/sagas'
import { GET_NIGHT_BY_NIGHT_DATA } from '../profile/sagas'
import {
  GET_INVENTORY_DEVICES,
  GET_DEVICES,
  GET_CLINIC_DEVICES,
  GET_NIGHT_BY_NIGHT_DATA as DEVICE_GET_NIGHT_BY_NIGHT_DATA,
} from '../device/sagas'
import { LISTEN_TO_SOCKET, CLOSE_SOCKET } from '../socket/sagas'

import { GET_CLINICS, GET_CLINICID } from '../clinic/sagas'
import { GET_CLINIC_PATIENTS } from '../clinicPatient/sagas'
import actions, {
  forgotPasswordSendCode,
  forgotPasswordSubmit,
  verifyAttributeEmailCode,
  verifyEmail,
  inviteMember,
  sleepSessionCreate,
  assignMemberToProvider,
  shareMember,
  revokeProvider,
  getLinkedProviders,
  unlinkProvider,
} from './actions'
import { getMemberDetail } from '../profile/actions'
/* 
import { GET_SLEEP_SESSIONS } from '../sleepSession/sagas' */

const getUser = state => state.user

export function* LOGIN({ payload }) {
  let { email = '' } = payload
  const { password } = payload
  email = email.trim()
  console.log('trim', email.trim())
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      newPassReq: false,
    },
  })

  const success = yield call(login, email, password, payload)
  if (success.success) {
    localStorage.setItem('VCSessionLastActivity', new Date().getTime())

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  } else if (success.newPassReq) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        newPassReq: true,
        userDetails: success.userDetails,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* FORGETPASSWORD_SEND_CODE({ payload }) {
  const { username } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: true,
    },
  })

  const response = yield call(forgotPasswordSendCode, username)

  if (response.status === 403) {
    if (response.err.code === 'UserNotFoundException') {
      notification.warning({
        message: 'User not found',
        description: 'Email is not registered to our platform',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          forgetPasswordLoading: false,
        },
      })
    } else {
      notification.warning({
        message: response.err.name,
        description: response.err.message,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          forgetPasswordLoading: false,
        },
      })
    }
  } else if (response.status === 200) {
    notification.success({
      message: 'Recovery Code sent',
      description: 'Please check your email for the recovery code',
    })
    return yield put({
      type: 'user/SET_STATE',
      payload: {
        forgetPasswordLoading: false,
        forgetPasswordUsername: username,
        forgetPasswordState: 'codeSent',
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: false,
    },
  })
}

export function* EDIT_SLEEP_SESSION({ payload }) {
  let { role } = yield select(getUser)

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      nightByNightLoading: true,
    },
  })
  const { isDeviceProfile, ...data } = payload.data
  const { clinic_id: clinicId } = data
  if (role === 'Superadmin') {
    data.user_type = 'sa'
  }
  const prevRole = role
  if (isDeviceProfile) {
    role = 'Clinic'
  }
  yield call(sleepSessionCreate, role, data)
  if (!isDeviceProfile) {
    return yield call(GET_NIGHT_BY_NIGHT_DATA, {
      payload: {
        userUID: data.user_id,
        date: data.date_of_sleep,
      },
    })
  }
  if (prevRole === 'Superadmin') {
    return yield call(DEVICE_GET_NIGHT_BY_NIGHT_DATA, {
      payload: {
        device_id: data.device_id,
        date: data.date_of_sleep,
        user_type: 'sa',
        clinic_id: clinicId,
      },
    })
  }
  return yield call(DEVICE_GET_NIGHT_BY_NIGHT_DATA, {
    payload: {
      device_id: data.device_id,
      date: data.date_of_sleep,
    },
  })
}

export function* FORGOTPASSWORD_SUBMIT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: true,
    },
  })

  const { code, password, username } = payload

  const response = yield call(forgotPasswordSubmit, username, code, password)

  if (response.status === 403) {
    notification.warning({
      message: response.err.name,
      description: response.err.message,
    })
  } else if (response.status === 200) {
    notification.success({
      message: 'Password Changed',
      description: 'Password Changed successfully, please login using your new password.',
    })
    return yield put({
      type: 'user/SET_STATE',
      payload: {
        forgetPasswordLoading: false,
        forgetPasswordState: 'passwordChanged',
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  let response = yield call(currentAccount)
  if (!response) {
    response = {
      status: false,
    }
  }
  if (response.status) {
    const { fullName, CoachImage, jwtToken } = response

    let userType = 'Provider'

    if (response.groups) {
      response.groups.map(group => {
        if (group === 'sa') {
          userType = 'Superadmin'
          return true
        }
        if (group === 'member') {
          if (response.groups.length === 1) {
            userType = 'Member'
          }
          return true
        }
        if (group === 'clinic') {
          userType = 'Clinic'
          return true
        }
        return false
      })
    }
    let menuLeftData = yield call(getLeftMenuData, userType)
    menuLeftData = menuLeftData.filter(datum => datum.display !== false)
    const { location } = window
    const { hostname } = location

    if (process.env.NODE_ENV !== 'development') {
      if (
        hostname !== 'beta.virtualsleepclinic.com' &&
        hostname !== 'develop-vc-web.s3-website-us-east-1.amazonaws.com' &&
        userType === 'Superadmin'
      ) {
        notification.error({
          message: `Not Allowed`,
          description: '',
        })
        yield call(LOGOUT)
        return false
      }
    }

    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuLeftData,
      },
    })
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuLeftData,
      },
    })
    const { userID } = response

    yield put({
      type: 'user/SET_STATE',
      payload: {
        name: fullName,
        CoachImage,
        role: userType,
        authorized: true,
      },
    })

    const difference =
      new Date().getTime() - parseInt(localStorage.getItem('VCSessionLastActivity'), 10)
    if (difference >= 300000) {
      yield call(LOGOUT)
      localStorage.setItem('VCSessionLastActivity', new Date().getTime())
      return yield put({
        type: 'user/SET_STATE',
        payload: {
          pauseLoading: true,
          loading: false,
        },
      })
    }

    const memberDetail = yield call(getMemberDetail, userID)
    notification.success({
      message: `Logged In`,
      description: '',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        details: memberDetail,
      },
    })
    if (userType !== 'Clinic' && userType !== 'Member') {
      yield all([
        put({
          type: 'user/SET_STATE',
          payload: {
            pauseLoading: true,
            loading: false,
          },
        }),

        put({
          type: 'member/SET_STATE',
          payload: {
            loading: true,
          },
        }),
        put({
          type: 'clinic/SET_STATE',
          payload: {
            loading: true,
          },
        }),
        put({
          type: 'device/SET_STATE',
          payload: {
            inventoryLoading: true,
          },
        }),
        put({
          type: 'clinic/SET_STATE',
          payload: {
            loading: true,
          },
        }),
      ])
      yield all([
        call(GET_MEMBERS, {
          payload: {
            data: {
              vcstatus: true,
            },
          },
        }),
        call(GET_INVENTORY_DEVICES, {
          payload: {
            status: '1',
          },
        }),
        call(GET_CLINICS),
        call(GET_DEVICES),
        /* 
               call(GET_SLEEP_SESSIONS, { payload: { user_type: 'sa' }, setTotal: true }), */
      ])
    } else if (userType === 'Clinic') {
      yield all([call(GET_CLINICID), call(GET_CLINIC_DEVICES)])
      yield call(GET_CLINIC_PATIENTS, { payload: {} })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          pauseLoading: true,
          loading: false,
        },
      })

      yield spawn(LISTEN_TO_SOCKET, {
        payload: {
          jwtToken,
        },
      })
    }
  }
  return yield put({
    type: 'user/SET_STATE',
    payload: {
      pauseLoading: true,
      loading: false,
    },
  })
}
// function* sleep(time) {
//   yield new Promise(resolve => setTimeout(resolve, time))
// }
export function* LOGOUT() {
  yield call(logout)
  yield call(CLOSE_SOCKET)
  if (typeof Storage !== 'undefined') {
    localStorage.removeItem('selectedClinic')
  }
  yield put({
    type: 'member/SET_STATE',
    payload: {
      activeMembers: [],
      members: [],
      lastFormValues: {},
    },
  })
  yield put({
    type: 'user/SET_STATE',
    payload: {
      details: {},
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* EMAIL_VERIFY_SEND_CODE({ payload }) {
  const { email } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      emailVerifyCodeLoading: true,
    },
  })

  const response = yield call(verifyAttributeEmailCode, email)

  if (response.status === 403) {
    notification.warning({
      message: response.err.name,
      description: response.err.message,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        emailVerifyCodeLoading: false,
      },
    })
  } else if (response.status === 200) {
    notification.success({
      message: 'Verification Code sent',
      description: 'Please check your email for the verification code',
    })
    return yield put({
      type: 'user/SET_STATE',
      payload: {
        emailVerifyCodeLoading: false,
        emailVerifyState: 'codeSent',
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      emailVerifyCodeLoading: false,
    },
  })
}

export function* EMAIL_VERIFY_SUBMIT({ payload }) {
  const { email } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      emailVerifyLoading: true,
    },
  })

  const response = yield call(verifyEmail, email)

  if (response.status === 403) {
    notification.warning({
      message: response.err.name,
      description: response.err.message,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        emailVerifyLoading: false,
      },
    })
  } else if (response.status === 200) {
    notification.success({
      message: 'Email Verified',
      description: 'your email is verified',
    })
    return yield put({
      type: 'user/SET_STATE',
      payload: {
        emailVerifyLoading: false,
        emailVerifyState: 'emailVerified',
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      emailVerifyLoading: false,
    },
  })
}

export function* INVITE_NEW_MEMBER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      inviteModalLoading: true,
    },
  })

  const { role } = yield select(getUser)
  const { data } = payload

  yield call(inviteMember, data, role)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      inviteModalLoading: false,
      inviteModalVisible: false,
    },
  })
}

export function* SHARE_MEMBER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      shareModalLoading: true,
    },
  })

  const { role } = yield select(getUser)
  const { data } = payload
  yield call(shareMember, data, role)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      shareModalLoading: false,
      shareModalVisible: false,
    },
  })
  yield call(GET_MEMBERS, {
    payload: {
      data: {
        vcstatus: true,
      },
    },
  })
}

export function* REVOKE_PROVIDER({ payload }) {
  const { role } = yield select(getUser)
  const { data } = payload

  yield call(revokeProvider, data, role)
  yield call(GET_MEMBERS, {
    payload: {
      data: {
        vcstatus: true,
      },
    },
  })
}

export function* ASSIGN_MEMBER_TO_PROVIDER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      assignModalLoading: true,
    },
  })

  const { role } = yield select(getUser)
  const { data } = payload
  yield call(assignMemberToProvider, data, role)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      assignModalLoading: false,
      assignModalVisible: false,
    },
  })
  yield call(GET_MEMBERS, {
    payload: {
      data: {
        vcstatus: true,
      },
    },
  })
}

export function* GET_LINKED_PROVIDERS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      linkedProvidersModalLoading: true,
    },
  })

  const { role } = yield select(getUser)
  const { data } = payload

  const linkedProviders = yield call(getLinkedProviders, data, role)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      linkedProvidersModalLoading: false,
      linkedProviders,
    },
  })
}

export function* UNLINK_PROVIDER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      linkedProvidersModalLoading: true,
    },
  })

  const { role, linkedProvidersMemberID } = yield select(getUser)
  const { data } = payload
  yield call(unlinkProvider, data, role)

  yield call(GET_LINKED_PROVIDERS, {
    payload: {
      data: {
        member_id: linkedProvidersMemberID,
      },
    },
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      linkedProvidersModalLoading: false,
    },
  })
}

export function LOG_ACTION({ type = '' }) {
  const split = type.split('/')
  const { location } = window
  const { hostname } = location

  if (
    type !== '@@router/LOCATION_CHANGE' &&
    split[1] !== 'SET_STATE' &&
    hostname === 'cns.virtualsleepclinic.com'
  ) {
    ReactGA.event({
      category: split[0],
      action: split[1],
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGETPASSWORD_SEND_CODE, FORGETPASSWORD_SEND_CODE),
    takeEvery(actions.FORGOTPASSWORD_SUBMIT, FORGOTPASSWORD_SUBMIT),
    takeEvery(actions.EMAIL_VERIFY_SEND_CODE, EMAIL_VERIFY_SEND_CODE),
    takeEvery(actions.EMAIL_VERIFY_SUBMIT, EMAIL_VERIFY_SUBMIT),
    takeEvery(actions.INVITE_NEW_MEMBER, INVITE_NEW_MEMBER),
    takeEvery(actions.ASSIGN_MEMBER_TO_PROVIDER, ASSIGN_MEMBER_TO_PROVIDER),
    takeEvery(actions.SHARE_MEMBER, SHARE_MEMBER),
    takeEvery(actions.REVOKE_PROVIDER, REVOKE_PROVIDER),
    takeEvery(actions.GET_LINKED_PROVIDERS, GET_LINKED_PROVIDERS),
    takeEvery(actions.UNLINK_PROVIDER, UNLINK_PROVIDER),
    takeEvery(actions.EDIT_SLEEP_SESSION, EDIT_SLEEP_SESSION),
    takeEvery('*', LOG_ACTION),
    yield call(LOAD_CURRENT_ACCOUNT), // run once on app load to check user auth,
  ])
}
