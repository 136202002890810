import React from 'react'
import { connect } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars'

import { Calendar, Badge, Spin } from 'antd'
import moment from 'moment'
import styles from './style.module.scss'

let calendarDATA = {}
let sleepRecorded = {}

function formatCSIData(data, sleepSessions) {
  data.forEach(element => {
    let colorType = 'error'
    if (element.csi_final >= 80) {
      colorType = 'success'
    } else if (element.csi_final >= 33) {
      colorType = 'warning'
    }
    calendarDATA[element.date] = [
      {
        type: colorType,
        content: Math.round(element.csi_final),
      },
    ]
  })
  if (sleepSessions.length > 0) {
    sleepSessions.forEach(element => {
      sleepRecorded[element.date_of_sleep] = [
        {
          type: 'processing',
          content: '',
        },
      ]
    })
  }
}

function formatDeviceCalendarData(sleepSessions, force = false) {
  sleepRecorded = {}
  if (sleepSessions.length > 0 || force) {
    sleepSessions.forEach(element => {
      sleepRecorded[element.date_of_sleep] = [
        {
          type: 'processing',
          content: '',
          attr: element.device_id,
        },
      ]
    })
  }
}
function getListData(value) {
  value = moment(value).format('YYYY-MM-DD')
  let listData
  if (calendarDATA[value] !== undefined) {
    listData = calendarDATA[value]
  }
  return listData || []
}

function getListDataOfSleepSessions(value) {
  value = moment(value).format('YYYY-MM-DD')
  let listData
  if (sleepRecorded[value] !== undefined) {
    listData = sleepRecorded[value]
  }
  return listData || []
}

function dateCellRender(value) {
  const listData = getListData(value)
  const sleepSessionsListData = getListDataOfSleepSessions(value)

  return (
    <ul className="events">
      {listData.length > 0 && (
        <li
          key={listData[0].content}
          title={`CSI ${listData[0].content}%`}
          style={{ display: 'inline-block' }}
        >
          <Badge status={listData[0].type} text="" />
        </li>
      )}

      {sleepSessionsListData.length > 0 && (
        <li key={sleepSessionsListData[0].content} style={{ display: 'inline-block' }}>
          <Badge status={sleepSessionsListData[0].type} text="" />
        </li>
      )}
    </ul>
  )
}
@connect(({ settings, profile, device, user, clinicPatient }) => ({
  isSettingsOpen: settings.isSettingsOpen,
  deviceDetail: device.deviceDetail,
  deviceSessions: device.deviceSessions,
  patientSessionDates: device.patientSessionDates,
  role: user.role,
  isPatientSelected: settings.isPatientSelected,
  loading: profile.loading,
  nightByNightLoading: profile.nightByNightLoading,
  calendarDate: profile.calendarDate,
  memberDetail: profile.memberDetail,
  memberCSIHistory: profile.memberCSIHistory,
  memberSleepSessions: profile.memberSleepSessions,
  patientSessionsLoading: device.patientSessionsLoading,
  clinicPatient,
}))
class Settings extends React.Component {
  state = {
    value: moment(),
  }

  componentDidUpdate = prevProps => {
    const { value } = this.state
    const {
      pathname,
      loading,
      nightByNightLoading,
      isPatientSelected,
      patientSessionsLoading,
      patientSessionDates,
    } = this.props
    let { calendarDate } = this.props
    const { loading: prevLoading, nightByNightLoading: prevNightByNightLoading } = prevProps
    if (
      !(pathname.includes('device/profile') || pathname.includes('/profile/')) ||
      pathname.includes('/clinic/profile/')
    ) {
      calendarDATA = {}
      sleepRecorded = {}
      return false
    }
    if (
      isPatientSelected !== prevProps.isPatientSelected ||
      patientSessionsLoading !== prevProps.patientSessionsLoading
    ) {
      calendarDATA = {}
      sleepRecorded = {}
      if (patientSessionDates.length > 0) {
        this.setValue(moment(patientSessionDates[0].date_of_sleep))
      } else {
        this.setValue(moment())
      }
      return false
    }
    calendarDate = moment(calendarDate)
    if (nightByNightLoading !== prevNightByNightLoading || prevLoading !== loading) {
      if (calendarDate.format('YYYY-MM-DD') !== value.format('YYYY-MM-DD')) {
        this.setState({
          value: calendarDate,
        })
      }
    }
    return true
  }

  changeSetting = (setting, value) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting,
        value,
      },
    })
  }

  getSleepStagesByDate = date => {
    const { dispatch, deviceDetail, role, pathname, memberDetail, memberSleepSessions } = this.props
    if ((role === 'Clinic' || role === 'Superadmin') && pathname.includes('device/profile')) {
      const data = {
        device_id: deviceDetail[0].device_id,
        date,
      }
      if (role === 'Superadmin') {
        const breakPath = pathname.split('/')

        data.user_type = 'sa'
        /* eslint-disable */
        data.clinic_id = breakPath.reverse()[0]
        /* eslint-enable */
      }
      dispatch({
        type: 'device/GET_NIGHT_BY_NIGHT_DATA',
        payload: data,
      })
    } else if (
      (role === 'Clinic' || role === 'Superadmin') &&
      pathname.includes('patient/profile')
    ) {
      if (memberSleepSessions.length > 0) {
        const currentDevice = memberSleepSessions.filter(val => {
          if (val.date_of_sleep !== date) {
            return false
          }
          return true
        })
        const data = {
          device_id: currentDevice[0].device_id,
          patient_id: memberDetail.mr_no,
          clinic_id: memberDetail.clinic_id,
          date_of_sleep: date,
        }
        dispatch({
          type: 'clinicPatient/GET_NIGHT_BY_NIGHT_DATA',
          payload: data,
        })
      }

      // const data = {
      //   device_id: deviceDetail[0].device_id,
      //   date,
      // }
    } else {
      dispatch({
        type: 'profile/GET_NIGHT_BY_NIGHT_DATA',
        payload: {
          userUID: memberDetail.user_id,
          date,
        },
      })
    }
  }

  disableDate = date => {
    const { isPatientSelected, patientSessionDates } = this.props
    let toBeReturned = true
    if (isPatientSelected) {
      toBeReturned = true
      date = date.format('YYYY-MM-DD')
      Object.keys(patientSessionDates).forEach(key => {
        if (patientSessionDates[key].date_of_sleep === date) {
          toBeReturned = false
        }
      })
    } else {
      date = date.format('YYYY-MM-DD')
      Object.keys(sleepRecorded).forEach(key => {
        if (key === date) {
          toBeReturned = false
        }
      })
    }
    return toBeReturned
  }

  onSelect = value => {
    this.setState({
      value,
    })

    const selectedDate = moment(value).format('YYYY-MM-DD')
    this.closeSettings()

    this.getSleepStagesByDate(selectedDate)
  }

  onPanelChange = value => {
    this.setState({ value })
  }

  closeSettings = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSettingsOpen',
        value: false,
      },
    })
  }

  setValue = value => {
    this.setState({
      value,
    })
  }

  render() {
    const {
      memberCSIHistory,
      memberSleepSessions,
      calendarDate,
      search,
      role,
      deviceSessions,
      pathname,
      isPatientSelected,
      patientSessionDates,
    } = this.props
    if (pathname.includes('device/profile') || pathname.includes('/profile/')) {
      if ((role === 'Clinic' || role === 'Superadmin') && pathname.includes('device/profile')) {
        if (Object.keys(sleepRecorded).length === 0) {
          if (isPatientSelected) {
            formatDeviceCalendarData(patientSessionDates, true)
          } else {
            formatDeviceCalendarData(deviceSessions)
          }
        }
      } else if (
        Object.keys(sleepRecorded).length === 0 ||
        Object.keys(calendarDATA).length === 0
      ) {
        formatCSIData(memberCSIHistory, memberSleepSessions)
      }
    }
    const urlParams = new URLSearchParams(search)
    const source = urlParams.get('source')
    const reportdate = urlParams.get('reportdate')
    let calendarDefaultDate = moment()
    if (source !== undefined && reportdate !== undefined && source === 'DeviceActivity') {
      calendarDefaultDate = moment(reportdate)
    } else {
      calendarDefaultDate = moment(calendarDate)
    }
    const { isSettingsOpen, patientSessionsLoading } = this.props
    const { value } = this.state
    return (
      <div
        className={isSettingsOpen ? `${styles.settings} ${styles.settingsOpened}` : styles.settings}
      >
        <Scrollbars style={{ height: '100vh' }}>
          <div className={styles.container}>
            <div className={styles.title}>
              Calendar
              <button
                className={`${styles.close} fa fa-times`}
                onClick={this.closeSettings}
                type="button"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <b>CSI: </b> &nbsp;
            <Badge status="success" text="Good" /> &nbsp;
            <Badge status="warning" text="Average" /> &nbsp;
            <Badge status="error" text="Bad" />
            <br />
            <b>Sleep Session: </b> &nbsp;
            <Badge status="processing" text="Recorded" /> &nbsp;
          </div>
          <Spin spinning={patientSessionsLoading}>
            <Calendar
              value={value}
              onPanelChange={this.onPanelChange}
              fullscreen={false}
              disabledDate={isPatientSelected ? this.disableDate : false}
              dateCellRender={dateCellRender}
              defaultValue={calendarDefaultDate}
              onSelect={this.onSelect}
              validRange={[moment('20111031'), moment()]}
            />
          </Spin>
        </Scrollbars>
      </div>
    )
  }
}

export default Settings
