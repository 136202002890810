import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import CacheBuster from './cacheBuster'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  //  SuperAdmin Routes
  // System Pages
  {
    path: '/vcAssessment',
    component: loadable(() => import('pages/vcAssessment')),
    exact: true,
  },
  {
    path: '/user/pp',
    component: loadable(() => import('pages/user/PrivacyPolicy')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Custom Application Routes
  {
    path: '/coach/profile/',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  },
  {
    path: '/provider/profile/:slug',
    component: loadable(() => import('pages/team/view')),
    customProps: { isProviderProfile: true },
    exact: true,
  },
  {
    path: '/clinic/profile/:slug',
    component: loadable(() => import('pages/team/view')),
    customProps: { isClinicProfile: true },
    exact: true,
  },
  {
    path: '/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: false,
  },

  //  Device Routes
  {
    path: '/device/',
    component: loadable(() => import('pages/device/manage')),
    exact: true,
  },
  {
    path: '/device/add',
    component: loadable(() => import('pages/device/add')),
    exact: true,
  },

  //  Members Routes
  {
    path: '/users/add',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/users/edit/:slug',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/users',
    component: loadable(() => import('pages/user/manage')),
    exact: true,
  },
  {
    path: '/clinics/add',
    component: loadable(() => import('pages/clinic/add')),
    exact: true,
  },
  {
    path: '/clinics/edit/:slug',
    component: loadable(() => import('pages/clinic/add')),
    exact: true,
  },
  {
    path: '/clinics',
    component: loadable(() => import('pages/clinic/manage')),
    exact: true,
  },
  {
    path: '/invoices',
    component: loadable(() => import('pages/invoice/manage')),
    exact: true,
  },
  {
    path: '/referrals',
    component: loadable(() => import('pages/referrals/manage')),
    exact: true,
  },

  {
    path: '/members/roles',
    component: loadable(() => import('pages/user/roles')),
    exact: true,
  },

  //  Device Inventory Routes
  {
    path: '/inventory',
    component: loadable(() => import('pages/deviceInventory/manage')),
    exact: true,
  },
  {
    path: '/device/:slug',
    component: loadable(() => import('pages/deviceInventory/view')),
    exact: true,
  },
  {
    path: '/device/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/device/profile/:slug/:clinicId',
    component: loadable(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/inventory/add',
    component: loadable(() => import('pages/deviceInventory/add')),
    exact: true,
  },
  {
    path: '/device/edit/:slug',
    component: loadable(() => import('pages/deviceInventory/add')),
    exact: true,
  },

  //  Device Routes

  //  User Routes
  {
    path: '/consumers',
    component: loadable(() => import('pages/consumers/manage')),
    exact: true,
  },

  // Sleep Education Routes

  {
    path: '/sleepTools',
    component: loadable(() => import('pages/sleepEducation')),
    exact: true,
  },

  // Package Routes
  {
    path: '/packages',
    component: loadable(() => import('pages/saPackages/manage')),
    exact: true,
  },
  {
    path: '/weeklyReports/',
    component: loadable(() => import('pages/weeklyReport/manage')),
    exact: true,
  },
  {
    path: '/sleepSessions/',
    component: loadable(() => import('pages/sleepSessions/manage')),
    exact: true,
  },
  {
    path: '/appSessions/',
    component: loadable(() => import('pages/appSessions/manage')),
    exact: true,
  },
  {
    path: '/notifications/',
    component: loadable(() => import('pages/notifications/manage')),
    exact: true,
  },
  //  Device Routes
  {
    path: '/deviceActivity/:slug',
    component: loadable(() => import('pages/deviceInventory/deviceActivityClinic')),
    exact: true,
  },
]

const clinicRoutes = [
  {
    path: '/clinic/profile/',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  },
  {
    path: '/user/pp',
    component: loadable(() => import('pages/user/PrivacyPolicy')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Custom Application Routes
  {
    path: '/clinic/profile/',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  },
  {
    path: '/provider/profile/:slug',
    component: loadable(() => import('pages/team/view')),
    customProps: { isProviderProfile: true },
    exact: true,
  },

  {
    path: '/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: false,
  },

  //  Device Routes
  {
    path: '/deviceActivity/:slug',
    component: loadable(() => import('pages/deviceInventory/deviceActivityClinic')),
    exact: true,
  },
  {
    path: '/device/',
    component: loadable(() => import('pages/device/manage')),
    exact: true,
  },
  {
    path: '/device/add',
    component: loadable(() => import('pages/device/add')),
    exact: true,
  },

  //  Members Routes
  {
    path: '/users/add',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/users/edit/:slug',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/users',
    component: loadable(() => import('pages/user/manage')),
    exact: true,
  },

  //  Device Routes
  {
    path: '/device/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: false,
  },

  //  User Routes
  {
    path: '/consumers',
    component: loadable(() => import('pages/consumers/manage')),
    exact: true,
  },

  // Sleep Education Routes

  {
    path: '/sleepTools',
    component: loadable(() => import('pages/sleepEducation')),
    exact: true,
  },

  {
    path: '/report/',
    component: loadable(() => import('pages/report')),
    exact: true,
  },
  {
    path: '/patient/',
    component: loadable(() => import('pages/clinicPatient/manage/')),
    exact: true,
  },
  {
    path: '/patient/Add/:deviceId',
    component: loadable(() => import('pages/clinicPatient/add')),
    exact: true,
  },
  {
    path: '/patient/Add',
    component: loadable(() => import('pages/clinicPatient/add')),
    exact: true,
  },
  {
    path: '/patient/edit/:slug',
    component: loadable(() => import('pages/clinicPatient/add')),
    exact: true,
  },
  {
    path: '/patient/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: false,
  },
]

const adminRoutes = [
  //  Preloaded Template Routes
  // System Pages
  {
    path: '/vcAssessment',
    component: loadable(() => import('pages/vcAssessment')),
    exact: true,
  },
  {
    path: '/sleepTools',
    component: loadable(() => import('pages/sleepEducation')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Custom Application Routes
  {
    path: '/coach/profile/',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  },

  {
    path: '/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: false,
  },

  //  Event Routes
  {
    path: '/event/',
    component: loadable(() => import('pages/event/manage')),
    exact: true,
  },
  {
    path: '/event/add',
    component: loadable(() => import('pages/event/add')),
    exact: true,
  },

  //  Device Routes
  {
    path: '/device/',
    component: loadable(() => import('pages/device/manage')),
    exact: true,
  },
  {
    path: '/device/add',
    component: loadable(() => import('pages/device/add')),
    exact: true,
  },

  //  Members Routes
  {
    path: '/users/add',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/users/edit/:slug',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/users',
    component: loadable(() => import('pages/user/manage')),
    exact: true,
  },
  {
    path: '/sharedMembers',
    component: loadable(() => import('pages/user/manage')),
    exact: true,
    customProps: {
      memberType: 'shared',
      memberView: 'Shared Member',
      addLink: 'sharedMember',
      disableFilter: true,
      disableCreate: true,
    },
  },

  //  Device Inventory Routes
  {
    path: '/inventory',
    component: loadable(() => import('pages/deviceInventory/manage')),
    exact: true,
  },
  {
    path: '/device/:slug',
    component: loadable(() => import('pages/deviceInventory/view')),
    exact: true,
  },
  {
    path: '/inventory/add',
    component: loadable(() => import('pages/deviceInventory/add')),
    exact: true,
  },

  //  Device Routes

  {
    path: '/packages',
    component: loadable(() => import('pages/packages/manage')),
    exact: true,
  },
  {
    path: '/packages/add',
    component: loadable(() => import('pages/saPackages/add')),
    exact: true,
  },
]

const memberRoute = [
  {
    path: '*',
    component: loadable(() => import('pages/notAllowed')),
    exact: true,
  },
]
@connect(({ user }) => ({ user }))
class Router extends React.Component {
  getRoutes = role => {
    if (role === 'Superadmin') {
      return routes
    }
    if (role === 'Provider') {
      return adminRoutes
    }
    if (role === 'Clinic') {
      return clinicRoutes
    }
    if (role === 'Member') {
      return memberRoute
    }

    return routes
  }

  render() {
    const { history } = this.props
    const { user } = this.props
    const { role } = user
    const RoutesList = this.getRoutes(role)

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload()
          }

          return (
            <ConnectedRouter history={history}>
              <IndexLayout>
                <Switch>
                  {role === 'Clinic' ? (
                    <Route
                      exact
                      path="/"
                      key="defaultRoute"
                      render={() => <Redirect to="/clinic/profile" />}
                    />
                  ) : (
                    <Route
                      exact
                      path="/"
                      key="defaultRoute"
                      render={() => <Redirect to="/coach/profile" />}
                    />
                  )}

                  {RoutesList.map(route => (
                    <Route
                      path={route.path}
                      render={props => <route.component {...route.customProps} {...props} />}
                      key={route.path}
                      customProps={route.customProps}
                      exact={route.exact}
                    />
                  ))}
                  <Route
                    render={props => <NotFoundPage {...props} type="Clinic" />}
                    path="/clinic/NotFound"
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              </IndexLayout>
            </ConnectedRouter>
          )
        }}
      </CacheBuster>
    )
  }
}

export default Router
