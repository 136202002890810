import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import actions, { getInvoices } from './actions'

const getUser = state => state.user

// Function to get A List of Invoices from Server and Save it in Redux Store
export function* GET_INVOICES() {
  yield put({
    type: 'invoice/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { role } = yield select(getUser)
  const invoiceList = yield call(getInvoices, role)
  yield put({
    type: 'invoice/SET_STATE',
    payload: {
      loading: false,
      invoiceList,
    },
  })
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([takeEvery(actions.GET_INVOICES, GET_INVOICES)])
}
