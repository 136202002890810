import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import member from './member/sagas'
import profile from './profile/sagas'
import device from './device/sagas'
import packages from './package/sagas'
import invoice from './invoice/sagas'
import referral from './referral/sagas'
import clinic from './clinic/sagas'
import sleepSession from './sleepSession/sagas'
import notifications from './notifications/sagas'
import sockets from './socket/sagas'
import clinicPatient from './clinicPatient/sagas'

export default function* rootSaga() {
  yield all([
    menu(),
    device(),
    user(),
    settings(),
    member(),
    profile(),
    packages(),
    invoice(),
    referral(),
    clinic(),
    sleepSession(),
    notifications(),
    sockets(),
    clinicPatient(),
  ])
}
