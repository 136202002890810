import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import actions, {
  getPackages,
  addPackage,
  updatePackage,
  deletePackage,
  getAllPackages,
  disablePackage,
} from './actions'

const getUser = state => state.user

export function* GET_PACKAGES({ payload }) {
  const { status, admin_id: providerID } = payload
  yield put({
    type: 'packages/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { role, details } = yield select(getUser)
  let { admin_id: adminID } = details
  if (role === 'Superadmin') {
    adminID = providerID
  }
  const packageResponse = yield call(getPackages, role, adminID, status)
  const { pkgs: packages } = packageResponse
  let { devices: packageDevices } = packageResponse
  if (role === 'Provider') {
    packageDevices = []
  }

  yield put({
    type: 'packages/SET_STATE',
    payload: {
      loading: false,
      packages,
      packageDevices,
    },
  })
}
export function* GET_ALL_PACKAGES({ payload }) {
  const { status, admin_id: providerID } = payload
  yield put({
    type: 'packages/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'packages/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { role } = yield select(getUser)
  const packageResponse = yield call(getAllPackages, role, providerID, status)
  const { pkgs: allPackages, devices: allPackageDevices } = packageResponse

  yield put({
    type: 'packages/SET_STATE',
    payload: {
      loading: false,
      allPackages,
      allPackageDevices,
    },
  })
}

export function* ADD_PACKAGE({ payload }) {
  const { data } = payload
  const { role } = yield select(getUser)

  yield put({
    type: 'packages/SET_STATE',
    payload: {
      packageModalLoading: true,
    },
  })
  yield call(addPackage, data, role)

  yield put({
    type: 'packages/SET_STATE',
    payload: {
      packageModalLoading: false,
      packageModalVisible: false,
    },
  })
  yield put({
    type: 'device/GET_INVENTORY_DEVICES',
    payload: {},
  })
}

export function* UPDATE_PACKAGE({ payload }) {
  const { data } = payload
  const { role } = yield select(getUser)

  yield put({
    type: 'packages/SET_STATE',
    payload: {
      packageModalLoading: true,
    },
  })
  yield call(updatePackage, data, role)
  yield put({
    type: 'packages/SET_STATE',
    payload: {
      packageModalLoading: false,
      packageModalVisible: false,
    },
  })
  yield call(GET_PACKAGES, { payload: {} })
}

export function* DELETE_PACKAGE({ payload }) {
  const { data } = payload
  const { role } = yield select(getUser)

  yield call(deletePackage, data, role)

  yield call(GET_PACKAGES)
}

export function* DISABLE_PACKAGE({ payload }) {
  const { data } = payload
  const { role } = yield select(getUser)
  yield call(disablePackage, data, role)

  yield call(GET_PACKAGES, {
    payload: {},
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PACKAGES, GET_PACKAGES),
    takeEvery(actions.ADD_PACKAGE, ADD_PACKAGE),
    takeEvery(actions.UPDATE_PACKAGE, UPDATE_PACKAGE),
    takeEvery(actions.GET_ALL_PACKAGES, GET_ALL_PACKAGES),
    takeEvery(actions.DISABLE_PACKAGE, DISABLE_PACKAGE),
  ])
}
