import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default class Index extends PureComponent {
  render() {
    const { location } = window
    const { hostname } = location
    let hostNameArray = hostname

    hostNameArray = hostNameArray.split('.')
    const [hostNameString] = hostNameArray

    const { type } = this.props
    let text = 'The page is deprecated, deleted, or does not exist at all'
    let showButton = true
    if (type === 'Clinic') {
      text =
        hostNameString !== '404'
          ? `The Clinic "${hostNameString}" does not exist.`
          : `The Clinic does not exist.`
      showButton = false
    }
    return (
      <div
        style={{
          minHeight: 'calc(100vh - 500px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Helmet title={`${!type ? '' : type} Not Found`} />
        <div
          style={{
            maxWidth: '560px',
            backgroundColor: '#fff',
            padding: '80px 30px',
            margin: '100px auto',
            borderRadius: '10px',
            flex: '1',
          }}
        >
          <div
            style={{
              maxWidth: '400px',
              margin: '0 auto',
            }}
          >
            <h1 className="font-size-36 mb-2">{!type ? 'Page' : type} not found</h1>
            <p className="mb-3">{text}</p>
            <h1 className="font-size-80 mb-4 font-weight-bold">404 —</h1>
            {showButton && (
              <Link to="/" className="btn">
                &larr; Go back to the home page
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}
