import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { Table } from 'antd'
import styles from './style.module.scss'
import { table1p6, table1p10 } from './data.json'

class PrivacyPolicy extends Component {
  componentDidMount() {
    const { location } = window
    const { hostname } = location
    if (hostname === 'cns.virtualsleepclinic.com') {
      ReactGA.pageview('/privacyPolicy')
    }
  }

  render() {
    const tableOneColumns = [
      {
        title: 'Data Collected',
        dataIndex: 'dataCollected',
        key: 'dataCollected',
      },
      {
        title: 'How We Collect the Data',
        dataIndex: 'howWeCollectData',
        key: 'howWeCollectData',
      },
      {
        title: 'Lawful Basis',
        dataIndex: 'lawfulBasis',
        key: 'lawfulBasis',
      },
    ]

    const tableTwoColumns = [
      {
        title: 'Recipient',
        dataIndex: 'recipient',
        key: 'recipient',
      },
      {
        title: 'Activity Carried Out',
        dataIndex: 'activityCarriedOut',
        key: 'activityCarriedOut',
      },
      {
        title: 'Sector',
        dataIndex: 'sector',
        key: 'sector',
      },
      {
        title: 'Location*',
        dataIndex: 'location',
        key: 'location',
      },
    ]
    return (
      <div style={{ height: '100%' }}>
        <Helmet title="Privacy Policy" />
        <div className={styles.title} style={{ color: 'white' }}>
          <br />
          <br />
          <br />
          <img src="resources/images/circadia.png" alt="" style={{ width: '45%' }} />
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className={styles.block}>
          <div className="row">
            <div className="col-xl-12">
              <div className={styles.inner}>
                <h1>Privacy & Cookies Policy</h1>
                <h2>BACKGROUND:</h2>
                <p>
                  Circadia Technologies Ltd understands that your privacy is important to you and
                  that you care about how your personal data is used. We respect and value the
                  privacy of everyone who uses our services and provides personal data through
                  either (a) our Mobile App:{' '}
                  <a
                    href="http://bit.ly/CircadiaTrackiOS"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    iOS
                  </a>
                  /
                  <a
                    href="http://bit.ly/CircadiaTrackAndroid"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Android
                  </a>{' '}
                  (“Mobile App”), (b) our website{' '}
                  <a href="https://myinsomniatest.com" rel="noopener noreferrer" target="_blank">
                    myinsomniatest.com
                  </a>{' '}
                  (“Website”) or (c) the sleep tracker device that has to be used in conjunction
                  with the Mobile App (“Sleep Device”) and will only collect and use personal data
                  in ways that are described here, and in a way that is consistent with our
                  obligations and your rights under the law.
                </p>
                <p>
                  Please read this Privacy & Cookies Policy carefully and ensure that you understand
                  it. Your acceptance of the Cookies part of this Privacy & Cookies Policy will
                  occur when you tick the consent box on Our Mobile App or Our Website and you
                  acceptance of the privacy part of this Privacy & Cookies Policy is deemed to occur
                  upon your first use of Our Mobile App, Website or Sleep Device <b>AND/OR</b> You
                  will be required to provide express consent by reading and accepting this Privacy
                  & Cookies Policy when signing up for an Account or Subscription (if we offer one).
                  If you do not accept and agree with this Privacy & Cookies Policy, you must stop
                  using Our Mobile App, Website and Sleep Tracker immediately.
                </p>
                <dl>
                  <section id="pp_p1">
                    <dt>1. Definitions and Interpretation </dt>
                    <dd>In this Policy the following terms shall have the following meanings:</dd>
                    <ul>
                      <dt>Account</dt>
                      <dd>
                        means an account required to access and/or use certain areas and features of
                        Our Mobile App or Website;
                      </dd>
                      <dt>Cookie</dt>
                      <dd>
                        means a small text file placed on your computer or device by Our Mobile App
                        or Website when you visit certain parts of Our Mobile App or Website and/or
                        when you use certain features of Our Mobile App or Website. Details of the
                        Cookies used by Site are set out in Part 14 below;
                      </dd>
                      <dt>Cookie Law</dt>
                      <dd>
                        means the relevant parts of the Privacy and Electronic Communications (EC
                        Directive) Regulations 2003 as amended by the Privacy and Electronic
                        Communications (EC Directive) (Amendment) Regulations 2011;
                      </dd>
                      <dt>Raw Data</dt>
                      <dd>
                        means data collected that is personal data as defined under the Data
                        Protection Legislation (as defined below) which comprises identifiers to one
                        or more factors specific to you physical, physiological, genetic, mental,
                        economic, cultural or social identity where such identifiers are provided by
                        you via Our Mobile App or Website or collected by technical sensors or
                        otherwise through Our Sleep Tracker and Mobile App, including but not
                        limited to height, weight, respiration data, sleep data, environmental data
                        and motion data; and
                      </dd>
                      <dt>Third Party Providers</dt>
                      <dd>
                        means any third party provider(s) that we may elect to work with to extend
                        the use and benefits you derive from Our Mobile App, Sleep Tracker or
                        Website, including but not limited to sleep coaches, wellness coaches,
                        alternative medicine therapists, sports coaches, service companies in the
                        sleep, health and wellness industry.
                      </dd>
                    </ul>
                  </section>
                  <section id="pp_p2">
                    <dt>2. Information About Us</dt>
                    <dd>
                      Circadia Technologies Ltd is a Limited company registered in England under
                      company number [09270518]
                    </dd>
                    <dd>Registered address: 6 Delmey Close, Croydon, United Kingdom, CR0 5QD</dd>
                    <dd>VAT number: </dd>
                    <dd>Data Protection Officer & Representative: Stefan Karolcik</dd>
                    <dd>
                      <ul>
                        <dd>
                          <b>Email address:</b> support@circadiatech.com{' '}
                        </dd>
                        <dd>
                          <b>Telephone number:</b> 02033055585 Postal
                        </dd>
                        <dd>
                          <b>Address:</b> 207 Lavender Hill, SW11 5SD, London, United Kingdom
                        </dd>
                      </ul>
                    </dd>
                  </section>
                  <section id="pp_p3">
                    <dt>3. What Does This Policy Cover?</dt>
                    <dd>
                      This Privacy & Cookies Policy applies only to your use of Our Mobile App, Our
                      Website and/or Our Sleep Tracker. Our Mobile App or Our Website may contain
                      links to other websites. Please note that we have no control over how your
                      data is collected, stored, or used by other websites and we advise you to
                      check the privacy policies of any such websites before providing any data to
                      them.
                    </dd>
                  </section>
                  <section id="pp_p4">
                    <dt>4. What Is Personal Data?</dt>
                    <dd>
                      Personal data is defined by the General Data Protection Regulation (EU
                      Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018
                      (collectively, “the Data Protection Legislation”) as ‘any information relating
                      to an identifiable person who can be directly or indirectly identified in
                      particular by reference to an identifier’. Personal data is, in simpler terms,
                      any information about you that enables you to be identified. Personal data
                      covers obvious information such as your name and contact details, but it also
                      covers less obvious information such as identification numbers, electronic
                      location data, and other online identifiers.
                    </dd>
                  </section>
                  <section id="pp_p5">
                    <dt>5. What Are My Rights?</dt>
                    <dd>
                      Under the Data Protection Legislation, you have the following rights, which we
                      will always work to uphold:
                      <ul>
                        <li>
                          The right to be informed about our collection and use of your personal
                          data. This Privacy & Cookies Policy should tell you everything you need to
                          know, but you can always contact us to find out more or to ask any
                          questions using the details in Part 15. The ICO has more guidance on their
                          website,{' '}
                          <a
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-be-informed/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            [here]
                          </a>
                        </li>
                        <li>
                          The right to access the personal data we hold about you. Part 13 will tell
                          you how to do this. The ICO has more guidance on their website,
                          <a
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-be-informed/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            [here]
                          </a>
                        </li>
                        <li>
                          The right to have your personal data rectified if any of your personal
                          data held by us is inaccurate or incomplete. Please contact us using the
                          details in Part 15 to find out more. The ICO has more guidance on their
                          website,
                          <a
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-be-informed/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            [here]
                          </a>
                        </li>
                        <li>
                          The right to ask us to delete or otherwise dispose of any of your personal
                          data that we hold. Please contact us using the details in Part 15 to find
                          out more. The ICO has more guidance on their website, [here]
                        </li>
                        <li>
                          The right to restrict (i.e. prevent) the processing of your personal data.
                          Please contact us using the details in Part 15 to find out more. The ICO
                          has more guidance on their website,
                          <a
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-be-informed/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            [here]
                          </a>
                        </li>
                        <li>
                          The right to object to us using your personal data for a particular
                          purpose or purposes. Please contact us using the details in Part 15 to
                          find out more. The ICO has more guidance on their website,
                          <a
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-be-informed/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            [here]
                          </a>
                        </li>
                        <li>
                          The right to withdraw consent. This means that, if we are relying on your
                          consent as the legal basis for using your personal data, you are free to
                          withdraw that consent at any time. Please contact us using the details in
                          Part 15 to find out more.
                        </li>
                        <li>
                          The right to data portability. This means that, if you have provided
                          personal data to us directly, we are using it with your consent or for the
                          performance of a contract, and that data is processed using automated
                          means, you can ask us for a copy of that personal data to re-use with
                          another service or business in many cases. Please contact us using the
                          details in Part 15 to find out more.
                        </li>
                        <li>
                          Rights relating to automated decision-making and profiling. We do not use
                          your personal data in this way.
                        </li>
                      </ul>
                      <p>
                        It is important that your personal data is kept accurate and up-to-date. If
                        any of the personal data we hold about you changes, please keep us informed
                        as long as we have that data.
                      </p>
                      <p>
                        Further information about your rights can also be obtained from the
                        Information Commissioner’s Office or your local Citizens Advice Bureau.
                      </p>
                      <p>
                        If you have any cause for complaint about our use of your personal data, you
                        have the right to lodge a complaint with the Information Commissioner’s
                        Office. We would welcome the opportunity to resolve your concerns ourselves
                        however, so please contact us first, using the details in Part 15.
                      </p>
                    </dd>
                  </section>
                  <section id="pp_p6">
                    <dt>6. What Data Do You Collect and How?</dt>
                    <dd>
                      Depending upon your use of Our Mobile App, Our Website and/or the Sleep
                      Tracker, we may collect and hold some or all of the personal and non-personal
                      data set out in the table below, using the methods also set out in the table.
                      Please also see Part 14 for more information about our use of Cookies and
                      similar technologies. We do not collect any ‘special category’ or ‘sensitive’
                      personal data but please refer to Part 16 on this.
                    </dd>
                    <div className="row">
                      <div className="col-md-12">
                        <Table
                          rowKey={record => record.key}
                          dataSource={table1p6}
                          size="small"
                          columns={tableOneColumns}
                          scroll={{ x: '100%' }}
                          pagination={false}
                        />
                      </div>
                    </div>
                    <br />
                  </section>
                  <section id="pp_p7">
                    <dt>7. How Do You Use My Personal Data?</dt>
                    <dd>
                      Under the Data Protection Legislation, we must always have a lawful basis for
                      using personal data which comprises consent, contract, legal obligation and
                      legitimate interests. More details on each lawful basis and examples of how we
                      use your personal data under each basis are as follows:
                      <ul>
                        <dt>Consent</dt>
                        <dd>
                          Where you have expressly provided your consent to receive communications
                          from us such as newsletters, emails, marketing materials, news and events,
                          new services, new products and exclusive events (if appropriate).
                          Consenting to the use of cookies on Our Mobile App or Website is used to
                          make your experience on Our Mobile App or Website more seamless by, for
                          example but not limited to, remembering pages you have previously visited,
                          products you have clicked on, automatically generated forms.
                        </dd>
                        <dt>Contract</dt>
                        <dd>
                          Your personal data is necessary for us to administer the functionality of
                          your Account or Subscription (if appropriate)and contractual relationship
                          with you so that we may communicate with you about subscription orders,
                          your sleep tracker device, consultations, after sales service, set up and
                          manage accounts, complete purchases or payments, store and record your
                          purchases and previous orders made via Our Mobile App or Our Website and
                          keeping you updated with any changes in our terms and policies as well as
                          offering you additional products, services and benefits as a valued
                          customer of ours.
                        </dd>
                        <dt>Legitimate Interest</dt>
                        <dd>
                          Where you have provided your email address or other personal data to us,
                          either online or offline, we may add you to our database for emails to
                          contact you about new products, news and events, exclusive services.
                          Certain personal data is also needed for legitimate purposes to prevent
                          fraud, security breaches, legal violations, for us to help recommend
                          products and services to you and to understand more about our customer and
                          targeted customer base as well as to help the Mobile App and Our Website
                          to switch to the correct currency, language and delivery options if
                          applicable. We may also use the personal data collected under this lawful
                          basis to help us analyse and administer, improve and update Our Mobile
                          App, Sleep Tracker or Website for better experience, better products and
                          service we offer.
                        </dd>
                        <dt>Legal Obligation</dt>
                        <dd>
                          Certain personal data you provide us is necessary for us to fulfil our
                          legal obligations as a business for example, verifying parental
                          responsibility, your age and appropriate use of Our Mobile App, Sleep
                          Tracker or Website, preventing fraud, security breaches and complying with
                          our tax and accounting obligations where needed.
                        </dd>
                        <dt>Personal data relating to children</dt>
                        <dd>
                          Our Mobile App, Sleep Tracker or Website is not intended for use by anyone
                          under the age of 18 but you may be required to provide certain personal
                          data for your children in order to use and benefit from the services
                          offered through our Mobile App, Sleep Tracker or Website. We will only
                          ever collect personal data of children where we have a lawful basis which
                          may be consent, contract, legitimate interest or legal obligation. Consent
                          for the use of personal data of children under the age of 13 has to be
                          provided by an adult who has parental responsibility for that
                          child/children. We reserve the right to ask you for evidence that you have
                          parental responsibility. Any personal data relating to your children will
                          be afforded the same rights as your personal data as set out in Part 5.
                          Furthermore, We do not conduct any automatic user profiling with
                          children’s personal data and Our Mobile App, Website and Sleep Trackers
                          are set up to have age appropriate safeguards in place. Personal data of
                          your children may be shared as set out in Part 10. Please contact us using
                          the details in Part 15 for further information.
                        </dd>
                        <dd>
                          You will not be sent any unlawful marketing or spam. We will always work
                          to fully protect your rights and comply with our obligations under the
                          Data Protection Legislation and the Privacy and Electronic Communications
                          (EC Directive) Regulations 2003, and you will always have the opportunity
                          to opt-out. See Part 11 for more details.
                        </dd>
                        <dd>
                          Third Parties whose content appears on Our Mobile App or Website may use
                          third-party Cookies, as detailed below in Part 14. Please refer to Part 14
                          for more information on controlling cookies. Please note that we do not
                          control the activities of such third parties, nor the data that they
                          collect and use themselves, and we advise you to check the privacy
                          policies of any such third parties.
                        </dd>
                        <dd>
                          We will only use your personal data for the purpose(s) for which it was
                          originally collected unless we reasonably believe that another purpose is
                          compatible with that or those original purpose(s) and need to use your
                          personal data for that purpose. If we do use your personal data in this
                          way and you wish us to explain how the new purpose is compatible with the
                          original, please contact us using the details in Part 15.
                        </dd>
                        <dd>
                          If we need to use your personal data for a purpose that is unrelated to,
                          or incompatible with, the purpose(s) for which it was originally
                          collected, we will inform you and explain the legal basis which allows us
                          to do so.
                        </dd>
                        <dd>
                          In some circumstances, where permitted or required by law, we may process
                          your personal data without your knowledge or consent. This will only be
                          done within the bounds of the Data Protection Legislation and your legal
                          rights.
                        </dd>
                      </ul>
                    </dd>
                  </section>
                  <section id="pp_p8">
                    <dt>8. How Long Will You Keep My Personal Data?</dt>
                    <dd>
                      We will not keep your personal data for any longer than is necessary in light
                      of the reason(s) for which it was first collected. Even if we delete your
                      personal data, it may still exist on backup or archival media for legal, tax
                      or regulatory purposes.
                    </dd>
                  </section>
                  <section id="pp_p9">
                    <dt>9. How and Where Do You Store or Transfer My Personal Data?</dt>
                    <dd>
                      We will store or transfer some of your personal data within the United Kingdom
                      and the European Economic Area (the “EEA”). The EEA consists of all EU member
                      states, plus Norway, Iceland, and Liechtenstein. This means that your personal
                      data will be fully protected under the Data Protection Legislation, GDPR,
                      and/or to equivalent standards by law.
                    </dd>
                    <dd>
                      We may store or transfer some or all of your personal data in countries that
                      are not part of the United Kingdom or the EEA. These are known as “third
                      countries” and may not have data protection laws that are as strong as those
                      in the UK and/or the EEA. This means that we will take additional steps in
                      order to ensure that your personal data is treated just as safely and securely
                      as it would be within the UK and under the Data Protection Legislation as
                      follows:
                    </dd>
                    <dd>
                      We may use specific contracts with external third parties that are approved by
                      the European Commission for the transfer of personal data to third countries
                      These contracts require the same levels of personal data protection that would
                      apply under the Data Protection Legislation. More information is available
                      from the{' '}
                      <a
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        European Commission
                        (https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_en).
                      </a>
                    </dd>
                    <dd>
                      Please contact us using the details below in Part 15 for further information
                      about the particular data protection mechanisms used by us when transferring
                      your personal data to a third country.The security of your personal data is
                      essential to us and to protect your data, we take a number of important
                      technical and organisational measures, including the following:
                      <ul>
                        <li>
                          limiting access to your personal data to those employees, agents,
                          contractors, and other third parties with a legitimate need to know and
                          ensuring that they are subject to duties of confidentiality and aware of
                          any obligations relating to the use of your personal data by them; and
                        </li>
                        <li>
                          procedures for dealing with data breaches (the accidental or unlawful
                          destruction, loss, alteration, unauthorised disclosure of, or access to,
                          your personal data) including notifying you and/or the Information
                          Commissioner’s Office where we are legally required to do so.
                        </li>
                      </ul>
                    </dd>
                  </section>
                  <section id="pp_p10">
                    <dt>10. Do You Share My Personal Data?</dt>
                    <ul>
                      <dd>
                        <b>Subject to part 9,</b> We will not share any of your personal data with
                        any third parties for any purposes, subject to the following exception
                        <b>[s]</b>.
                      </dd>
                    </ul>
                    <dd>
                      If we sell, transfer, or merge parts of our business or assets, your personal
                      data may be transferred to a third party. We may be legally required to share
                      certain personal data, which might include yours, if we are involved in legal
                      proceedings or complying with legal obligations, a court order, or the
                      instructions of a government authority.
                      <b>
                        We may share your personal data with our employees, agents, professional
                        advisers to ensure the Mobile App, Sleep Tracker and Website work as they
                        should for you. We may also need to share your personal data to relevant
                        authorities for example to facilitate the detection of crime or collection
                        of taxes or duties.
                      </b>
                    </dd>
                    <ul>
                      <dd>
                        We may sometimes contract with the following third parties to supply certain
                        services and as such your personal data and/or Raw Data may be shared with
                        them as outlined below.
                      </dd>
                    </ul>
                    <div className="row">
                      <div className="col-md-12">
                        <Table
                          rowKey={record => record.key}
                          dataSource={table1p10}
                          size="small"
                          columns={tableTwoColumns}
                          scroll={{ x: '100%' }}
                          pagination={false}
                        />
                      </div>
                    </div>
                    <br />
                    <dt>*Location</dt>
                    <dd>
                      Personal data collected by Us may be stored and processed in your region, in
                      the United States, or in any other country where Circadia or its affiliates,
                      subsidiaries or service providers maintain facilities. Typically, the primary
                      storage location is in the customer’s region or in the United States, often
                      with a backup to a data centre in another region. The storage location(s) are
                      chosen to operate efficiently, to improve performance, and to create
                      redundancies to protect the data in the event of an outage or other problem.
                      All data stored outside of the United Kingdom or the EEA will be processed in
                      accordance with Part 9 of this Privacy & Cookies Policy.
                    </dd>
                    <dt>Research</dt>
                    <dd>
                      We may use your Raw Data and other personal data on an anonymised basis,
                      aggregate it with anonymised Raw Data and other personal data from other users
                      of Our Mobile App, Sleep Tracker or Website to formulate analysis, theories
                      and papers for distribution internally with our own research and development
                      department or externally with research bodies, Third Party Providers or
                      otherwise. When you create your Account you will be given an opportunity to
                      consent to the use of your personal and Raw data for these purposes. If you
                      have previously consented but later would prefer us not to use your personal
                      data in this way, please let Us know by contacting us as described in Part 15.
                      If any of your personal data is shared with a third party, as described above,
                      we will take steps to ensure that your personal data is handled safely,
                      securely, and in accordance with your rights, our obligations, and the third
                      party’s obligations under the law, as described above in Part 9.
                    </dd>
                  </section>
                  <section id="pp_p11">
                    <dt>11. How Can I Control My Personal Data?</dt>
                    <ol>
                      <li>
                        In addition to your rights under the Data Protection Legislation, set out in
                        Part 5, when you submit personal data via Our Mobile App, Sleep Tracker or
                        Website, we aim to give you strong controls on our use of your data for
                        direct marketing purposes (including the ability to opt-out of receiving
                        emails from us which you may do by unsubscribing using the links provided in
                        our emails and at the point of providing your details and by managing your
                        Account and opting out of your personal data being used for research
                        purposes).
                      </li>
                      <li>
                        If you are based in the United Kingdom, you may also wish to sign up to one
                        or more of the preference services operating in the UK: The Telephone
                        Preference Service (“the TPS”), the Corporate Telephone Preference Service
                        (“the CTPS”), and the Mailing Preference Service (“the MPS”).These may help
                        to prevent you receiving unsolicited marketing. Please note, however, that
                        these services will not prevent you from receiving marketing communications
                        that you have consented to receiving.
                      </li>
                    </ol>
                  </section>
                  <section id="pp_p12">
                    <dt>12. Can I Withhold Information?</dt>
                    <dd>
                      You may access certain areas of Our Mobile App or Website without providing
                      any personal data at all. However, to use all features and functions available
                      on Our Mobile App and Sleep Tracker you may be required to submit or allow for
                      the collection of certain data.
                    </dd>
                    <dd>You may restrict our use of Cookies. For more information, see Part 14.</dd>
                  </section>
                  <section id="pp_p13">
                    <dt>13. How Can I Access My Personal Data?</dt>
                    <dd>
                      If you want to know what personal data we have about you, you can ask us for
                      details of that personal data and for a copy of it (where any such personal
                      data is held). This is known as a “subject access request”.
                    </dd>
                    <dd>
                      All subject access requests should be made in writing and sent to the email or
                      postal addresses shown in Part 15.
                    </dd>
                    <dd>
                      There is not normally any charge for a subject access request. If your request
                      is ‘manifestly unfounded or excessive’ (for example, if you make repetitive
                      requests) a fee may be charged to cover our administrative costs in
                      responding.
                    </dd>
                    <dd>
                      We will respond to your subject access request within 14 calendar days and, in
                      any case, not more than one month of receiving it. Normally, we aim to provide
                      a complete response, including a copy of your personal data within that time.
                      In some cases, however, particularly if your request is more complex, more
                      time may be required up to a maximum of three months from the date we receive
                      your request. You will be kept fully informed of our progress.
                    </dd>
                  </section>
                  <section id="pp_p14">
                    <dt>14. How Do You Use Cookies?</dt>
                    <dd>
                      Our Mobile App or Website may place and access certain first-party Cookies on
                      your computer or device. First-party Cookies are those placed directly by us
                      and are used only by us. We use Cookies to facilitate and improve your
                      experience of Our Mobile App or Website and to provide and improve our
                      products <b>and</b> services. We have carefully chosen these Cookies and have
                      taken steps to ensure that your privacy and personal data is protected and
                      respected at all times.
                    </dd>
                    <dd>
                      By using Our Mobile App or Website, you may also receive certain third-party
                      Cookies on your computer or device. Third-party Cookies are those placed by
                      websites, services, and/or parties other than us. Third-party Cookies are used
                      on Our Mobile App or Website for analytics and SEO such as Google Analytics
                      and Google Tag Manager. Please contact us using the details in Part 15 if you
                      would like to know which analytics services we are using from time to time.
                      These Cookies are not integral to the functioning of Our Mobile App or Website
                      and your use and experience of Our Mobile App or Website will not be impaired
                      by refusing consent to them.
                    </dd>
                    <dd>
                      All Cookies used by and on Our Mobile App or Website are used in accordance
                      with current Cookie Law.
                    </dd>
                    <dd>
                      Before Cookies are placed on your computer or device, you will be shown a pop
                      up screen requesting your consent to set those Cookies. By giving your consent
                      to the placing of Cookies you are enabling us to provide the best possible
                      experience and service to you. You may, if you wish, deny consent to the
                      placing of Cookies; however certain features of Our Mobile App or Website may
                      not function fully or as intended. You will be given the opportunity to allow
                      only first-party Cookies and block third-party Cookies. Certain features of
                      Our Mobile App or Website depend on Cookies to function. Cookie Law deems
                      these Cookies to be “strictly necessary”. Your consent will not be sought to
                      place these Cookies, but it is still important that you are aware of them. You
                      may still block these Cookies by changing your internet browser’s settings as
                      detailed below, but please be aware that Our Mobile App, Sleep Tracker or
                      Website may not work properly if you do so. We have taken great care to ensure
                      that your privacy is not at risk by allowing them.
                    </dd>
                    <dd>
                      Our Mobile App or Website may use analytics services from time to time.
                      Website analytics refers to a set of tools used to collect and analyse
                      anonymous usage information, enabling us to better understand how Our Mobile
                      App or Website is used. This, in turn, enables <b>us</b> to improve Our Mobile
                      App, Sleep Tracker or Website and the products <b>and</b> services offered
                      through it.
                    </dd>
                    <dd>
                      The analytics service(s) used by Our Mobile App or Website use(s) Cookies to
                      gather the required information. You do not have to allow us to use these
                      Cookies, however whilst our use of them does not pose any risk to your privacy
                      or your safe use of Our Mobile App or Website, it does enable us to
                      continually improve Our Mobile App or Website, making it a better and more
                      useful experience for you.
                    </dd>
                    <dd>
                      You may contact us for more details on strictly necessary and third party
                      Cookies we use.
                    </dd>
                    <dd>
                      In addition to the controls that we provide, you can choose to enable or
                      disable Cookies in your internet browser. Most internet browsers also enable
                      you to choose whether you wish to disable all Cookies or only third-party
                      Cookies. By default, most internet browsers accept Cookies, but this can be
                      changed. For further details, please consult the help menu in your internet
                      browser or the documentation that came with your device.
                    </dd>
                    <dd>
                      You can choose to delete Cookies on your computer or device at any time,
                      however you may lose any information that enables you to access Our Mobile App
                      or Website more quickly and efficiently including, but not limited to, login
                      and personalisation settings.
                    </dd>
                    <dd>
                      It is recommended that you keep your internet browser and operating system
                      up-to-date and that you consult the help and guidance provided by the
                      developer of your internet browser and manufacturer of your computer or device
                      if you are unsure about adjusting your privacy settings.
                    </dd>
                  </section>
                  <section id="pp_p15">
                    <dt>15. How Do I Contact You?</dt>
                    <dd>
                      To contact us about anything to do with your personal data and data
                      protection, including to make a subject access request, please use the
                      following details (for the attention of Stefan Karolcik):
                    </dd>
                    <ul>
                      <dd>
                        Email address:{' '}
                        <a href="mailto:support@circadiatech.com">support@circadiatech.com</a>
                      </dd>
                      <dd>Telephone number: 02033055585</dd>
                      <dd>Postal address: 207 Lavender Hill, SW11 5SD, London, United Kingdom</dd>
                    </ul>
                  </section>
                  <section id="pp_p16">
                    <dt>16. General</dt>
                    <dd>
                      You may not transfer any of your rights under this Privacy & Cookies Policy to
                      any other person. Subject to terms and conditions, anyone under the age of 18
                      should not use Our Mobile App, Sleep Tracker or Website but in the event
                      someone under the age of 18 uses Our Mobile App or Website and accepts this
                      Privacy & Cookies Policy without parental responsibility consent or on another
                      unauthorised basis, We shall not be liable for any breach of the Data
                      Protection Legislation relating to the personal data We collect as a result of
                      such use.We may transfer our rights under this Privacy & Cookies Policy where
                      we reasonably believe your rights will not be affected.
                    </dd>
                    <dd>
                      If any court or competent authority finds that any provision of this Privacy &
                      Cookies Policy (or part of any provision) is invalid, illegal or
                      unenforceable, that provision or part-provision will, to the extent required,
                      be deemed to be deleted, and the validity and enforceability of the other
                      provisions of this Privacy & Cookies Policy will not be affected.
                    </dd>
                    <dd>
                      Unless otherwise agreed, no delay, act or omission by a party in exercising
                      any right or remedy will be deemed a waiver of that, or any other, right or
                      remedy.
                    </dd>
                    <dd>
                      This Privacy & Cookies Policy will be governed by and interpreted according to
                      the law of England and Wales. All disputes arising under the Agreement will be
                      subject to the exclusive jurisdiction of the English and Welsh courts
                    </dd>
                  </section>
                  <section id="pp_p17">
                    <dt>17. Changes to this Privacy & Cookies Policy</dt>
                    <dd>
                      We may change this Privacy & Cookies Policy from time to time. This may be
                      necessary, for example, if the law changes, or if we change our business in a
                      way that affects personal data protection.
                    </dd>
                    <dd>
                      Any changes will be immediately posted on Our Mobile App or Website and you
                      will be deemed to have accepted the terms of the Privacy & Cookies Policy on
                      your first use of Our Mobile App, Sleep Tracker or Website following the
                      alterations. We recommend that you check this page regularly to keep
                      up-to-date. This Privacy & Cookies Policy was last updated on{' '}
                      <b>03/05/2019</b>
                    </dd>
                  </section>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy
