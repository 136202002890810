import API from '@aws-amplify/api'
import { apiPaths, urls } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'sleepSession/SET_STATE',
  GET_SLEEP_SESSIONS: 'sleepSession/GET_SLEEP_SESSIONS',
  GET_APP_SESSIONS: 'sleepSession/GET_APP_SESSIONS',
}

/* function getMyInIt(data) {
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: data,
  }
  return myInit
} */
// API call to get a List of All Devices filtered by the current logged in Provider if a Provider is logged in, or unfiltered if Superadmin is logged in.
export const getSleepSessions = async (data, role) =>
  API.get('virtualClinic', apiPaths[role].apiSleepSessionsList, {
    queryStringParameters: data,
  })
    .then(res => res)
    .catch(e => {
      const { response } = e
      const { data: resData } = response

      return resData
    })

export const getAppSessions = async data =>
  API.get('virtualClinic', urls.referrals.appSessions, {
    queryStringParameters: data,
  })
    .then(res => res)
    .catch(err => {
      console.log('getAppSessions error ', err)
      return {}
    })

export default actions
