import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import Loader from 'components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import PrivacyPolicy from '../pages/user/PrivacyPolicy'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps

    const { location: winLocation } = window
    const { hostname } = winLocation
    if (location.pathname !== prevLocation.pathname) {
      if (hostname === 'cns.virtualsleepclinic.com') {
        ReactGA.pageview(`/${location.path}`)
      }
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props
    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isLoginLayout = getLayout() === 'login'

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages

      if (pathname === '/clinic/NotFound') {
        return children
      }
      if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <Loader />
      }
      if (isUserLoading) {
        return <Loader />
      }
      // redirect to login page if current is not login page and user not authorized
      if (pathname === '/PrivacyPolicy' || pathname === '/privacypolicy' || pathname === '/pp') {
        return <PrivacyPolicy />
      }
      if (!isLoginLayout && !isUserAuthorized) {
        if (pathname !== '/user/login') {
          sessionStorage.setItem('prevURL', `${pathname}${search}`)
        }
        return <Redirect to="/user/login" />
      }
      // redirect to main dashboard when user on login page and authorized
      if (isLoginLayout && isUserAuthorized) {
        return <Redirect to="/" />
      }
      // in other case render previously set layout
      const prevPathname = sessionStorage.getItem('prevURL')
      const mLogout = sessionStorage.getItem('mLogout')
      if (prevPathname && isUserAuthorized && !mLogout) {
        sessionStorage.removeItem('prevURL')
        return <Redirect to={prevPathname} />
      }
      if (mLogout) {
        sessionStorage.removeItem('prevURL')
        sessionStorage.removeItem('mLogout')
      }
      return <Container>{children}</Container>
    }
    return (
      <Fragment>
        <Helmet titleTemplate="Virtual Clinic | %s" title="Circadia" />

        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
export { IndexLayout }
