import actions from './actions'

// Defining the Initial Variables to be saved in the Redux Store on initialize.
const initialState = {
  clinicPatientLoading: false,
  clinicPatientsList: [],
  lastTimezone: '',
  availableDevices: [],
  patientDetail: {},
  clinicPatientAddLoading: false,
  redirect: false,
  availableDevicesLoading: false,
  patientIds: [],
  redirectActivity: false,
  patientIdsLoading: false,
}

export default function clinicPatientReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
