import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import member from './member/reducers'
import profile from './profile/reducers'
import device from './device/reducers'
import packages from './package/reducers'
import invoice from './invoice/reducers'
import referral from './referral/reducers'
import clinic from './clinic/reducers'
import sleepSession from './sleepSession/reducers'
import notifications from './notifications/reducers'
import clinicPatient from './clinicPatient/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    menu,
    device,
    user,
    settings,
    member,
    packages,
    profile,
    invoice,
    referral,
    clinic,
    sleepSession,
    notifications,
    clinicPatient,
  })
