import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import actions, { getSleepSessions, getAppSessions } from './actions'

const getUser = state => state.user

// Function to get A List of Invoices from Server and Save it in Redux Store
export function* GET_SLEEP_SESSIONS({ payload }) {
  yield put({
    type: 'sleepSession/SET_STATE',
    payload: {
      loading: true,
      sleepSessionList: [],
      sleepSessionDevicesList: [],
    },
  })
  const { role } = yield select(getUser)
  const {
    user_type: userType,
    paginationStats = { limit: 20, page: 1 },
    searchStats = {},
    setTotal = false,
  } = payload
  const data = {
    user_type: userType,
    ...paginationStats,
    ...searchStats,
  }
  const sleepSessionList = yield call(getSleepSessions, data, role)
  let sleepSessionDevicesList = {}
  if (setTotal) {
    sleepSessionDevicesList = yield call(getSleepSessions, { user_type: payload.user_type }, role)
  }
  let payloadToBeSent = {
    loading: false,
    sleepSessionList,
    sleepSessionDevicesList,
  }
  if (setTotal) {
    const { pagination } = sleepSessionList
    const { total_records: totalRecords = 0 } = pagination
    payloadToBeSent = {
      ...payloadToBeSent,
      totalSleepSessionRecords: totalRecords,
    }
  }
  payloadToBeSent = {
    ...payloadToBeSent,
    sleepSessionsListPageSize: paginationStats.limit,
    sleepSessionsListPageNumber: paginationStats.page,
  }
  yield put({
    type: 'sleepSession/SET_STATE',
    payload: payloadToBeSent,
  })
}

export function* GET_APP_SESSIONS({ payload }) {
  yield put({
    type: 'sleepSession/SET_STATE',
    payload: {
      appSessionsLoading: true,
    },
  })
  const { data } = payload
  const { role } = yield select(getUser)
  if (role === 'Superadmin') {
    data.user_type = 'sa'
  }
  const appSessions = yield call(getAppSessions, data)

  yield put({
    type: 'sleepSession/SET_STATE',
    payload: {
      appSessions,
      appSessionsList: appSessions.detail,
      appSessionsLoading: false,
    },
  })
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SLEEP_SESSIONS, GET_SLEEP_SESSIONS),
    takeEvery(actions.GET_APP_SESSIONS, GET_APP_SESSIONS),
  ])
}
