import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Settings from 'components/LayoutComponents/Settings'
import style from './style.module.scss'

let time = 0
let timeoutFunction
@withRouter
@connect(({ user, settings }) => ({ user, settings }))
class MainLayout extends React.PureComponent {
  componentDidMount() {
    timeoutFunction = setTimeout(this.incrementTime(), 1000)

    window.onload = this.resetTimer
    // DOM Events
    document.onmousemove = this.resetTimer
    document.onkeypress = this.resetTimer
  }

  componentWillUnmount = () => {
    clearTimeout(timeoutFunction)
  }

  incrementTime = () => {
    const { location } = window
    const { host } = location
    if (host.includes('localhost:3000')) {
      time = 0
    }
    if (time === 300000) {
      const { dispatch } = this.props
      dispatch({
        type: 'user/LOGOUT',
      })
      time = 0
      return true
    }
    time += 1000
    timeoutFunction = setTimeout(this.incrementTime, 1000)
    return false
  }

  resetTimer = () => {
    time = 0
    localStorage.setItem('VCSessionLastActivity', JSON.stringify(new Date().getTime()))
    clearTimeout(timeoutFunction)
    timeoutFunction = setTimeout(this.incrementTime, 1000)
  }

  render() {
    const { location, settings, children } = this.props
    const {
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
      portalVersion,
    } = settings
    return (
      <Layout
        className={classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
        })}
      >
        <Menu />
        <Settings pathname={location.pathname} search={location.search} />
        <Layout>
          <Layout.Header style={{ zIndex: 5 }}>
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="utils__content">{children}</div>
          </Layout.Content>
          <div className={`${style.footer} text-center`}>
            <p className="text-muted">Version {portalVersion}</p>
          </div>
        </Layout>
      </Layout>
    )
  }
}

export default MainLayout
