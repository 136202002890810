import API from '@aws-amplify/api'
import { notification } from 'antd'
import { apiPaths } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'clinic/SET_STATE',
  GET_CLINICID: 'clinic/GET_CLINICID',
  GET_CLINICS: 'clinic/GET_CLINICS',
  ADD_CLINIC: 'clinic/ADD_CLINIC',
  UPDATE_CLINIC: 'clinic/UPDATE_CLINIC',
  GET_CLINIC_DETAIL: 'clinic/GET_CLINIC_DETAIL',
  GET_CLINIC_COMPLETE_PROFILE: 'clinic/GET_CLINIC_COMPLETE_PROFILE',
  GET_CLINIC_USERS: 'clinic/GET_CLINIC_USERS',
  SEND_DOWNLOAD_REPORT: 'clinic/SEND_DOWNLOAD_REPORT',
  EMAIL_REPORT_LINK: 'clinic/EMAIL_REPORT_LINK',
  SEND_EMAIL_REPORT: 'clinic/SEND_EMAIL_REPORT',
}

function getMyInIt(Obj) {
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: Obj,
  }
  return myInit
}
// API call to get a List of All Devices filtered by the current logged in Provider if a Provider is logged in, or unfiltered if Superadmin is logged in.
export const getClinics = async role =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiClinicsListing !== undefined ? apiPaths[role].apiClinicsListing : '',
  )
    .then(res => res)
    .catch(() => [])

export const getClinicDetail = async (data, role) =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiClinicsListing !== undefined ? apiPaths[role].apiClinicsListing : '',
    {
      headers: {},
      queryStringParameters: data,
    },
  )
    .then(res => {
      if (res.length > 0) {
        return res[0]
      }
      return {}
    })
    .catch(() => [])

export const getClinicID = () =>
  API.get('virtualClinic', '/clinic/v1/list', {})
    .then(res => res)
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return {}
    })

// API call to add a new Device (SuperAdmin Only)
export const addClinic = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiClinicCreate, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: `Success`,
        description: 'Clinic created successfully',
      })
      return res
    })
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return []
    })

export const updateClinic = (dataObj, role, showNotification) =>
  API.post('virtualClinic', apiPaths[role].apiClinicUpdate, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => {
      if (showNotification) {
        notification.success({
          message: `Success`,
          description: 'Clinic updated successfully',
        })
      }
      return res
    })
    .catch(err => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return []
    })

export const getClinicMetrics = (role, Obj) =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiClinicMetrics,
    Obj.clinic_id !== undefined ? getMyInIt(Obj) : '',
  )
    .then(res => res)
    .catch(() => {})

export const getClinicUsers = (role, dataObj) =>
  API.post('virtualClinic', apiPaths[role].apiGetClinicUsers, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      const data = response ? response.data : 'No response from server'
      notification.error({
        message: `Someting went wrong`,
        description: `${data}`,
      })
      return []
    })
export const sendDownloadReport = (role, dataObj) =>
  API.post('virtualClinic', apiPaths[role].apiSendDownloadReport, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      const data = response ? response.data : 'No response from server'
      notification.error({
        message: `Someting went wrong`,
        description: `${data}`,
      })
      return []
    })

export const emailReport = (role, dataObj) =>
  API.post('virtualClinic', apiPaths[role].apiEmailReport, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then(res => {
      notification.success({
        message: 'Success',
        description: 'Report sent successfully',
      })
      return res
    })
    .catch(err => {
      const { response } = err
      const data = response ? response.data : 'No response from server'
      notification.error({
        message: `Someting went wrong`,
        description: `${data}`,
      })
      return []
    })
export default actions
