import actions from './actions'

// Defining the Initial Variables to be saved in the Redux Store on initialize.
const initialState = {
  clinicsList: [],
  userClinic: {},
  clinicDevicesList: [],
  userClinicLoading: false,
  totalSleepSessions: 0,
  newUsers: 0,
  userClinicImage: '',
  todaySleepSessions: 0,
  weeklyReportsLoading: false,
  loading: false,
  redirect: false,
  clinicProfileLoading: false,
  newClinic: '',
  editedClinic: '',
  clinicUsers: [],
  clinicUsersLoading: false,
}

export default function clinicReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
