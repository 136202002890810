import API from '@aws-amplify/api'
import moment from 'moment-timezone'
import { apiPaths } from '../../config'
// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'profile/SET_STATE',
  GET_MEMBER_DETAIL: 'profile/GET_MEMBER_DETAIL',
  GET_WEEKLY_REPORT_DATA: 'profile/GET_WEEKLY_REPORT_DATA',
  GET_NIGHT_BY_NIGHT_DATA: 'profile/GET_NIGHT_BY_NIGHT_DATA',
  GET_MEMBER_BODY_CLOCK: 'profile/GET_MEMBER_BODY_CLOCK',
  GET_MEMBER_TRENDS: 'profile/GET_MEMBER_TRENDS',
  STORE_HISTORY_NOTES: 'profile/STORE_HISTORY_NOTES',
  GET_HISTORY_NOTES: 'profile/GET_HISTORY_NOTES',
  GET_MEMBER_DIARY: 'profile/GET_MEMBER_DIARY',
  UPDATE_HISTORY_NOTE: 'profile/UPDATE_HISTORY_NOTE',
  GET_DAILY_REPORT_DATA: 'profile/GET_DAILY_REPORT_DATA',
  GET_MEMBER_AVG_SLEEP_METRICS: 'profile/GET_MEMBER_AVG_SLEEP_METRICS',
  GENERATE_CLINIC_REPORT: 'profile/GENERATE_CLINIC_REPORT',
}
function getMyInIt(userUID) {
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      user_uid: userUID,
    },
  }
  return myInit
}
function getMyInItPost(userUID) {
  const myInit = {
    // OPTIONAL
    body: { user_uid: userUID },
    headers: {}, // OPTIONAL
  }
  return myInit
}
/* function bodyClockInit(Obj) {
  const myInit = {
    headers: {},
    queryStringParameters: {
      user_id: Obj.userUID,
      graph: Obj.graph,
    },
  }
  return myInit
} */
function SleepStagesHistory(userUID, date) {
  const myInit = {
    // OPTIONAL
    queryStringParameters: {
      user_uid: userUID,
      date_of_sleep: date,
    },
    headers: {}, // OPTIONAL
  }
  return myInit
}
export const getMemberDetail = userUID =>
  API.get('virtualClinic', apiPaths.All.apiMemberDetail, getMyInIt(userUID))
    .then(res => res)
    .catch(err => {
      console.log('memberDetail', err)
      return []
    })

export const getMemberSleepSessions = userUID =>
  API.get('virtualClinic', apiPaths.All.apiMemberSleepSessions, getMyInIt(userUID))
    .then(res => res)
    .catch(err => {
      const { response } = err
      console.log('memberSleepSessions', !response ? err : response)
      return []
    })

export const getMemberCSIHistory = userUID =>
  API.post('virtualClinic', apiPaths.All.apiMemberCSIHistory, getMyInItPost(userUID))
    .then(res => res)
    .catch(err => {
      console.log('memberCSIHistory', err)
      return []
    })

export const getLastSleepStagesHistory = userUID =>
  API.get('virtualClinic', apiPaths.All.apiMemberSleepStages, {
    // OPTIONAL
    queryStringParameters: {
      user_uid: userUID,
    },
    headers: {}, // OPTIONAL
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      console.log('error getLastSleepStagesHistory', !response ? err : response)
      return { status: 404 }
    })

export const getMemberSleepStagesHistory = (userUID, date) =>
  API.get('virtualClinic', apiPaths.All.apiMemberSleepStages, SleepStagesHistory(userUID, date))
    .then(res => res)
    .catch(err => {
      const { response } = err
      console.log('error getMemberSleepStagesHistory', !response ? err : response)
      return { status: 404 }
    })

export const getMemberBodyClock = dataObj =>
  API.get('virtualClinic', apiPaths.All.apiCircadiaAnalysisGraphs, {
    headers: {},
    queryStringParameters: {
      user_id: dataObj.userUID,
      graph: dataObj.graph,
    },
  })
    .then(res => {
      const { img } = res
      const { graph } = dataObj

      if (graph === 'process_c') {
        return res
      }
      if (img) {
        return img
      }

      return res
    })
    .catch(err => {
      console.log('bodyclocktimeline', err)
      return {}
    })

export const getMemberPRCImage = dataObj =>
  API.get('virtualClinic', apiPaths.All.apiCircadiaAnalysisGraphs, {
    headers: {},
    queryStringParameters: {
      user_id: dataObj.userUID,
      graph: dataObj.graph,
    },
  })
    .then(res => res || '')
    .catch(err => {
      console.log('PRC error', err)
      return ''
    })

export const getMemberBodyClockImage = dataObj =>
  API.get('virtualClinic', apiPaths.All.apiCircadiaAnalysisGraphs, {
    headers: {},
    queryStringParameters: {
      user_id: dataObj.userUID,
      graph: dataObj.graph,
    },
    response: true,
  })
    .then(res => res || '')
    .catch(err => {
      console.log('bodyclock display error', err)
      return ''
    })

export const getMemberProcessCImage = dataObj =>
  API.get(
    'virtualClinic',
    `${apiPaths.All.apiCircadiaAnalysisGraphs}?user_id=${dataObj.userUID}&graph=${dataObj.graph}`,
  )
    .then(res => res || '')
    .catch(err => {
      console.log('process c error', err)
      return ''
    })

export const getMetricsHistory = userUID =>
  API.post('virtualClinic', apiPaths.All.apiMetricsHistory, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: {
      user_uid: userUID,
      date: moment().format('YYYY-MM-DD'),
    },
  })
    .then(res => res)
    .catch(err => {
      console.log('memberSleepSessions', err)
      return []
    })

export const storeHistory = dataObj =>
  API.post('virtualClinic', `/notes/v1/create`, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: {
      member_id: dataObj.memberID,
      text: dataObj.values,
    },
  })
    .then(res => res)
    .catch(err => {
      console.log('store historyNotes error: ', err)
      return []
    })

export const UpdateHistory = dataObj =>
  API.post('virtualClinic', `/notes/v1/update`, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: {
      id: dataObj.id,
      member_id: dataObj.memberID,
      text: dataObj.values,
    },
  })
    .then(res => res)
    .catch(err => {
      console.log('update historyNote error: ', err)
      return []
    })

export const historyNotes = userID =>
  API.get('virtualClinic', `/notes/v1/list`, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      member_id: userID,
    },
  })
    .then(res => res)
    .catch(err => {
      console.log('list historyNotes error: ', err)
      return []
    })

export const getMemberDiary = dataObj =>
  API.get('virtualClinic', apiPaths.All.apiMemberDiary, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      member_id: dataObj.userUID,
      date: dataObj.date,
    },
  })
    .then(res => res)
    .catch(err => {
      console.log('memberDiary error: ', err)
      return []
    })

export const getMemberAVGSleepMetrics = memberID =>
  API.get('virtualClinic', apiPaths.All.apiAVGSLeepMetrics, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      member_id: memberID,
    },
  })
    .then(res => res)
    .catch(err => {
      console.log('AVG sleep metrcis error: ', err)
      return []
    })

export const getBreathingPatternData = data =>
  API.get('virtualClinic', apiPaths.All.apiBreathingPattern, {
    headers: {},
    queryStringParameters: data,
  })
    .then(res => res)
    .catch(err => {
      console.log('Breathing Pattern error: ', err)
      return []
    })

export const convertTime = (date, timezone) => {
  const m = moment.utc(date) // parse input as UTC
  if (timezone === undefined || timezone === null) {
    const local = m.local().format('MM/DD/YYYY HH:mm:ss')
    return local
  }
  const newDate = m.tz(timezone).format('MM/DD/YYYY HH:mm:ss')
  return newDate
}

export const convertTimeOffset = (date, offset, format = 'MM/DD/YYYY HH:mm:ss') => {
  const m = moment.utc(date)
  if (offset === undefined || offset === null) {
    const local = m.local().format('MM/DD/YYYY HH:mm:ss')
    return local
  }
  const newDate = m.utcOffset(offset)
  return newDate.format(format)
}

export const getOffsetFromRecommendation = recommendation => {
  recommendation = recommendation.split('\n')
  recommendation = recommendation[1] ? recommendation[1] : ''
  recommendation = recommendation.split(' ')
  recommendation = recommendation[2] ? parseFloat(recommendation[2], 10) : 0

  return recommendation
}

export default actions
