export async function getLeftMenuData(role) {
  const superAdminMenu = [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'home',
      url: '/coach/profile',
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'user',
      url: '/users',
    },
    {
      title: 'Clinics',
      key: 'clinic',
      icon: 'medicine-box',
      url: '/clinics',
    } /* 
    {
      title: 'Manage Devices',
      key: 'device',
      icon: 'fa fa-tasks',
      url: '/device',
      display: false,
    }, */,
    {
      title: 'Sleep Kit',
      key: 'sleepTools',
      icon: 'book',
      url: '/sleepTools',
    },
    {
      title: 'Devices',
      key: 'manage_deviceInventory',
      icon: 'hdd',
      url: '/inventory',
    },
    {
      title: 'Packages',
      key: 'manage_packages',
      icon: 'packages',
      url: '/packages',
    },
    {
      title: 'Invoices',
      key: 'invoices',
      icon: 'pound-circle',
      url: '/invoices',
    },
    {
      title: 'Referrals',
      key: 'referrals',
      icon: 'fa fa-users',
      url: '/referrals',
    },
    {
      title: 'Weekly Report',
      key: 'weeklyReports',
      icon: 'snippets',
      url: '/weeklyReports/',
    },
    {
      title: 'Sleep Sessions',
      key: 'sleepSessions',
      icon: 'fa fa-history',
      iconType: 'fontAwesome',
      url: '/sleepSessions/',
    },
    {
      title: 'App Sessions',
      key: 'appSessions',
      icon: 'mobile',
      url: '/appSessions/',
    },
    // {
    //   title: 'Notifications',
    //   key: 'notifications',
    //   icon: 'bell',
    //   url: '/notifications/',
    // },
  ]

  const clinicMenu = [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'home',
      url: '/clinic/profile',
    },
    {
      title: 'Patients',
      key: 'Patients',
      icon: 'user',
      url: '/patient/',
    },
    {
      title: 'Devices',
      key: 'device',
      icon: 'hdd',
      url: '/device',
    },
    {
      title: 'Report',
      key: 'Report',
      icon: 'file-pdf',
      url: '/report/',
    },
    {
      title: 'Sleep Kit',
      key: 'sleepTools',
      icon: 'book',
      url: '/sleepTools',
    },
    // {
    //   title: 'Sleep Sessions',
    //   key: 'sleepSessions',
    //   icon: 'hdd',
    //   url: '/sleepSessions/',
    // },
    // {
    //   title: 'Users',
    //   key: 'users',
    //   icon: 'user',
    //   url: '/users',
    // },
  ]

  const adminMenu = [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'home',
      url: '/coach/profile',
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'user',
      url: '/users',
    },
    // {
    //   title: 'Appointments',
    //   key: 'appointments',
    //   icon: 'icmn icmn-calendar',
    //   url: '/event',
    // },
    // {
    //   title: 'Appointments',
    //   key: 'appointments',
    //   icon: 'icmn icmn-calendar',
    //   url: '/event',
    //   display: false,
    //   children: [{ title: 'New Appointment', key: 'addevent', url: '/event/add' }],
    // },
    // {
    //   title: 'Income',
    //   key: 'income',
    //   icon: 'icmn icmn-coin-dollar',
    //   url: '/income',
    // },
    {
      title: 'Sleep Kit',
      key: 'sleepTools',
      icon: 'book',
      url: '/sleepTools',
    },
    {
      title: 'Devices',
      key: 'device',
      icon: 'hdd',
      url: '/device',
    },
    {
      title: 'Packages',
      key: 'manage_packages',
      icon: 'packages',
      url: '/packages',
    },
    /* {
      title: 'VC Assessment',
      key: 'vc_assessment',
      icon: 'reconciliation',
      url: '/vcAssessment',
    }, */
  ]
  const menu = []
  if (role === 'Superadmin') {
    return superAdminMenu
  }
  if (role === 'Provider') {
    return adminMenu
  }
  if (role === 'Clinic') {
    return clinicMenu
  }
  return menu
  // return role === 'Superadmin' ? superAdminMenu : adminMenu
}
export async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Docs',
      key: 'documentation',
      url: 'https://docs.cleanuitemplate.com/react/getting-started',
      target: '_blank',
      icon: 'icmn icmn-books',
    },
    {
      title: 'Dashboards',
      key: 'dashboards',
      icon: 'icmn icmn-stack',
      children: [
        {
          title: 'Dashboard Alpha',
          key: 'dashboardAlpha',
          url: '/dashboard/alpha',
        },
      ],
    },
  ]
}
