import { all, call, takeEvery, take, select, put } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

const socketServerURL = `wss://v9nk4dufp2.execute-api.us-east-1.amazonaws.com/prod`
let socket

const getDevice = state => state.device

const connectSocket = token => {
  /* 
  console.log('token is this', token) */
  socket = new WebSocket(`${socketServerURL}?token=${token}`)
  return new Promise(resolve => {
    socket.onopen = () => {
      console.log('fawwad socketisOpen', socket)
      socket.send(
        JSON.stringify({
          action: 'addInRoom',
          room: 'CLINIC-ADMIN',
          content: 'Testing the Websocket API.',
        }),
      )
      resolve(true)
    }
  })
}

const createSocketChannel = socketInstance =>
  eventChannel(emit => {
    const handler = data => {
      emit(data)
    }
    socketInstance.onmessage = handler
    socketInstance.onerror = handler
    return () => {
      socketInstance.onclose = handler
    }
  })

export function* LISTEN_TO_SOCKET({ payload }) {
  yield take
  const { jwtToken } = payload
  yield call(connectSocket, jwtToken)

  const socketChannel = yield call(createSocketChannel, socket)
  while (true) {
    const returnedData = yield take(socketChannel)
    let { data } = returnedData
    data = JSON.parse(data)
    if (data.device_id) {
      const { inventoryDevices } = yield select(getDevice)
      inventoryDevices.map(value => {
        if (value.device_id === data.device_id) {
          value.device_conn_status = data.status
        }
        return true
      })
      yield put({
        type: 'device/SET_STATE',
        payload: {
          inventoryDevices,
        },
      })
    }
  }
}

export function CLOSE_SOCKET() {
  if (socket) {
    socket.close()
  }
  return true
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([takeEvery('socket/LISTEN_TO_SOCKET', LISTEN_TO_SOCKET)])
}
