import { all, put, call, select, takeEvery } from 'redux-saga/effects'
import { getLeftMenuData } from 'services/menu'
import actions from '../user/actions'

const getUser = state => state.user
export function* GET_DATA() {
  const role = yield select(getUser)
  let menuLeftData = yield call(getLeftMenuData, role)
  menuLeftData = menuLeftData.filter(datum => datum.display !== false)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
    },
  })
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, GET_DATA),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
