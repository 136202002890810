import actions from './actions'

// Defining the Initial Variables to be saved in the Redux Store on initialize.
const initialState = {
  notificationsList: [],
  loading: false,
}

export default function referralReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
