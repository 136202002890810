import actions from './actions'

// Defining the Initial Variables to be saved in the Redux Store on initialize.
const initialState = {
  referralList: [],
  weeklyReportsList: [],
  totalSleepSessions: 0,
  newUsers: 0,
  todaySleepSessions: 0,
  weeklyReportsLoading: false,
  loading: false,
}

export default function referralReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
