import React from 'react'
import { connect } from 'react-redux'
import { Icon } from 'antd'
// import { Button } from 'antd'
// import { FormattedMessage } from 'react-intl'
// import HomeMenu from './HomeMenu'
// import ProjectManagement from './ProjectManagement'
// import IssuesHistory from './IssuesHistory'
// import LiveSearch from './LiveSearch'
// import BitcoinPrice from './BitcoinPrice'
import ProfileMenu from './ProfileMenu'
// import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss' /* ---* Uncomment to use Breadcrumbs *---
import Breadcrumbs from '../Breadcrumbs' */

@connect(({ clinic, user }) => ({
  clinic,
  user,
}))
class TopBar extends React.Component {
  render() {
    const { clinic, user } = this.props
    const { role } = user
    const { userClinicImage, userClinic } = clinic
    return (
      <div className={styles.topbar}>
        {role === 'Clinic' && (
          <div className="mr-4" style={{ height: '100%' }}>
            <img
              style={{
                height: '80%',
                verticalAlign: 'middle',
                borderRadius: '7px',
                padding: '0px 10px',
              }}
              src={userClinicImage}
              alt={userClinic.name}
            />
          </div>
        )}
        {/*
        <div className="mr-4">
          <ProjectManagement />
        </div>
        <div className="d-none d-md-block mr-4">
          {/*  ---* Uncomment to use Breadcrumbs *---
          <Breadcrumbs /> 
        </div> */}
        <div className="mr-auto" />

        <div className="mr-4" />
        <div className="mr-4" />

        <div className="mr-4">
          {/*  <HomeMenu /> */}
          <a
            href="https://circadia-sleep-kit.s3.amazonaws.com/feature-manual/Clinic-Features-Manual.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="question-circle" theme="filled" style={{ fontSize: '18px' }} />
          </a>
        </div>
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
