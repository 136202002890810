import API from '@aws-amplify/api'
import { apiPaths } from '../../config'

// Defined Device Constants to bind SAGA functions with Redux Dispatcher
const actions = {
  SET_STATE: 'notifications/SET_STATE',
  GET_NOTIFICATIONS: 'notifications/GET_NOTIFICATIONS',
}

function getMyInIt(data) {
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: data,
  }
  return myInit
}
// API call to get a List of All Devices filtered by the current logged in Provider if a Provider is logged in, or unfiltered if Superadmin is logged in.
export const getNotifications = async data =>
  API.get(
    'virtualClinic',
    apiPaths.All.apiNotificationList,
    Object.keys(data).length > 0 ? getMyInIt(data) : '',
  )
    .then(res => res)
    .catch(() => [])

export default actions
