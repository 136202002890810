import { put, call, takeEvery, select, all } from 'redux-saga/effects'
import moment from 'moment-timezone'
import actions, {
  getMemberDetail,
  getMemberCSIHistory,
  getMemberSleepStagesHistory,
  getMemberBodyClock,
  convertTime,
  getMemberSleepSessions,
  getMetricsHistory,
  storeHistory,
  historyNotes,
  getMemberDiary,
  UpdateHistory,
  getLastSleepStagesHistory,
  getMemberAVGSleepMetrics,
  getOffsetFromRecommendation,
  convertTimeOffset,
} from './actions'
import { formatWeeklyReportData } from '../../services/formattingFunctions'
import { getDeviceSession, getDeviceMetricsHistory } from '../device/actions'
import {
  getPatientSleepStagesHistory,
  getPatientTrendsData,
  getClinicPatients,
} from '../clinicPatient/actions'

const getProfile = (state) => state.profile
const getUser = (state) => state.user

const formateTime = (time, timeZone) => {
  const timeInFloating = parseFloat(time)
  const minsFloating = (timeInFloating % 1) * 60
  let mins = Math.trunc(minsFloating)
  let hours = `${time}`.split('.')[0]

  let offset = moment().utcOffset() / 60
  if (timeZone) {
    offset = moment().tz(timeZone).utcOffset() / 60
  }
  hours = parseInt(hours, 10)
  hours += offset
  if (hours >= 24) {
    hours -= 24
  } else if (hours < 0) {
    hours += 24
  }
  mins = mins < 10 ? `0${mins}` : mins
  hours = hours < 10 ? `0${hours}` : hours
  if (hours < 12) {
    hours = hours === '00' ? 12 : hours
    return `${hours}:${mins} AM`
  }
  hours -= 12
  return `${hours}:${mins} PM`
}

export function* GET_MEMBER_DETAIL({ payload }) {
  const { userUID } = payload
  let { date } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      nightByNightLoading: true,
      loading: true,
      memberSleepSessions: [],
      memberDetail: {},
      memberCSIHistory: [],
      memberAVGSleepMetrics: {},
    },
  })
  const [memberSleepSessions, memberCSIHistory, memberDetail, memberAVGSleepMetrics] = yield all([
    call(getMemberSleepSessions, userUID),
    call(getMemberCSIHistory, userUID),
    call(getMemberDetail, userUID),
    call(getMemberAVGSleepMetrics, userUID),
  ])

  if (!date) {
    if (memberSleepSessions.length > 0) {
      memberSleepSessions.sort((a, b) => new Date(b.date_of_sleep) - new Date(a.date_of_sleep))
      date = memberSleepSessions[0].date_of_sleep
    } else {
      date = moment().format('YYYY-MM-DD')
    }
  }

  const { tz_id: timeZone } = memberDetail
  const formattedMemberAVGSleepMetrics = formatAVGMetrcis(memberAVGSleepMetrics, timeZone)
  if (memberDetail.user_id === '1d368620-9262-4bb5-b9ce-1693251bcfca') {
    memberDetail.dob = '1995-05-30'
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
      memberDetail,
      memberAVGSleepMetrics: formattedMemberAVGSleepMetrics,
      memberSleepSessions,
      memberCSIHistory,
    },
  })

  yield put({
    type: 'setting/SET_STATE',
    payload: {
      isSettingsOpen: false,
    },
  })
  yield call(GET_NIGHT_BY_NIGHT_DATA, { payload: { userUID, date, isInitial: true } })
  yield all([
    call(GET_MEMBER_TRENDS, { payload: { userUID } }),
    call(GET_MEMBER_BODY_CLOCK, { payload: { userUID } }),
    call(GET_HISTORY_NOTES, { payload: { userUID } }),
  ])
}

export function* GET_NIGHT_BY_NIGHT_DATA({ payload }) {
  const { userUID, date, isInitial = false } = payload
  if (!userUID) {
    return false
  }
  yield put({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'isSettingsOpen',
      value: false,
    },
  })
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      diaryLoading: true,
      nightByNightLoading: true,
      memberDiary: [],
      calendarDate: date,
    },
  })

  const [memberSleepStagesHistory, memberDiary] = yield all([
    call(getMemberSleepStagesHistory, userUID, date),
    call(getMemberDiary, payload),
  ])
  let formattedMemberDiary = []
  if (memberSleepStagesHistory.status === 404) {
    if (memberDiary.length > 0) {
      formattedMemberDiary = formatDiary(memberDiary)
    }
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        memberSleepStagesHistory: {},
        noiseGraphData: [],
        motionGraphData: [],
        respiratoryGraphData: [],
        respiratoryVariableGraphData: [],
        hypnogramData: [],
        lightAMChartData: [],
        memberDiary: formattedMemberDiary,
        nightByNightLoading: false,
      },
    })
    return false
  }

  const { memberCSIHistory } = yield select(getProfile)
  let currentCSI = 0
  memberCSIHistory.map((value) => {
    if (value.date === date) {
      currentCSI = value.csi_final
    }
    return true
  })

  const userTimezone = parseInt(memberSleepStagesHistory.tz_diff, 10)
  const {
    hypnogramData,
    noiseGraphData,
    motionGraphData,
    respiratoryGraphData,
    respiratoryVariableGraphData,
    absence,
    lightAMChartData,
    presenceData,
  } = formatSleepStagesHistory(memberSleepStagesHistory, userTimezone, 'number')

  if (memberDiary.length > 0) {
    formattedMemberDiary = formatDiary(memberDiary)
  }

  if (isInitial) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        lastDeviceId: memberSleepStagesHistory.device_id,
      },
    })
  }

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      diaryLoading: false,
      memberDiary: formattedMemberDiary,
      memberSleepStagesHistory,
      noiseGraphData,
      hypnogramData,
      motionGraphData,
      deviceID: memberSleepStagesHistory.device_id,
      respiratoryGraphData,
      respiratoryVariableGraphData,
      absence,
      currentCSI,
      calendarDate: date,
      lightAMChartData,
      presenceData,
      nightByNightLoading: false,
    },
  })
}

export function* GET_MEMBER_BODY_CLOCK({ payload }) {
  const { userUID } = payload
  const bodyClockObj = {
    userUID,
    graph: 'bodyclock_points',
  }
  const bodyClockImageObj = {
    userUID,
    graph: 'bodyclock',
  }
  const bodyClockProccessCObj = {
    userUID,
    graph: 'process_c',
  }
  const bodyClockPRCObj = {
    userUID,
    graph: 'prc',
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      tabLoading: true,
      memberBodyClockData: [],
      bodyclockSummary: [],
      memberBodyClockImage: '',
      memberProcessCImage: '',
      memberPRCImage: '',
      memberBodyClockGraphData: [],
    },
  })
  const [
    memberBodyClockData,
    memberPRCImage,
    memberBodyClockImage,
    memberProcessCRequest,
  ] = yield all([
    call(getMemberBodyClock, bodyClockObj),
    call(getMemberBodyClock, bodyClockPRCObj),
    call(getMemberBodyClock, bodyClockImageObj),
    call(getMemberBodyClock, bodyClockProccessCObj),
  ])
  const memberProcessCImage = memberProcessCRequest.img ? memberProcessCRequest.img : ''
  const recommendation = memberProcessCRequest.recommendation
    ? memberProcessCRequest.recommendation
    : ''
  const bodyClockOffset = getOffsetFromRecommendation(recommendation)
  const [CProcessData, timelineData, bodyclockSummary] = yield all([
    call(formatCProcessData, memberBodyClockData),
    call(formatTimelineData, memberBodyClockData),
    call(formatBodyClockSummary, memberBodyClockData, bodyClockOffset),
  ])

  const formattedBodyClockData = { datasets: timelineData.concat(CProcessData) }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      memberBodyClockData,
      bodyclockSummary,
      memberBodyClockImage,
      memberProcessCImage,
      memberPRCImage,
      memberBodyClockGraphData: formattedBodyClockData,
      tabLoading: false,
    },
  })
  return bodyclockSummary
}

export function* GET_MEMBER_TRENDS({ payload }) {
  const { userUID } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      tabLoading: true,
    },
  })

  const trendsData = yield call(getMetricsHistory, userUID)
  const { weeklyTrendsData, monthlyTrendsData, fortnightTrendsData } = formatTrendsData(trendsData)

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      monthlyTrendsData,
      weeklyTrendsData,
      fortnightTrendsData,
      tabLoading: false,
    },
  })
}

export function* GET_DAILY_REPORT_DATA({ payload }) {
  const { userUID, date, deviceId, isDeviceProfile = false, clinicId } = payload
  if (!isDeviceProfile && (!userUID || !date)) {
    return false
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      reportDataLoading: true,
      reportCurrentDate: date,
    },
  })
  const diaryPayload = {
    userUID: payload.userUID,
    date: payload.date,
  }
  if (isDeviceProfile) {
    const data = {
      device_id: deviceId,
      clinic_id: clinicId,
    }
    const { role } = yield select(getUser)
    if (role === 'Superadmin') {
      data.user_type = 'sa'
    }
    const [tempMemberSleepStagesHistory, trendsData] = yield all([
      call(getDeviceSession, data, date),
      call(getDeviceMetricsHistory, deviceId),
    ])
    let { tz_diff: tzDiff } = tempMemberSleepStagesHistory

    tzDiff = parseInt(tzDiff, 10)

    const {
      hypnogramData: reportHypnogramData,
      noiseGraphData: reportNoiseData,
      motionGraphData: reportMotionData,
      respiratoryGraphData: reportRespiratoryData,
      respiratoryVariableGraphData: reportRespiratoryVData,
      absence: reportAbsence,
      lightAMChartData: reportLightData,
      presenceData: reportPresenceData,
    } = formatSleepStagesHistory(tempMemberSleepStagesHistory, tzDiff, 'number')

    const { weeklyTrendsData, monthlyTrendsData } = formatTrendsData(trendsData)
    let reportTTA = 0

    monthlyTrendsData.map((datum) => {
      if (datum.metricKey === 'time_awaken') {
        datum.monthData.map((datum2) => {
          if (datum2.date === date) {
            reportTTA = datum2.value
          }
          return false
        })
      }
      return false
    })
    return yield put({
      type: 'profile/SET_STATE',
      payload: {
        reportTTA,
        deviceID: deviceId,
        reportMemberSleepStagesHistory: tempMemberSleepStagesHistory,
        weeklyTrendsData,
        reportDataLoading: false,
        reportHypnogramData,
        reportPresenceData,
        reportMotionData,
        reportLightData,
        reportDiaryData: formattedReportDiaryData,
        reportRespiratoryData,
        reportRespiratoryVData,
        reportNoiseData,
        reportAbsence,
      },
    })
  }
  const [
    tempMemberSleepStagesHistory,
    trendsData,
    reportDiaryData,
    memberCSIHistory,
    lastmemberSleepStagesHistory,
  ] = yield all([
    call(getMemberSleepStagesHistory, userUID, date),
    call(getMetricsHistory, userUID),
    call(getMemberDiary, diaryPayload),
    call(getMemberCSIHistory, userUID),
    call(getLastSleepStagesHistory, userUID),
  ])
  let memberSleepStagesHistory = tempMemberSleepStagesHistory
  const reportHypnogramData = []
  const reportPresenceData = []
  const reportLightData = []
  const reportRespiratoryData = []
  const reportRespiratoryVData = []
  const reportNoiseData = []
  const reportMotionData = []

  let reportAbsence = 0
  if (memberSleepStagesHistory.status === 404) {
    memberSleepStagesHistory = {}
  } else {
    const { levels } = memberSleepStagesHistory
    const { env, data } = levels

    let lastPresenceDate
    let lastRRValue = 0
    let lastRRv1Value = 0
    let lastMotionValue = 0
    let lastNoiseValue = 0

    const userTimezone = memberSleepStagesHistory.tz_diff

    env.map((datum, count) => {
      const dateTime = new Date(
        convertTimeOffset(
          datum.packet_dt,
          typeof userTimezone === 'string' ? parseInt(userTimezone, 10) : userTimezone,
        ),
      )
      // let dateTime = new Date(convertTime(datum.packet_dt))

      // if (userTimezone) {
      //   dateTime = new Date(convertTime(datum.packet_dt, userTimezone))
      // }

      if (datum.presence === 0) {
        if (!lastPresenceDate) {
          lastPresenceDate = dateTime
        }
        if (datum.noice_max) {
          lastNoiseValue = datum.noice_max
        }
        reportNoiseData.push({
          t: dateTime,
          y: lastNoiseValue,
        })
        reportRespiratoryData.push({
          t: dateTime,
          y: '0',
        })
        reportRespiratoryVData.push({
          t: dateTime,
          y: '0',
        })
        reportLightData.push({
          t: dateTime,
          r: datum.illum_r_max,
          g: datum.illum_g_max,
          b: datum.illum_b_max,
          w: datum.illum_w_max,
        })
        reportMotionData.push({
          t: dateTime,
          y: '0',
        })

        return true
      }
      if (lastPresenceDate) {
        let endDate = new Date(
          convertTimeOffset(
            env[count - 1].packet_dt,
            typeof userTimezone === 'string' ? parseInt(userTimezone, 10) : userTimezone,
          ),
        ) // endDate = new Date(convertTime(env[count - 1].packet_dt))
        // if (userTimezone) {
        //   endDate = new Date(convertTime(env[count - 1].packet_dt, userTimezone))
        // }
        if (lastPresenceDate === endDate) {
          endDate += 15000
        }
        reportPresenceData.push({
          startDate: lastPresenceDate,
          endDate,
        })
        lastPresenceDate = null
      }
      if (datum.noice_max) {
        lastNoiseValue = datum.noice_max
      }
      reportNoiseData.push({
        t: dateTime,
        y: lastNoiseValue,
      })
      if (datum.trackerRR) {
        lastRRValue = datum.trackerRR
      }
      reportRespiratoryData.push({
        t: dateTime,
        y: lastRRValue,
        value: lastRRValue,
      })
      if (datum.trackerRR_V1) {
        lastRRv1Value = datum.trackerRR_V1
      }
      reportRespiratoryVData.push({
        t: dateTime,
        y: lastRRv1Value,
        value: lastRRv1Value,
      })
      reportLightData.push({
        t: dateTime,
        r: datum.illum_r_max,
        g: datum.illum_g_max,
        b: datum.illum_b_max,
        w: datum.illum_w_max,
      })
      if (datum.tracker_mvmnt_V2_amp) {
        lastMotionValue = datum.tracker_mvmnt_V2_amp
      }
      reportMotionData.push({
        t: dateTime,
        y: lastMotionValue,
      })
      return true
    })
    data.map((datum, count) => {
      const dateTime = convertTimeOffset(
        datum.created_at,
        typeof userTimezone === 'string' ? parseInt(userTimezone, 10) : userTimezone,
      )
      // let dateTime = convertTime(datum.created_at)

      // if (userTimezone) {
      //   dateTime = convertTime(datum.created_at, userTimezone)
      // }

      if (datum.stage === 'W') {
        reportHypnogramData.push({
          t: new Date(dateTime),
          y: 4,
          state: 'Wake',
        })
      } else if (datum.stage === 'D') {
        reportHypnogramData.push({
          t: new Date(dateTime),
          y: 1,
          state: 'Deep',
        })
      } else if (datum.stage === 'R') {
        reportHypnogramData.push({
          t: new Date(dateTime),
          y: 3,
          state: 'REM',
        })
      } else {
        reportHypnogramData.push({
          t: new Date(dateTime),
          y: 2,
          state: 'Light',
        })
      }
      if (count === data.length - 1) {
        const lastDateTime = moment(dateTime, 'MM/DD/YYYY HH:mm:ss').add(datum.seconds, 'seconds')
        const lastY = reportHypnogramData[reportHypnogramData.length - 1].y
        const lastState = reportHypnogramData[reportHypnogramData.length - 1].state
        reportHypnogramData.push({
          t: new Date(lastDateTime),
          y: lastY,
          state: lastState,
        })
      }
      return true
    })
    reportPresenceData.map((datum) => {
      reportAbsence += moment(datum.endDate).diff(moment(datum.startDate), 'minute')
      return true
    })
  }
  const { weeklyTrendsData, monthlyTrendsData } = formatTrendsData(trendsData)
  let reportCSI = 0
  memberCSIHistory.map((datum) => {
    if (datum.date === date) {
      reportCSI = Math.round(datum.csi_final)
      return true
    }
    return false
  })
  let reportTTA = 0
  monthlyTrendsData.map((datum) => {
    if (datum.metricKey === 'time_awaken') {
      datum.monthData.map((datum2) => {
        if (datum2.date === date) {
          reportTTA = datum2.value
        }
        return false
      })
    }
    return false
  })

  let formattedReportDiaryData = []
  if (reportDiaryData.length > 0) {
    formattedReportDiaryData = formatDiary(reportDiaryData)
  }
  const deviceID = lastmemberSleepStagesHistory.device_id
    ? lastmemberSleepStagesHistory.device_id
    : ''

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      reportTTA,
      reportCSI,
      deviceID,
      reportMemberSleepStagesHistory: memberSleepStagesHistory,
      weeklyTrendsData,
      reportDataLoading: false,
      reportHypnogramData,
      reportPresenceData,
      reportMotionData,
      reportLightData,
      reportDiaryData: formattedReportDiaryData,
      reportRespiratoryData,
      reportRespiratoryVData,
      reportNoiseData,
      reportAbsence,
    },
  })
}

export function* GENERATE_CLINIC_REPORT({ payload }) {
  const { role } = yield select(getUser)
  const { date, deviceId, clinicId, getReferences } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      reportDataLoading: true,
    },
  })

  let sleepStagesHistoryObject = {}

  if (role === 'Superadmin') {
    sleepStagesHistoryObject = {
      device_id: deviceId,
      user_type: 'sa',
      clinic_id: clinicId,
    }
  } else {
    sleepStagesHistoryObject = {
      device_id: deviceId,
    }
  }
  const { deviceObject } = payload
  let sleepStagesFunction = getDeviceSession
  let trendsFunction = getDeviceMetricsHistory

  if (payload.selectedPatient) {
    const [patientDetail] = yield call(getClinicPatients, role, {
      patient_id: deviceObject.patient_id,
      clinic_id: clinicId,
    })
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        memberDetail: patientDetail,
      },
    })
    sleepStagesHistoryObject = {
      clinic_id: clinicId,
      patient_id: deviceObject.patient_id,
      device_id: deviceId,
    }
    sleepStagesFunction = getPatientSleepStagesHistory
    trendsFunction = getPatientTrendsData
  }
  const memberSleepStagesHistory = yield call(sleepStagesFunction, sleepStagesHistoryObject)

  const trendsData = yield call(trendsFunction, {
    device_id: deviceId,
    date: date.format('YYYY-MM-DD'),
    clinic_id: clinicId,
    user_type: 'sa',
  })
  const userTimezone = parseInt(memberSleepStagesHistory.tz_diff, 10)
  const { weeklyTrendsData } = formatTrendsData(trendsData)

  const [functionsList, dates] = [[], []]
  const daysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  daysArray.map((val) => {
    const tempDate = moment(date).day(val).week(date.week()).format('YYYY-MM-DD')
    dates.push(moment(date).day(val).week(date.week()).format('YYYY-MM-DD'))
    functionsList.push(call(sleepStagesFunction, sleepStagesHistoryObject, tempDate))
    return true
  })

  const allFunctions = yield all(functionsList)

  const { weeklyReportMetrics, weeklyReportData, reportSleepSessions } = yield call(
    formatWeeklyReportData,
    allFunctions,
    userTimezone,
    dates,
    getReferences,
  )

  let weeklyReportMetricsSummary = weeklyTrendsData.map((datum) => {
    datum.graphData = datum.graphData.reverse()
    datum.weekData = datum.weekData.reverse()
    if (datum.metricKey === 'time_awaken') {
      datum.metric = 'TTA [minutes]'
    }
    return datum
  })
  weeklyReportMetricsSummary = weeklyReportMetricsSummary.filter(
    (datum) =>
      datum.metricKey !== 'percent_light' &&
      datum.metricKey !== 'percent_deep' &&
      datum.metricKey !== 'percent_rem',
  )
  delete weeklyReportMetrics['TTA [minutes]']

  return yield put({
    type: 'profile/SET_STATE',
    payload: {
      weeklyReportMetricsSummary,
      weeklyReportData,
      weeklyReportMetrics,
      reportSleepSessions,
      deviceID: deviceId,
      reportDataLoading: false,
    },
  })
}

export function* GET_WEEKLY_REPORT_DATA({ payload }) {
  const { userUID, date, deviceId, isDeviceProfile = false, clinicId, getReferences } = payload
  const { role } = yield select(getUser)
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      reportDataLoading: true,
    },
  })
  if (isDeviceProfile) {
    const weeklyReportMetrics = {
      'Total Sleep Time': [],
      /* 'TTA [minutes]': [], */
      'Sleep Efficiency': [],
      'Sleep Onset Latency': [],
      'REM Onset Latency': [],
      'Time in Bed': [],
      'Time Awake': [],
      Absence: [],
    }
    const weeklyReportData = { Wake: [], REM: [], Light: [], Deep: [] }
    let sleepStagesHistoryObject = {}

    if (role === 'Superadmin') {
      sleepStagesHistoryObject = {
        device_id: deviceId,
        user_type: 'sa',
        clinic_id: clinicId,
      }
    } else {
      sleepStagesHistoryObject = {
        device_id: deviceId,
      }
    }
    const { deviceObject } = payload
    let sleepStagesFunction = getDeviceSession
    let trendsFunction = getDeviceMetricsHistory

    if (payload.selectedPatient) {
      console.log('payload is', payload)

      const [patientDetail] = yield call(getClinicPatients, role, {
        patient_id: deviceObject.patient_id,
        clinic_id: clinicId,
      })
      yield put({
        type: 'profile/SET_STATE',
        payload: {
          memberDetail: patientDetail,
        },
      })
      sleepStagesHistoryObject = {
        clinic_id: clinicId,
        patient_id: deviceObject.patient_id,
        device_id: deviceId,
      }
      sleepStagesFunction = getPatientSleepStagesHistory
      trendsFunction = getPatientTrendsData
    }
    const memberSleepStagesHistory = yield call(sleepStagesFunction, sleepStagesHistoryObject)

    const trendsData = yield call(trendsFunction, {
      device_id: deviceId,
      date: date.format('YYYY-MM-DD'),
      clinic_id: clinicId,
      user_type: 'sa',
    })
    const userTimezone = parseInt(memberSleepStagesHistory.tz_diff, 10)
    const { weeklyTrendsData, monthlyTrendsData } = formatTrendsData(trendsData)
    const reportSleepSessions = {}
    const [functionsList, dates] = [[], []]

    for (let i = 6; i >= 0; i -= 1) {
      const tempDate = moment(date).subtract(i, 'day').format('YYYY-MM-DD')
      dates.push(moment(date).subtract(i, 'day').format('YYYY-MM-DD'))
      functionsList.push(call(sleepStagesFunction, sleepStagesHistoryObject, tempDate))
    }
    const allFunctions = yield all(functionsList)

    allFunctions.map((deviceSleepStagesHistory, index) => {
      let absence = 0
      let tta = 0

      if (Object.keys(deviceSleepStagesHistory).length === 0) {
        weeklyReportData.Wake.push({ value: 0, view: '-' })
        weeklyReportData.REM.push({ value: 0, view: '-' })
        weeklyReportData.Light.push({ value: 0, view: '-' })
        weeklyReportData.Deep.push({ value: 0, view: '-' })
        reportSleepSessions[dates[index]] = {
          count: index,
          sleep: { startTime: null, endTime: null },
        }
        weeklyReportMetrics['REM Onset Latency'].push({
          view: '-',
          value: 0,
        })
        weeklyReportMetrics['Time in Bed'].push({
          view: '-',
          value: 0,
        })
        weeklyReportMetrics['Total Sleep Time'].push({
          view: '-',
          value: 0,
        })
        weeklyReportMetrics['Sleep Onset Latency'].push({
          view: '-',
          value: 0,
        })
        weeklyReportMetrics['Sleep Efficiency'].push({
          view: '-',
          value: 0,
        })
        weeklyReportMetrics['Time Awake'].push({
          view: '-',
          value: 0,
        }) /* 
        weeklyReportMetrics['TTA [minutes]'].push({
          view: '-',
          value: 0,
        }) */
        weeklyReportMetrics.Absence.push({
          view: '-',
          value: 0,
        })
      } else {
        monthlyTrendsData.map((datum) => {
          if (datum.metricKey === 'time_awaken') {
            datum.monthData.map((subDatum) => {
              if (subDatum.date === deviceSleepStagesHistory.date_of_sleep) {
                tta = subDatum.value
              }
              return false
            })
          }
          return false
        })

        const { levels } = deviceSleepStagesHistory
        const { env } = levels
        let lastPresenceDate
        const reportPresenceData = []

        env.map((datum, count) => {
          const dateTime = new Date(convertTimeOffset(datum.packet_dt, userTimezone))
          if (datum.presence === 0) {
            absence += 30
            if (!lastPresenceDate) {
              lastPresenceDate = dateTime
            }
            if (lastPresenceDate !== null && count === env.length - 1) {
              let endDate = new Date(convertTimeOffset(env[count - 1].packet_dt, userTimezone))

              if (lastPresenceDate === endDate) {
                endDate += 15000
              }
              reportPresenceData.push({
                startDate: lastPresenceDate,
                endDate,
              })
              lastPresenceDate = null
            }
            return true
          }
          if (lastPresenceDate) {
            let endDate = new Date(convertTimeOffset(env[count - 1].packet_dt, userTimezone))

            if (lastPresenceDate === endDate) {
              endDate += 15000
            }
            reportPresenceData.push({
              startDate: lastPresenceDate,
              endDate,
            })
            lastPresenceDate = null
          }
          return true
        })
        let color = 'none'

        weeklyReportData.Wake.push({
          value: Math.round(
            deviceSleepStagesHistory.levels.summary.W.avg > 0
              ? deviceSleepStagesHistory.levels.summary.W.avg
              : 0,
          ),
          view:
            deviceSleepStagesHistory.levels.summary.W.avg > 0
              ? Math.round(deviceSleepStagesHistory.levels.summary.W.avg)
              : '-',
        })
        if (
          deviceSleepStagesHistory.levels.summary.R.avg >= 20 &&
          deviceSleepStagesHistory.levels.summary.R.avg <= 20
        ) {
          color = 'table-success'
        } else if (deviceSleepStagesHistory.levels.summary.R.avg < 20) {
          color = 'table-danger'
        } else {
          color = 'none'
        }
        weeklyReportData.REM.push({
          value: Math.round(
            deviceSleepStagesHistory.levels.summary.R.avg > 0
              ? deviceSleepStagesHistory.levels.summary.R.avg
              : 0,
          ),
          view:
            deviceSleepStagesHistory.levels.summary.R.avg > 0
              ? Math.round(deviceSleepStagesHistory.levels.summary.R.avg)
              : '-',
          color,
        })
        if (
          deviceSleepStagesHistory.levels.summary.N.avg >= 40 &&
          deviceSleepStagesHistory.levels.summary.N.avg <= 45
        ) {
          color = 'table-success'
        } else if (deviceSleepStagesHistory.levels.summary.N.avg > 50) {
          color = 'table-danger'
        } else {
          color = 'none'
        }
        weeklyReportData.Light.push({
          value: Math.round(
            deviceSleepStagesHistory.levels.summary.N.avg > 0
              ? deviceSleepStagesHistory.levels.summary.N.avg
              : 0,
          ),
          view:
            deviceSleepStagesHistory.levels.summary.N.avg > 0
              ? Math.round(deviceSleepStagesHistory.levels.summary.N.avg)
              : '-',
          color,
        })

        if (
          deviceSleepStagesHistory.levels.summary.D.avg >= 20 &&
          deviceSleepStagesHistory.levels.summary.D.avg <= 25
        ) {
          color = 'table-success'
        } else if (deviceSleepStagesHistory.levels.summary.D.avg < 20) {
          color = 'table-danger'
        } else {
          color = 'none'
        }
        weeklyReportData.Deep.push({
          value: Math.round(
            deviceSleepStagesHistory.levels.summary.D.avg > 0
              ? deviceSleepStagesHistory.levels.summary.D.avg
              : 0,
          ),
          view:
            deviceSleepStagesHistory.levels.summary.D.avg > 0
              ? Math.round(deviceSleepStagesHistory.levels.summary.D.avg)
              : '-',
          color,
        })
        /* 
        const tempStartTime = moment.utc(deviceSleepStagesHistory.startTime)
        const tempEndTime = moment.utc(deviceSleepStagesHistory.endTime) */

        absence = Math.trunc(absence / 60)

        reportSleepSessions[deviceSleepStagesHistory.date_of_sleep] = {
          count: index,
          sleep: {
            startTime: deviceSleepStagesHistory.startTime,
            endTime: deviceSleepStagesHistory.endTime,
          },
        }

        const { metrics } = levels
        color = 'none'

        if (
          parseInt(metrics.StageRLatency, 10) <= 150 &&
          parseInt(metrics.StageRLatency, 10) >= 50
        ) {
          color = 'table-success'
        } else {
          color = 'none'
        }
        weeklyReportMetrics['REM Onset Latency'].push({
          view: metrics.StageRLatency > 0 ? `${Math.round(metrics.StageRLatency)}m` : '-',
          color,
          value: metrics.StageRLatency,
        })
        if (parseInt(metrics.TotalSleepTime, 10) + 30 === parseInt(metrics.TimeInBed, 10)) {
          color = 'table-success'
        } else if (parseInt(metrics.TotalSleepTime, 10) + 30 < parseInt(metrics.TimeInBed, 10)) {
          color = 'table-danger'
        } else {
          color = 'none'
        }

        weeklyReportMetrics['Time in Bed'].push({
          view: metrics.TimeInBed > 0 ? convertMinsToHrsMins(metrics.TimeInBed) : '-',
          color,
          value: metrics.TimeInBed,
        })

        if (
          parseInt(metrics.TotalSleepTime, 10) >= 7 * 60 &&
          parseInt(metrics.TotalSleepTime, 10) <= 9 * 60
        ) {
          color = 'table-success'
        } else if (parseInt(metrics.TotalSleepTime, 10) < 4 * 60) {
          color = 'table-error'
        } else {
          color = 'none'
        }

        weeklyReportMetrics['Total Sleep Time'].push({
          view: metrics.TotalSleepTime > 0 ? convertMinsToHrsMins(metrics.TotalSleepTime) : '-',
          value: metrics.TotalSleepTime,
          color,
        })

        if (
          parseInt(metrics.SleepOnsetLatency, 10) >= 15 &&
          parseInt(metrics.SleepOnsetLatency, 10) <= 20
        ) {
          color = 'table-success'
        } else if (parseInt(metrics.SleepOnsetLatency, 10) > 15) {
          color = 'table-danger'
        } else {
          color = 'none'
        }

        weeklyReportMetrics['Sleep Onset Latency'].push({
          view: metrics.SleepOnsetLatency > 0 ? `${Math.round(metrics.SleepOnsetLatency)}m` : '-',
          value: metrics.SleepOnsetLatency,
          color,
        })

        if (parseInt(metrics.SleepEfficiency, 10) > 85) {
          color = 'table-success'
        } else if (parseInt(metrics.SleepEfficiency, 10) < 80) {
          color = 'table-danger'
        } else {
          color = 'none'
        }
        weeklyReportMetrics['Sleep Efficiency'].push({
          view: metrics.SleepEfficiency ? `${Math.round(metrics.SleepEfficiency)}%` : '-',
          value: metrics.SleepEfficiency,
          color,
        })
        if (parseInt(metrics.WASO, 10) <= 30) {
          color = 'table-success'
        } else if (parseInt(metrics.WASO, 10) > 30) {
          color = 'table-danger'
        } else {
          color = 'none'
        }
        weeklyReportMetrics['Time Awake'].push({
          view: metrics.WASO > 0 ? `${Math.round(metrics.WASO)}m` : '-',
          value: metrics.WASO,
          color,
        })

        if (parseInt(tta, 10) < 50) {
          color = 'table-success'
        } else if (parseInt(tta, 10) > 60) {
          color = 'table-danger'
        } else {
          color = 'none'
        }

        /*  weeklyReportMetrics['TTA [minutes]'].push({
          view: tta > 0 ? Math.round(tta) : '-',
          value: tta,
          color,
        }) */

        color = 'none'

        weeklyReportMetrics.Absence.push({
          view: absence > 0 ? `${Math.round(absence)}m` : '-',
          value: absence,
        })
      }
      return true
    })

    const avgTST =
      weeklyReportMetrics['Total Sleep Time'].reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics['Total Sleep Time'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportMetrics['Total Sleep Time'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
    /*  const avgTTA =
      weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
    */ const avgSE =
      weeklyReportMetrics['Sleep Efficiency'].reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics['Sleep Efficiency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportMetrics['Sleep Efficiency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
    const avgSOL =
      weeklyReportMetrics['Sleep Onset Latency'].reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics['Sleep Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) ===
      0
        ? 1
        : weeklyReportMetrics['Sleep Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
    const avgROL =
      weeklyReportMetrics['REM Onset Latency'].reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics['REM Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportMetrics['REM Onset Latency'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
    const avgTimeInBed =
      weeklyReportMetrics['Time in Bed'].reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics['Time in Bed'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
        ? 0
        : weeklyReportMetrics['Time in Bed'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
    const avgWASO =
      weeklyReportMetrics['Time Awake'].reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics['Time Awake'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportMetrics['Time Awake'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
    const avgAbsence =
      weeklyReportMetrics.Absence.reduce((a, b) => a + b.value, 0) /
      (weeklyReportMetrics.Absence.reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportMetrics.Absence.reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))

    let color = 'none'
    if (parseInt(avgROL, 10) <= 150 && parseInt(avgROL, 10) >= 50) {
      color = 'table-success'
    } else {
      color = 'none'
    }
    weeklyReportMetrics['REM Onset Latency'].push({
      view: avgROL > 0 ? `${Math.round(avgROL)}m` : '-',
      value: avgROL,
      color,
    })

    if (parseInt(avgTST, 10) + 30 === parseInt(avgTimeInBed, 10)) {
      color = 'table-success'
    } else if (parseInt(avgTST, 10) + 30 < parseInt(avgTimeInBed, 10)) {
      color = 'table-danger'
    } else {
      color = 'none'
    }
    weeklyReportMetrics['Time in Bed'].push({
      view: avgTimeInBed > 0 ? convertMinsToHrsMins(avgTimeInBed) : '-',
      value: avgTimeInBed,
      color,
    })

    if (parseInt(avgTST, 10) >= 7 * 60 && parseInt(avgTST, 10) <= 9 * 60) {
      color = 'table-success'
    } else if (parseInt(avgTST, 10) < 4 * 60) {
      color = 'table-error'
    } else {
      color = 'none'
    }
    weeklyReportMetrics['Total Sleep Time'].push({
      view: avgTST > 0 ? convertMinsToHrsMins(avgTST) : '-',
      color,
      value: avgTST,
    })
    if (parseInt(avgSOL, 10) >= 15 && parseInt(avgSOL, 10) <= 20) {
      color = 'table-success'
    } else if (parseInt(avgSOL, 10) > 15) {
      color = 'table-danger'
    } else {
      color = 'none'
    }

    weeklyReportMetrics['Sleep Onset Latency'].push({
      view: avgSOL > 0 ? `${Math.round(avgSOL)}m` : '-',
      value: avgSOL,
      color,
    })

    if (parseInt(avgSE, 10) > 85) {
      color = 'table-success'
    } else if (parseInt(avgSE, 10) < 80) {
      color = 'table-danger'
    } else {
      color = 'none'
    }

    weeklyReportMetrics['Sleep Efficiency'].push({
      view: avgSE > 0 ? `${Math.round(avgSE)}%` : '-',
      color,
      value: avgSE,
    })
    if (parseInt(avgWASO, 10) <= 30) {
      color = 'table-success'
    } else if (parseInt(avgWASO, 10) > 30) {
      color = 'table-danger'
    } else {
      color = 'none'
    }
    weeklyReportMetrics['Time Awake'].push({
      view: avgWASO > 0 ? `${Math.round(avgWASO)}m` : '-',
      color,
      value: avgWASO,
    }) /* 
    if (parseInt(avgTTA, 10) < 50) {
      color = 'table-success'
    } else if (parseInt(avgTTA, 10) > 60) {
      color = 'table-danger'
    } else {
      color = 'none'
    }
    weeklyReportMetrics['TTA [minutes]'].push({
      view: avgTTA > 0 ? Math.round(avgTTA) : '-',
      color,
      value: avgTTA,
    }) */
    color = 'none'
    weeklyReportMetrics.Absence.push({
      view: avgAbsence > 0 ? `${Math.round(avgAbsence)}m` : '-',
      value: avgAbsence,
      color,
    })

    const WakeAvg =
      (weeklyReportData.Wake.reduce((a, { value: b }) => a + b, 0) === 0
        ? 0
        : weeklyReportData.Wake.reduce((a, { value: b }) => a + b, 0)) /
      (weeklyReportData.Wake.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportData.Wake.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
    const LightAvg =
      weeklyReportData.Light.reduce((a, { value: b }) => a + b, 0) /
      (weeklyReportData.Light.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportData.Light.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
    const DeepAvg =
      weeklyReportData.Deep.reduce((a, { value: b }) => a + b, 0) /
      (weeklyReportData.Deep.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportData.Deep.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
    const REMAvg =
      weeklyReportData.REM.reduce((a, { value: b }) => a + b, 0) /
      (weeklyReportData.REM.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
        ? 1
        : weeklyReportData.REM.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
    weeklyReportData.Wake.push({
      value: WakeAvg > 0 ? Math.round(WakeAvg) : 0,
      view: WakeAvg > 0 ? Math.round(WakeAvg) : '-',
      color,
    })
    if (REMAvg >= 20 && REMAvg <= 25) {
      color = 'table-success'
    } else if (REMAvg < 20) {
      color = 'table-danger'
    } else {
      color = 'none'
    }
    weeklyReportData.REM.push({
      value: REMAvg > 0 ? Math.round(REMAvg) : 0,
      view: REMAvg > 0 ? Math.round(REMAvg) : '-',
      color,
    })

    if (LightAvg >= 40 && LightAvg <= 45) {
      color = 'table-success'
    } else if (LightAvg > 50) {
      color = 'table-danger'
    } else {
      color = 'none'
    }

    weeklyReportData.Light.push({
      value: LightAvg > 0 ? Math.round(LightAvg) : 0,
      view: LightAvg > 0 ? Math.round(LightAvg) : '-',
      color,
    })

    if (DeepAvg >= 20 && DeepAvg <= 25) {
      color = 'table-success'
    } else if (DeepAvg < 20) {
      color = 'table-danger'
    } else {
      color = 'none'
    }

    weeklyReportData.Deep.push({
      value: DeepAvg > 0 ? Math.round(DeepAvg) : 0,
      view: DeepAvg > 0 ? Math.round(DeepAvg) : '-',
      color,
    })

    if (getReferences) {
      weeklyReportMetrics['REM Onset Latency'].push({
        view: '50-150m',
        value: 0,
        color,
      })

      weeklyReportMetrics['Time in Bed'].push({
        view: 'TST + 30m',
        value: '0',
        color,
      })
      weeklyReportMetrics['Total Sleep Time'].push({
        view: '7-9h',
        color,
        value: 0,
      })
      weeklyReportMetrics['Sleep Onset Latency'].push({
        view: '15-20m',
        value: '0',
        color,
      })
      weeklyReportMetrics['Sleep Efficiency'].push({
        view: `>85%`,
        color,
        value: 0,
      })

      weeklyReportMetrics['Time Awake'].push({
        view: '<30m',
        color,
        value: avgWASO,
      })

      weeklyReportMetrics.Absence.push({
        view: '-',
        value: avgAbsence,
        color,
      })

      weeklyReportData.Wake.push({
        value: WakeAvg > 0 ? Math.round(WakeAvg) : 0,
        view: '-',
        color,
      })

      weeklyReportData.REM.push({
        value: REMAvg > 0 ? Math.round(REMAvg) : 0,
        view: '20-25',
        color,
      })
      weeklyReportData.Light.push({
        value: LightAvg > 0 ? Math.round(LightAvg) : 0,
        view: '40-45',
        color,
      })
      weeklyReportData.Deep.push({
        value: DeepAvg > 0 ? Math.round(DeepAvg) : 0,
        view: '20-25',
        color,
      })
    }

    let weeklyReportMetricsSummary = weeklyTrendsData.map((datum) => {
      datum.graphData = datum.graphData.reverse()
      datum.weekData = datum.weekData.reverse()
      if (datum.metricKey === 'time_awaken') {
        datum.metric = 'TTA [minutes]'
      }
      return datum
    })
    weeklyReportMetricsSummary = weeklyReportMetricsSummary.filter(
      (datum) =>
        datum.metricKey !== 'percent_light' &&
        datum.metricKey !== 'percent_deep' &&
        datum.metricKey !== 'percent_rem',
    )
    delete weeklyReportMetrics['TTA [minutes]']

    return yield put({
      type: 'profile/SET_STATE',
      payload: {
        weeklyReportMetricsSummary,
        weeklyReportData,
        weeklyReportMetrics,
        reportSleepSessions,
        deviceID: deviceId,
        reportDataLoading: false,
      },
    })
  }

  // Member Profile COndition

  const weeklyReportMetrics = {
    'TST [hours]': [],
    'TTA [minutes]': [],
    'SE [%]': [],
    'SOL [minutes]': [],
    'ROL [minutes]': [],
    'Time in Bed [hours]': [],
    'WASO [minutes]': [],
    'Absence [minutes]': [],
    'CSI [%]': [],
  }
  const weeklyReportData = { Wake: [], REM: [], Light: [], Deep: [] }

  const memberSleepStagesHistory = yield call(getLastSleepStagesHistory, userUID)
  const deviceID = memberSleepStagesHistory.device_id
  const profile = yield select(getProfile)
  const { memberCSIHistory, memberDetail } = profile
  const userTimezone = memberDetail.tz_id
  const trendsData = yield call(getMetricsHistory, userUID)
  const { weeklyTrendsData, monthlyTrendsData } = formatTrendsData(trendsData)
  const reportSleepSessions = {}
  const [functionsList, dates] = [[], []]

  for (let i = 6; i >= 0; i -= 1) {
    const tempDate = moment(date).subtract(i, 'day').format('YYYY-MM-DD')
    dates.push(moment(date).subtract(i, 'day').format('YYYY-MM-DD'))
    functionsList.push(call(getMemberSleepStagesHistory, userUID, tempDate))
  }
  const allFunctions = yield all(functionsList)

  allFunctions.map((datum, index) => {
    let absence = 0
    let csi = 0
    let tta = 0
    if (datum.status === 404) {
      weeklyReportData.Wake.push({ value: 0 })
      weeklyReportData.REM.push({ value: 0 })
      weeklyReportData.Light.push({ value: 0 })
      weeklyReportData.Deep.push({ value: 0 })

      reportSleepSessions[dates[index]] = {
        count: index,
        sleep: { startTime: null, endTime: null },
      }

      weeklyReportMetrics['ROL [minutes]'].push({
        view: 0,

        value: 0,
      })
      weeklyReportMetrics['Time in Bed [hours]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['TST [hours]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['SOL [minutes]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['SE [%]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['WASO [minutes]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['TTA [minutes]'].push({
        view: Math.round(tta),
        value: tta,
      })
      weeklyReportMetrics['Absence [minutes]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['CSI [%]'].push({
        view: Math.round(csi),
        value: csi,
      })
    } else {
      memberCSIHistory.map((subDatum) => {
        if (subDatum.date === datum.date_of_sleep) {
          csi = subDatum.csi_final
        }
        return true
      })
      monthlyTrendsData.map((subDatum) => {
        if (subDatum.metricKey === 'time_awaken') {
          subDatum.monthData.map((subDatum2) => {
            if (subDatum2.date === datum.date_of_sleep) {
              tta = subDatum2.value
            }
            return true
          })
        }
        return true
      })

      const { levels } = datum
      const { env } = levels
      let lastPresenceDate
      const reportPresenceData = []

      env.map((subDatum, count) => {
        const dateTime = new Date(convertTime(subDatum.packet_dt, userTimezone))

        if (subDatum.presence === 0) {
          absence += 30
          if (!lastPresenceDate) {
            lastPresenceDate = dateTime
          }
          if (lastPresenceDate !== null && count === env.length - 1) {
            let endDate = new Date(convertTime(env[count - 1].packet_dt, userTimezone))

            if (lastPresenceDate === endDate) {
              endDate += 15000
            }
            reportPresenceData.push({
              startDate: lastPresenceDate,
              endDate,
            })
            lastPresenceDate = null
          }
          return true
        }

        if (lastPresenceDate) {
          let endDate = new Date(convertTime(env[count - 1].packet_dt, userTimezone))

          if (lastPresenceDate === endDate) {
            endDate += 15000
          }
          reportPresenceData.push({
            startDate: lastPresenceDate,
            endDate,
          })
          lastPresenceDate = null
        }
        return true
      })
      let color = 'none'
      weeklyReportData.Wake.push({
        value: Math.round(datum.levels.summary.W.avg > 0 ? datum.levels.summary.W.avg : 0),
        color,
      })
      if (datum.levels.summary.R.avg >= 20 && datum.levels.summary.R.avg <= 20) {
        color = 'table-success'
      } else if (datum.levels.summary.R.avg < 20) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportData.REM.push({
        value: Math.round(datum.levels.summary.R.avg > 0 ? datum.levels.summary.R.avg : 0),
        color,
      })

      if (datum.levels.summary.N.avg >= 40 && datum.levels.summary.N.avg <= 45) {
        color = 'table-success'
      } else if (datum.levels.summary.N.avg > 50) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportData.Light.push({
        value: Math.round(datum.levels.summary.N.avg > 0 ? datum.levels.summary.N.avg : 0),
        color,
      })

      if (datum.levels.summary.D.avg >= 20 && datum.levels.summary.D.avg <= 25) {
        color = 'table-success'
      } else if (datum.levels.summary.D.avg < 20) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportData.Deep.push({
        value: Math.round(datum.levels.summary.D.avg > 0 ? datum.levels.summary.D.avg : 0),
        color,
      })

      const tempStartTime = moment.utc(datum.startTime)
      const tempEndTime = moment.utc(datum.endTime)

      absence = Math.trunc(absence / 60)
      reportSleepSessions[datum.date_of_sleep] = {
        count: index,
        sleep: { startTime: tempStartTime, endTime: tempEndTime },
      }
      color = 'none'

      if (
        parseInt(datum.levels.metrics.StageRLatency, 10) <= 150 &&
        parseInt(datum.levels.metrics.StageRLatency, 10) >= 50
      ) {
        color = 'table-success'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['ROL [minutes]'].push({
        view: Math.round(datum.levels.metrics.StageRLatency),
        color,
        value: datum.levels.metrics.StageRLatency,
      })
      if (
        parseInt(datum.levels.metrics.TotalSleepTime, 10) + 30 ===
        parseInt(datum.levels.metrics.TimeInBed, 10)
      ) {
        color = 'table-success'
      } else if (
        parseInt(datum.levels.metrics.TotalSleepTime, 10) + 30 <
        parseInt(datum.levels.metrics.TimeInBed, 10)
      ) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['Time in Bed [hours]'].push({
        view: convertMinsToHrsMins(datum.levels.metrics.TimeInBed),
        color,
        value: datum.levels.metrics.TimeInBed,
      })

      if (
        parseInt(datum.levels.metrics.TotalSleepTime, 10) >= 7 * 60 &&
        parseInt(datum.levels.metrics.TotalSleepTime, 10) <= 9 * 60
      ) {
        color = 'table-success'
      } else if (parseInt(datum.levels.metrics.TotalSleepTime, 10) < 4 * 60) {
        color = 'table-error'
      } else {
        color = 'none'
      }

      weeklyReportMetrics['TST [hours]'].push({
        view: convertMinsToHrsMins(datum.levels.metrics.TotalSleepTime),
        color,
        value: datum.levels.metrics.TotalSleepTime,
      })
      if (
        parseInt(datum.levels.metrics.SleepOnsetLatency, 10) >= 15 &&
        parseInt(datum.levels.metrics.SleepOnsetLatency, 10) <= 20
      ) {
        color = 'table-success'
      } else if (parseInt(datum.levels.metrics.SleepOnsetLatency, 10) > 15) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['SOL [minutes]'].push({
        view: Math.round(datum.levels.metrics.SleepOnsetLatency),
        color,
        value: datum.levels.metrics.SleepOnsetLatency,
      })
      if (parseInt(datum.levels.metrics.SleepEfficiency, 10) > 85) {
        color = 'table-success'
      } else if (parseInt(datum.levels.metrics.SleepEfficiency, 10) < 80) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['SE [%]'].push({
        view: Math.round(datum.levels.metrics.SleepEfficiency),
        color,
        value: datum.levels.metrics.SleepEfficiency,
      })
      if (parseInt(datum.levels.metrics.WASO, 10) <= 30) {
        color = 'table-success'
      } else if (parseInt(datum.levels.metrics.WASO, 10) > 30) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['WASO [minutes]'].push({
        view: Math.round(datum.levels.metrics.WASO),
        color,
        value: datum.levels.metrics.WASO,
      })
      if (parseInt(tta, 10) < 50) {
        color = 'table-success'
      } else if (parseInt(tta, 10) > 60) {
        color = 'table-danger'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['TTA [minutes]'].push({
        view: Math.round(tta),
        color,
        value: tta,
      })
      color = 'none'
      weeklyReportMetrics['Absence [minutes]'].push({
        view: Math.round(absence),
        color,
        value: absence,
      })
      if (parseInt(csi, 10) > '70') {
        color = 'table-success'
      } else {
        color = 'none'
      }
      weeklyReportMetrics['CSI [%]'].push({
        view: Math.round(csi),
        color,
        value: csi,
      })
    }

    return true
  })
  const avgTST =
    weeklyReportMetrics['TST [hours]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['TST [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['TST [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgTTA =
    weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgSE =
    weeklyReportMetrics['SE [%]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['SE [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['SE [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgSOL =
    weeklyReportMetrics['SOL [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['SOL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['SOL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgROL =
    weeklyReportMetrics['ROL [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['ROL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['ROL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgTimeInBed =
    weeklyReportMetrics['Time in Bed [hours]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Time in Bed [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 0
      : weeklyReportMetrics['Time in Bed [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgWASO =
    weeklyReportMetrics['WASO [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['WASO [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['WASO [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgAbsence =
    weeklyReportMetrics['Absence [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Absence [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['Absence [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgCSI =
    weeklyReportMetrics['CSI [%]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['CSI [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['CSI [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))

  let color = 'none'
  if (parseInt(avgROL, 10) <= 150 && parseInt(avgROL, 10) >= 50) {
    color = 'table-success'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['ROL [minutes]'].push({
    view: Math.round(avgROL),
    color,
    value: avgROL,
  })
  if (parseInt(avgTST, 10) + 30 === parseInt(avgTimeInBed, 10)) {
    color = 'table-success'
  } else if (parseInt(avgTST, 10) + 30 < parseInt(avgTimeInBed, 10)) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['Time in Bed [hours]'].push({
    view: convertMinsToHrsMins(avgTimeInBed),
    color,
    value: avgTimeInBed,
  })

  if (parseInt(avgTST, 10) >= 7 * 60 && parseInt(avgTST, 10) <= 9 * 60) {
    color = 'table-success'
  } else if (parseInt(avgTST, 10) < 4 * 60) {
    color = 'table-error'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['TST [hours]'].push({
    view: convertMinsToHrsMins(avgTST),
    color,
    value: avgTST,
  })
  if (parseInt(avgSOL, 10) >= 15 && parseInt(avgSOL, 10) <= 20) {
    color = 'table-success'
  } else if (parseInt(avgSOL, 10) > 15) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportMetrics['SOL [minutes]'].push({
    view: Math.round(avgSOL),
    color,
    value: avgSOL,
  })
  if (parseInt(avgSE, 10) > 85) {
    color = 'table-success'
  } else if (parseInt(avgSE, 10) < 80) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportMetrics['SE [%]'].push({
    view: Math.round(avgSE),
    color,
    value: avgSE,
  })
  if (parseInt(avgWASO, 10) <= 30) {
    color = 'table-success'
  } else if (parseInt(avgWASO, 10) > 30) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['WASO [minutes]'].push({
    view: Math.round(avgWASO),
    color,
    value: avgWASO,
  })
  if (parseInt(avgTTA, 10) < 50) {
    color = 'table-success'
  } else if (parseInt(avgTTA, 10) > 60) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['TTA [minutes]'].push({
    view: Math.round(avgTTA),
    color,
    value: avgTTA,
  })
  color = 'none'
  weeklyReportMetrics['Absence [minutes]'].push({
    view: Math.round(avgAbsence),
    color,
    value: avgAbsence,
  })
  if (parseInt(avgCSI, 10) > '70') {
    color = 'table-success'
  } else {
    color = 'none'
  }
  weeklyReportMetrics['CSI [%]'].push({
    view: Math.round(avgCSI),
    color,
    value: avgCSI,
  })

  const WakeAvg =
    (weeklyReportData.Wake.reduce((a, { value: b }) => a + b, 0) === 0
      ? 0
      : weeklyReportData.Wake.reduce((a, { value: b }) => a + b, 0)) /
    (weeklyReportData.Wake.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Wake.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  const LightAvg =
    weeklyReportData.Light.reduce((a, { value: b }) => a + b, 0) /
    (weeklyReportData.Light.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Light.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  const DeepAvg =
    weeklyReportData.Deep.reduce((a, { value: b }) => a + b, 0) /
    (weeklyReportData.Deep.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Deep.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  const REMAvg =
    weeklyReportData.REM.reduce((a, { value: b }) => a + b, 0) /
    (weeklyReportData.REM.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.REM.reduce((a, { value: b }) => (b > 0 ? a + 1 : a), 0))
  color = 'none'
  weeklyReportData.Wake.push({ value: Math.round(WakeAvg), color })
  if (REMAvg >= 20 && REMAvg <= 25) {
    color = 'table-success'
  } else if (REMAvg < 20) {
    color = 'table-danger'
  } else {
    color = 'none'
  }
  weeklyReportData.REM.push({ value: Math.round(REMAvg), color })

  if (LightAvg >= 40 && LightAvg <= 45) {
    color = 'table-success'
  } else if (LightAvg > 50) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportData.Light.push({ value: Math.round(LightAvg), color })

  if (DeepAvg >= 20 && DeepAvg <= 25) {
    color = 'table-success'
  } else if (DeepAvg < 20) {
    color = 'table-danger'
  } else {
    color = 'none'
  }

  weeklyReportData.Deep.push({ value: Math.round(DeepAvg), color })

  const bodyClockObj = {
    userUID,
    graph: 'bodyclock_points',
  }
  const bodyClockImageObj = {
    userUID,
    graph: 'bodyclock',
  }
  const bodyClockProccessCObj = {
    userUID,
    graph: 'process_c',
  }
  const bodyClockPRCObj = {
    userUID,
    graph: 'prc',
  }
  const [
    memberBodyClockData,
    memberPRCImage,
    memberBodyClockImage,
    memberProcessCRequest,
  ] = yield all([
    call(getMemberBodyClock, bodyClockObj),
    call(getMemberBodyClock, bodyClockPRCObj),
    call(getMemberBodyClock, bodyClockImageObj),
    call(getMemberBodyClock, bodyClockProccessCObj),
  ])
  const memberProcessCImage = memberProcessCRequest.img ? memberProcessCRequest.img : ''
  const recommendation = memberProcessCRequest.recommendation
    ? memberProcessCRequest.recommendation
    : ''
  const bodyClockOffset = getOffsetFromRecommendation(recommendation)
  const CProcessData = yield call(formatCProcessData, memberBodyClockData)
  const timelineData = yield call(formatTimelineData, memberBodyClockData)
  const bodyclockSummary = yield call(formatBodyClockSummary, memberBodyClockData, bodyClockOffset)
  const formattedBodyClockData = { datasets: timelineData.concat(CProcessData) }

  let weeklyReportMetricsSummary = weeklyTrendsData.map((datum) => {
    datum.graphData = datum.graphData.reverse()
    datum.weekData = datum.weekData.reverse()
    if (datum.metricKey === 'time_awaken') {
      datum.metric = 'TTA [minutes]'
    }
    return datum
  })
  weeklyReportMetricsSummary = weeklyReportMetricsSummary.filter(
    (datum) =>
      datum.metricKey !== 'percent_light' &&
      datum.metricKey !== 'percent_deep' &&
      datum.metricKey !== 'percent_rem',
  )
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      weeklyReportMetricsSummary,
      weeklyReportData,
      weeklyReportMetrics,
      reportSleepSessions,
      memberBodyClockData,
      bodyclockSummary,
      memberBodyClockImage,
      memberProcessCImage,
      deviceID,
      memberPRCImage,
      memberBodyClockGraphData: formattedBodyClockData,
      reportDataLoading: false,
    },
  })
}

export const formatTrendsData = (data) => {
  const weeklyTrendsData = []
  const monthlyTrendsData = []
  const fortnightTrendsData = []
  Object.keys(data).map((key) => {
    let { weekDataSum, weekDataAvg, lastWeekDataSum, lastWeekDataAvg } = 0
    const weekData = data[key].slice(0, 7)
    const lastWeekData = data[key].slice(7, 14)
    let allWeekValues = weekData.filter((datum) => datum.value && true)
    let allLastWeekValues = lastWeekData.filter((datum) => datum.value && true)

    if (key === 'bedTime' || key === 'wakeTime') {
      allWeekValues = allWeekValues.map((datum) => {
        const { date } = datum
        const time = moment(datum.value).format('hh:mm:ss')
        const dateTime = moment(`${date} ${time}`).valueOf()
        return dateTime
      })
      allLastWeekValues = allLastWeekValues.map((datum) => {
        const { date } = datum
        const time = moment(datum.value).format('hh:mm:ss')
        const dateTime = moment(`${date} ${time}`).valueOf()
        return dateTime
      })

      weekDataSum = allWeekValues.length > 0 ? allWeekValues.reduce((a, b) => a + b) : 0
      weekDataAvg = allWeekValues.length ? weekDataSum / allWeekValues.length : 0

      lastWeekDataSum = allLastWeekValues.length > 0 ? allLastWeekValues.reduce((a, b) => a + b) : 0
      lastWeekDataAvg =
        allLastWeekValues.length !== 0 ? lastWeekDataSum / allLastWeekValues.length : 0

      return false
    }

    allWeekValues = allWeekValues.map((datum) => datum.value)
    allLastWeekValues = allLastWeekValues.map((datum) => datum.value)

    weekDataSum = allWeekValues.length > 0 ? allWeekValues.reduce((a, b) => a + b) : 0
    weekDataAvg = allWeekValues.length ? weekDataSum / allWeekValues.length : 0

    lastWeekDataSum = allLastWeekValues.length > 0 ? allLastWeekValues.reduce((a, b) => a + b) : 0
    lastWeekDataAvg =
      allLastWeekValues.length !== 0 ? lastWeekDataSum / allLastWeekValues.length : 0

    let { monthDataSum, monthDataAvg, lastMonthDataSum, lastMonthDataAvg } = 0

    const monthData = data[key].slice(0, 30)
    const lastMonthData = data[key].slice(30, 60)

    const monthGraphData = monthData.map((datum) => ({ t: new Date(datum.date), y: datum.value }))
    const weekGraphData = weekData.map((datum) => ({ t: new Date(datum.date), y: datum.value }))

    let allMonthValues = monthData.filter((datum) => datum.value && true)
    let allLastMonthValues = lastMonthData.filter((datum) => datum.value && true)

    allMonthValues = allMonthValues.map((datum) => datum.value)
    allLastMonthValues = allLastMonthValues.map((datum) => datum.value)

    monthDataSum = allMonthValues.length > 0 ? allMonthValues.reduce((a, b) => a + b) : 0
    monthDataAvg = allMonthValues.length ? monthDataSum / allMonthValues.length : 0

    lastMonthDataSum =
      allLastMonthValues.length > 0 ? allLastMonthValues.reduce((a, b) => a + b) : 0
    lastMonthDataAvg =
      allLastMonthValues.length !== 0 ? lastMonthDataSum / allLastMonthValues.length : 0

    let { fortnightDataSum, fortnightDataAvg, lastFortnightDataSum, lastFortnightDataAvg } = 0

    const fortnightData = data[key].slice(0, 14)
    const lastFortnightData = data[key].slice(14, 28)

    let allFortnightValues = fortnightData.filter((datum) => datum.value && true)
    let allLastFortnightValues = lastFortnightData.filter((datum) => datum.value && true)

    allFortnightValues = allFortnightValues.map((datum) => datum.value)
    allLastFortnightValues = allLastFortnightValues.map((datum) => datum.value)

    fortnightDataSum =
      allFortnightValues.length > 0 ? allFortnightValues.reduce((a, b) => a + b) : 0
    fortnightDataAvg = allFortnightValues.length ? fortnightDataSum / allFortnightValues.length : 0
    lastFortnightDataSum =
      allLastFortnightValues.length > 0 ? allLastFortnightValues.reduce((a, b) => a + b) : 0
    lastFortnightDataAvg =
      allLastFortnightValues.length !== 0 ? lastFortnightDataSum / allLastFortnightValues.length : 0

    const fortnightGraphData = fortnightData.map((datum) => ({
      t: new Date(datum.date),
      y: datum.value,
    }))

    let metricName = ''
    let type = ''
    if (key === 'sleep_efficiency') {
      metricName = 'Efficiency [%]'
      type = 'right'
    } else if (key === 'waso') {
      // old key time_to_fall_asleep
      metricName = 'WASO [minutes]'
      type = 'left'
    } else if (key === 'time_awaken') {
      metricName = 'Times Awake'
      type = 'left'
    } else if (key === 'total_sleep_time') {
      metricName = 'TST [hours]'
      type = 'left'
    } else if (key === 'time_to_fall_asleep') {
      metricName = 'SOL [minutes]'
      type = 'left'
    } else if (key === 'percent_rem') {
      metricName = 'REM [%]'
      type = 'right'
    } else if (key === 'percent_light') {
      metricName = 'Light [%]'
      type = 'right'
    } else if (key === 'percent_deep') {
      metricName = 'Deep [%]'
      type = 'right'
    } else if (key === 'csi') {
      metricName = 'CSI [%]'
      type = 'right'
    }
    fortnightTrendsData.push({
      metricKey: key,
      metric: metricName,
      type,
      weekData,
      graphData: fortnightGraphData,
      lastAvg: Math.round(lastFortnightDataAvg),
      avg: Math.round(fortnightDataAvg),
      trend: fortnightDataAvg > lastFortnightDataAvg ? 'positive' : 'negative',
      max: allFortnightValues.length > 0 ? Math.round(Math.max(...allFortnightValues)) : 0,
      min: allFortnightValues.length > 0 ? Math.round(Math.min(...allFortnightValues)) : 0,
    })
    weeklyTrendsData.push({
      metricKey: key,
      metric: metricName,
      weekData,
      type,
      graphData: weekGraphData,
      lastAvg: Math.round(lastWeekDataAvg),
      avg: Math.round(weekDataAvg),
      trend: weekDataAvg > lastWeekDataAvg ? 'positive' : 'negative',
      max: allWeekValues.length > 0 ? Math.round(Math.max(...allWeekValues)) : 0,
      min: allWeekValues.length > 0 ? Math.round(Math.min(...allWeekValues)) : 0,
    })
    monthlyTrendsData.push({
      metricKey: key,
      metric: metricName,
      monthData,
      type,
      graphData: monthGraphData,
      lastAvg: Math.round(lastMonthDataAvg),
      avg: Math.round(monthDataAvg),
      trend: monthDataAvg > lastMonthDataAvg ? 'positive' : 'negative',
      max: allMonthValues.length > 0 ? Math.round(Math.max(...allMonthValues)) : 0,
      min: allMonthValues.length > 0 ? Math.round(Math.min(...allMonthValues)) : 0,
    })
    return true
  })
  return { weeklyTrendsData, monthlyTrendsData, fortnightTrendsData }
}

export const formatBodyClockSummary = (data, bodyClockOffset) => {
  if (!data.timeline) {
    return []
  }
  const { timeline } = data
  const formattedBodyClockSummary = []
  let count = 1
  formattedBodyClockSummary.push({
    key: 1,
    head: 'Out-Of-Sync\n[Hours]',
    value: bodyClockOffset,
  })
  timeline.map((temp) => {
    if (
      temp.title === 'Melatonin Stop' ||
      temp.title === 'Highest Alertness' ||
      temp.title === 'Greatest Strength' ||
      temp.title === 'Fastest Reaction Time' ||
      temp.title === 'Melatonin Start'
    ) {
      count += 1
      return formattedBodyClockSummary.push({
        key: count,
        head: `${
          temp.title === 'Fastest Reaction Time' ? 'Fastest Reaction' : temp.title
        } \n [Time]`,
        value: temp.time_hhmm,
      })
    }

    return false
  })

  return formattedBodyClockSummary
}

export const bodyClockDiff = (bodyclockSummary, memberDetail) => {
  if (bodyclockSummary.length > 0) {
    const melatoninStop = bodyclockSummary[0].value
    let desiredWakeTime = memberDetail.desired_wake_time
    const time = moment.duration('00:30:00')
    if (desiredWakeTime === null || desiredWakeTime === '') {
      desiredWakeTime = '07:00 AM'
    }
    const date = moment(`2/27/2019 ${melatoninStop}`).subtract(time)
    const diffHours = moment.duration(date.diff(moment(`2/27/2019 ${desiredWakeTime}`)))
    let hours = diffHours.hours()
    if (Math.abs(diffHours.minutes()) > 30) {
      if (hours > 0) {
        hours += 1
      } else {
        hours -= 1
      }
    }
    const bodyClockDifference = `${hours}h`

    return bodyClockDifference
  }
  return '0h'
}

export const formatTimelineData = (data) => {
  if (!data.timeline) {
    return []
  }
  const timelineData = data.timeline
  const formattedTimelineData = []
  let trigger = 0
  timelineData.map((temp, count) => {
    if (count > 0) {
      if (
        new Date(`2019-01-13 ${timelineData[count - 1].time_hhmm}`).getHours() >
        new Date(`2019-01-13 ${timelineData[count].time_hhmm}`).getHours()
      ) {
        trigger += 1
      }
    }
    let color = 'orange'
    if (temp.title === 'Lowest Body Temp') {
      color = 'rgba(75,192,192,1)'
    } else if (temp.title === 'Melatonin Stop') {
      color = 'Red'
    } else if (temp.title === 'Highest Alertness') {
      color = 'Purple'
    } else if (temp.title === 'Fastest Reaction Time') {
      color = 'Brown'
    } else if (temp.title === 'Greatest Strength') {
      color = 'Pink'
    } else if (temp.title === 'Highest Body Temp') {
      color = 'Gray'
    } else if (temp.title === 'Melatonin Start') {
      color = '#33FF99'
    } else if (temp.title === 'Lowest Body Temp') {
      color = '#6699FF'
    }
    return formattedTimelineData.push({
      type: 'scatter',
      label: temp.title,
      backgroundColor: color,
      pointBorderColor: color,
      pointBackgroundColor: '#fff',
      pointBorderWidth: 10,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      fill: false,
      pointHitRadius: 10,
      yAxisID: 'y-axis-2',
      data: [{ x: new Date(`2019-01-${13 + trigger} ${temp.time_hhmm}`), y: temp.process_c_value }],
    })
  })
  return formattedTimelineData
}

export const formatCProcessData = (data) => {
  if (!data.process_c_with_ts_hhmm) {
    return []
  }
  const processCData = data.process_c_with_ts_hhmm
  const formattedProcessCData = []
  let trigger = 0
  processCData.map((temp, count) => {
    if (count > 0) {
      if (
        new Date(`2019-01-13 ${processCData[count - 1].timestamp_hhmm}`).getHours() >
        new Date(`2019-01-13 ${processCData[count].timestamp_hhmm}`).getHours()
      ) {
        trigger += 1
      }
    }

    return formattedProcessCData.push({
      x: new Date(`2019-01-${13 + trigger} ${temp.timestamp_hhmm}`),
      y: temp.process_c,
    })
  })
  const formattedGraphData = [
    {
      type: 'line',
      fill: false,
      spanGaps: true,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      label: 'Process C',
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointRadius: 1,
      pointHitRadius: 0,
      yAxisID: 'y-axis-2',
      data: formattedProcessCData,
    },
  ]
  return formattedGraphData
}

export function* toggleCalendar() {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      isSettingsOpen: true,
    },
  })
}

export function* STORE_HISTORY_NOTES({ payload }) {
  const { memberID } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      noteLoading: true,
      noteListLoading: true,
    },
  })
  const historyStored = yield call(storeHistory, payload)
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      noteLoading: false,
      historyStored,
    },
  })
  let getHistoryNotes = yield call(historyNotes, memberID)
  getHistoryNotes = getHistoryNotes.reverse()
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      noteLoading: false,
      noteListLoading: false,
      historyStored,
      getHistoryNotes,
    },
  })
}

export function* UPDATE_HISTORY_NOTE({ payload }) {
  const { memberID } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      noteListLoading: true,
    },
  })
  const historyUpdated = yield call(UpdateHistory, payload)
  let getHistoryNotes = yield call(historyNotes, memberID)
  getHistoryNotes = getHistoryNotes.reverse()
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      noteListLoading: false,
      historyUpdated,
      getHistoryNotes,
    },
  })
}

export function* GET_HISTORY_NOTES({ payload }) {
  const { userUID } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      noteListLoading: true,
    },
  })
  let getHistoryNotes = yield call(historyNotes, userUID)
  getHistoryNotes = getHistoryNotes.reverse()
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      noteListLoading: false,
      getHistoryNotes,
    },
  })
}

export function* GET_MEMBER_DIARY({ payload }) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      diaryLoading: true,
      memberDiary: [],
    },
  })
  let memberDiary = yield call(getMemberDiary, payload)
  if (memberDiary.length > 0) {
    memberDiary = formatDiary(memberDiary)
  }

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      diaryLoading: false,
      memberDiary,
    },
  })
}

export function* GET_MEMBER_AVG_SLEEP_METRICS({ payload }) {
  const { memberID } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      AVGMetrcisLoading: true,
    },
  })
  const memberAVGSleepMetrics = yield call(getMemberAVGSleepMetrics, memberID)

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      AVGMetrcisLoading: false,
      memberAVGSleepMetrics,
    },
  })
}

export const convertMinsToHrsMins = (
  mins,
  skipRangeCondition = false,
  onlyShowHoursIfAvailable = false,
) => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)

  if ((mins >= 24 * 60 || mins < 0) && !skipRangeCondition) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
  }
  const h = (mins / 60) | 0 // eslint-disable-line no-bitwise
  const m = mins % 60 | 0 // eslint-disable-line no-bitwise
  // if (mins > 30) {
  //   h += 1
  // }
  if (mins === 0) {
    return '0'
  }
  if (h === 0) {
    if (onlyShowHoursIfAvailable) {
      return `${moment.utc().minutes(m).format('mm[m]')}`
    }
    return `0h ${moment.utc().minutes(m).format('mm[m]')}`
  }
  return moment.utc().hours(h).minutes(m).format('h[h] mm[m]')
}

export const convertMinsToHrsMins2 = (mins) => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
  }
  let h = (mins / 60) | 0 // eslint-disable-line no-bitwise
  const m = mins % 60 | 0 // eslint-disable-line no-bitwise
  if (mins > 30) {
    h += 1
  }
  if (mins === 0 || mins === null) {
    return '0'
  }
  return moment.utc().hours(h).minutes(m).format('h')
}
const questionType = (text) => {
  let typeText = ''
  if (text === 1) {
    typeText = 'Onboarding'
  } else if (text === 2) {
    typeText = 'Morning'
  } else if (text === 3) {
    typeText = 'Evening'
  } else if (text === 4) {
    typeText = 'Daily four questions'
  } else if (text === 5) {
    typeText = 'Health Test'
  } else if (text === 6) {
    typeText = 'Subjective'
  }
  return typeText
}

const timeFormat = (time) => {
  const timeInFloating = parseFloat(time)
  const minsFloating = (timeInFloating % 1) * 60
  let mins = Math.trunc(minsFloating)
  let hours = time.split('.')[0]

  mins = mins < 10 ? `0${mins}` : mins
  hours = hours < 10 ? `0${hours}` : hours
  if (hours < 12) {
    hours = hours === '00' ? 12 : hours
    return `${hours}:${mins} AM`
  }
  hours -= 12
  return `${hours}:${mins} PM`
}

const formatAnswers = (data, records) => {
  const res = data.split(' ')
  if (res.length > 1 && res[1] === 'Hours') {
    const formatedTime = timeFormat(res[0])
    return formatedTime
  }

  if (records.q_type === 6) {
    const formatAsPercentage = `${Math.round(data * 100)}%`
    return formatAsPercentage
  }
  return data
}

export const formatDiary = (diaryData) => {
  const formateDiary = []
  diaryData.map((obj) => {
    let same = 0
    const item = {
      question_uid: obj.question_uid,
      qs_text: obj.qs_text,
      q_type: questionType(obj.q_type),
      value: formatAnswers(obj.value, obj),
      created_at: obj.created_at,
    }
    formateDiary.map((datum) => {
      if (obj.question_uid === datum.question_uid) {
        same = 1
        return true
      }
      return false
    })
    if (same !== 1) {
      formateDiary.push(item)
    }
    return true
  })

  const sortAsc = (a, b) => {
    if (a.q_type < b.q_type) return -1
    if (a.q_type > b.q_type) return 1
    return 0
  }
  formateDiary.sort(sortAsc)
  return formateDiary
}

export const formatAVGMetrcis = (data, timeZone) => {
  data.bt = data.bt !== undefined && data.bt !== null ? formateTime(data.bt, timeZone) : '-'
  data.rt = data.rt !== undefined && data.rt !== null ? formateTime(data.rt, timeZone) : '-'
  data.se = data.se !== undefined && data.se !== null ? `${Math.round(data.se)}%` : '-'
  data.tst = data.tst !== undefined && data.tst !== null ? convertMinsToHrsMins(data.tst) : '-'
  return data
}

export const formatSleepStagesHistory = (
  memberSleepStagesHistory,
  userTimezone,
  timezoneType = 'text',
) => {
  const { levels = {} } = memberSleepStagesHistory
  const { env = [], data = [] } = levels
  const { hypnogramData } = formatHypnogramData(data, userTimezone, timezoneType)
  const {
    noiseGraphData,
    motionGraphData,
    respiratoryGraphData,
    respiratoryVariableGraphData,
    absence,
    lightAMChartData,
    presenceData,
  } = formatEnvData(env, userTimezone, timezoneType, hypnogramData)
  return {
    hypnogramData,
    noiseGraphData,
    motionGraphData,
    respiratoryGraphData,
    respiratoryVariableGraphData,
    absence,
    lightAMChartData,
    presenceData,
  }
}

export const formatHypnogramData = (data, userTimezone, TimezoneType) => {
  const hypnogramData = []
  data.map((datum, count) => {
    let dateTime = convertTime(datum.created_at)
    if (TimezoneType === 'number') {
      dateTime = convertTimeOffset(datum.created_at, userTimezone)
    } else {
      dateTime = convertTime(datum.created_at, userTimezone)
    }
    if (datum.stage === 'W') {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 4,
        state: 'Wake',
      })
    } else if (datum.stage === 'D') {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 1,
        state: 'Deep',
      })
    } else if (datum.stage === 'R') {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 3,
        state: 'REM',
      })
    } else {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 2,
        state: 'Light',
      })
    }
    if (count === data.length - 1) {
      const lastDateTime = moment(dateTime, 'MM/DD/YYYY HH:mm:ss').add(datum.seconds, 'seconds')
      const lastY = hypnogramData[hypnogramData.length - 1].y
      const lastState = hypnogramData[hypnogramData.length - 1].state
      hypnogramData.push({
        t: new Date(lastDateTime),
        y: lastY,
        state: lastState,
      })
    }
    return true
  })
  return {
    hypnogramData,
  }
}

export const formatEnvData = (envData, userTimezone, timezoneType, hypnogramData = []) => {
  const noiseGraphData = []
  const motionGraphData = []
  const respiratoryGraphData = []
  const respiratoryVariableGraphData = []
  const lightAMChartData = []
  const presenceData = []
  let lastPresenceDate
  let lastRRValue = 0
  let lastRRv1Value = 0
  let lastMotionValue = 0
  let lastNoiseValue = 0
  let absence = 0
  envData.map((datum, count) => {
    let dateTime = new Date(convertTime(datum.packet_dt)).getTime()
    if (timezoneType === 'number') {
      dateTime = new Date(convertTimeOffset(datum.packet_dt, userTimezone)).getTime()
    } else {
      dateTime = new Date(convertTime(datum.packet_dt, userTimezone)).getTime()
    }
    const state = getSleepState(hypnogramData, dateTime)
    if (datum.presence === 0) {
      absence += 30

      if (!lastPresenceDate) {
        lastPresenceDate = new Date(dateTime)
      }
      if (datum.noice_max) {
        lastNoiseValue = datum.noice_max
      }

      /* We push Noise and Light Data on No Presence as they are Related to the Environment not the user, hence Presence of the User Doesnt matter */

      noiseGraphData.push({
        t: dateTime,
        y: lastNoiseValue,
        state,
      })
      lightAMChartData.push({
        t: dateTime,
        r: datum.illum_r_max,
        g: datum.illum_g_max,
        b: datum.illum_b_max,
        w: datum.illum_w_max,
        state,
      })

      /* We push 0 in rest of the values as they are Related to the user not the Environment */

      respiratoryGraphData.push({
        t: dateTime,
        y: '0',
        state,
      })
      respiratoryVariableGraphData.push({
        t: dateTime,
        y: '0',
        state,
      })
      motionGraphData.push({
        t: dateTime,
        y: '0',
        state,
      })
      if (lastPresenceDate !== null && count === envData.length - 1) {
        let endDate = new Date(convertTime(envData[count - 1].packet_dt))
        if (timezoneType === 'number') {
          endDate = new Date(convertTimeOffset(envData[count - 1].packet_dt, userTimezone))
        } else {
          endDate = new Date(convertTime(envData[count - 1].packet_dt, userTimezone))
        }

        if (
          lastPresenceDate.toDateString() === endDate.toDateString() &&
          lastPresenceDate.toTimeString() === endDate.toTimeString()
        ) {
          endDate = new Date(moment(endDate).add(20, 'second'))
        }
        presenceData.push({
          startDate: lastPresenceDate,
          endDate,
        })

        lastPresenceDate = null
      }
      return true
    }
    if (lastPresenceDate) {
      let endDate = new Date(convertTime(envData[count - 1].packet_dt))
      if (timezoneType === 'number') {
        endDate = new Date(convertTimeOffset(envData[count - 1].packet_dt, userTimezone))
      } else {
        endDate = new Date(convertTime(envData[count - 1].packet_dt, userTimezone))
      }
      if (
        lastPresenceDate.toDateString() === endDate.toDateString() &&
        lastPresenceDate.toDateString() === endDate.toDateString()
      ) {
        endDate = new Date(moment(endDate).add(20, 'second'))
      }
      presenceData.push({
        startDate: lastPresenceDate,
        endDate,
      })

      lastPresenceDate = null
    }

    if (datum.noice_max) {
      lastNoiseValue = datum.noice_max.toFixed(2)
    }
    noiseGraphData.push({
      t: dateTime,
      y: lastNoiseValue,
      state,
    })

    if (datum.trackerRR) {
      lastRRValue = datum.trackerRR
    }
    respiratoryGraphData.push({
      t: dateTime,
      y: lastRRValue.toFixed(2),
      value: lastRRValue.toFixed(2),
      state,
    })

    if (datum.trackerRR_V1) {
      lastRRv1Value = datum.trackerRR_V1
    }
    respiratoryVariableGraphData.push({
      t: dateTime,
      y: lastRRv1Value.toFixed(2),
      value: lastRRv1Value.toFixed(2),
      state,
    })

    lightAMChartData.push({
      t: dateTime,
      r: datum.illum_r_max.toFixed(2),
      g: datum.illum_g_max.toFixed(2),
      b: datum.illum_b_max.toFixed(2),
      w: datum.illum_w_max.toFixed(2),
      state,
    })

    if (datum.tracker_mvmnt_V2_amp) {
      lastMotionValue = datum.tracker_mvmnt_V2_amp
    }
    motionGraphData.push({
      t: dateTime,
      y: lastMotionValue.toFixed(2),
      state,
    })

    return true
  })
  absence = Math.trunc(absence / 60)

  return {
    noiseGraphData,
    motionGraphData,
    respiratoryGraphData,
    respiratoryVariableGraphData,
    absence,
    lightAMChartData,
    presenceData,
  }
}

export const getSleepState = (hypnogramData, dateTime) => {
  let state = ''
  for (let i = 0; i < hypnogramData.length; i += 1) {
    if (hypnogramData[i + 1]) {
      if (
        moment(dateTime).isBetween(
          moment(hypnogramData[i].t),
          moment(hypnogramData[i + 1].t),
          'seconds',
        )
      ) {
        const { state: hypnogramState } = hypnogramData[i]
        state = hypnogramState

        break
      }
    } else {
      const { state: hypnogramState } = hypnogramData[i]
      state = hypnogramState
    }
  }
  return state
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_MEMBER_DETAIL, GET_MEMBER_DETAIL),
    takeEvery(actions.GET_MEMBER_BODY_CLOCK, GET_MEMBER_BODY_CLOCK),
    takeEvery(actions.GET_MEMBER_TRENDS, GET_MEMBER_TRENDS),
    takeEvery(actions.STORE_HISTORY_NOTES, STORE_HISTORY_NOTES),
    takeEvery(actions.GET_HISTORY_NOTES, GET_HISTORY_NOTES),
    takeEvery(actions.GET_NIGHT_BY_NIGHT_DATA, GET_NIGHT_BY_NIGHT_DATA),
    takeEvery(actions.GET_MEMBER_DIARY, GET_MEMBER_DIARY),
    takeEvery(actions.UPDATE_HISTORY_NOTE, UPDATE_HISTORY_NOTE),
    takeEvery(actions.GET_WEEKLY_REPORT_DATA, GET_WEEKLY_REPORT_DATA),
    takeEvery(actions.GET_DAILY_REPORT_DATA, GET_DAILY_REPORT_DATA),
    takeEvery(actions.GET_MEMBER_AVG_SLEEP_METRICS, GET_MEMBER_AVG_SLEEP_METRICS),
    takeEvery(actions.GENERATE_CLINIC_REPORT, GENERATE_CLINIC_REPORT),
  ])
}
