import { all, put, call, takeEvery, select, take } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import actions, { getNotifications } from './actions'

const getUser = state => state.user

const getStateNotifications = state => state.notifications

function countdown(secs) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      secs -= 1
      if (secs > 0) {
        emitter(secs)
      } else {
        // this causes the channel to close
        emitter(END)
      }
    }, 1000)
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv)
    }
  })
}

export function* GET_NOTIFICATIONS({ payload }) {
  yield put({
    type: 'notifications/SET_STATE',
    payload: {
      loading: true,
    },
  })
  let { notificationsList } = yield select(getStateNotifications)

  const { role, details } = yield select(getUser)
  if (Object.keys(details).length === 0) {
    yield put({
      type: 'notifications/SET_STATE',
      payload: {
        loading: false,
        notificationsList: [],
      },
    })
    try {
      while (true) {
        yield take(yield call(countdown, 60))
      }
    } finally {
      /*  if (role === 'Clinic') {
        yield call(GET_CLINIC_DEVICES, { payload: {} })
      } */
      yield call(GET_NOTIFICATIONS, { payload: {} })
    }
  }
  let data = payload
  if (Object.keys(data).length === 0) {
    data = {}
    if (role === 'Superadmin') {
      data.user_type = 'sa'
    }
    if (notificationsList.length > 0) {
      data.id = notificationsList[notificationsList.length - 1].id
        ? notificationsList[notificationsList.length - 1].id
        : null
    }
  }

  const tempNotificationsList = yield call(getNotifications, data)
  notificationsList = [...notificationsList, ...tempNotificationsList]
  yield put({
    type: 'notifications/SET_STATE',
    payload: {
      loading: false,
      notificationsList,
    },
  })
  try {
    while (true) {
      yield take(yield call(countdown, 60))
    }
  } finally {
    yield call(GET_NOTIFICATIONS, { payload: {} })
  }
}

// Assigning constant Strings to Saga Functions, in order to access Saga Functions from Redux
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NOTIFICATIONS, GET_NOTIFICATIONS),
    call(GET_NOTIFICATIONS, { payload: {} }),
  ])
}
