import actions from './actions'

// Defining the Initial Variables to be saved in the Redux Store on initialize.
const initialState = {
  devices: [],
  loading: false,
  lastTimezone: 0,
  deviceActivity: [],
  reportDeviceSessions: [],
  redirect: false,
  formLoading: false,
  deviceForm: '',
  inventoryLoading: false,
  inventoryDevices: [],
  inventoryDevicesList: [],
  modalLoading: false,
  modalVisible: false,
  assignModalLoading: false,
  assignModalVisible: false,
  deviceDetail: [],
  deviceSessions: [],
  patientLinkLoading: false,
  patientModalVisible: false,
  patientSessionDates: [],
  devicePatients: [],
  devicePatientsLoading: false,
  patientSessionsLoading: false,
}

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
