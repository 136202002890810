import API from '@aws-amplify/api'
import { notification } from 'antd'
import { apiPaths } from '../../config'

const actions = {
  SET_STATE: 'clinicPatient/SET_STATE',
  GET_CLINIC_PATIENTS: 'clinicPatient/GET_CLINIC_PATIENTS',
  LINK_SESSION_TO_PATIENT: 'clinicPatient/LINK_SESSION_TO_PATIENT',
  ADD_CLINIC_PATIENT: 'clinicPatient/ADD_CLINIC_PATIENT',
  GET_PATIENT_PROFILE: 'clinicPatient/GET_PATIENT_PROFILE',
  GET_PATIENT_BODY_CLOCK: 'clinicPatient/GET_PATIENT_BODY_CLOCK',
  GET_AVAILABLE_DEVICES: 'clinicPatient/GET_AVAILABLE_DEVICES',
  UNASSIGN_DEVICE: 'clinicPatient/UNASSIGN_DEVICE',
  GET_PATIENT_DETAIL: 'clinicPatient/GET_PATIENT_DETAIL',
  UPDATE_PATIENT: 'clinicPatient/UPDATE_PATIENT',
  DELETE_PATIENT: 'clinicPatient/DELETE_PATIENT',
  DISCHARGE_PATIENT: 'clinicPatient/DISCHARGE_PATIENT',
  READMIT_PATIENT: 'clinicPatient/READMIT_PATIENT',
  GET_PATIENT_IDS: 'clinicPatient/GET_PATIENT_IDS',
  GET_PATIENT_SESSIONS: 'clinicPatient/GET_PATIENT_SESSIONS',
  GET_NIGHT_BY_NIGHT_DATA: 'clinicPatient/GET_NIGHT_BY_NIGHT_DATA',
}

export const getClinicPatients = (role, data) =>
  API.post('virtualClinic', apiPaths[role].apiClinicPatientsListing, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: data,
  })
    .then((res) => res)
    .catch(() => [])

export const linkSessionToPatient = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiLinkSessionToPatient, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then((res) => {
      notification.success({
        message: `Success`,
        description: 'Patient Linked Successfully',
      })
      return res
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return []
    })

export const addClinicPatient = (dataObj, role) =>
  API.post('virtualClinic', apiPaths[role].apiAddClinicPatient || '', {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: dataObj,
  })
    .then((res) => {
      notification.success({
        message: `Success`,
        description: 'Patient Added Successfully',
      })
      return { res, success: true }
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return { success: false }
    })

export const getPatientSleepSessions = async (role, data) =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiPatientSleepSessions !== undefined
      ? apiPaths[role].apiPatientSleepSessions
      : '',
    {
      headers: {},
      queryStringParameters: data,
    },
  )
    .then((res) => res)
    .catch(() => [])

export const getPatientSleepStagesHistory = async (data, date) =>
  API.get(
    'virtualClinic',
    apiPaths.All.apiPatientSleepStagesHistory !== undefined
      ? apiPaths.All.apiPatientSleepStagesHistory
      : '',
    {
      headers: {},
      queryStringParameters: date ? { ...data, date_of_sleep: date } : data,
    },
  )
    .then((res) => res)
    .catch(() => [])

export const getPatientTrendsData = async (data) =>
  API.get(
    'virtualClinic',
    apiPaths.All.apiPatientTrends !== undefined ? apiPaths.All.apiPatientTrends : '',
    {
      headers: {},
      queryStringParameters: data,
    },
  )
    .then((res) => res)
    .catch(() => [])

export const getPatientCircadianAnalysis = async (role, data) =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiPatientCircadianAnalysis !== undefined
      ? apiPaths[role].apiPatientCircadianAnalysis
      : '',
    {
      headers: {},
      queryStringParameters: data,
    },
  )
    .then((res) => res)
    .catch(() => [])

export const getAvailableDevices = async (role, data) =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiAvailableDevicesList !== undefined
      ? apiPaths[role].apiAvailableDevicesList
      : '',
    {
      headers: {},
      queryStringParameters: data,
    },
  )
    .then((res) => res)
    .catch(() => [])

export const unassignDeviceFromPatient = async (role, data, showNotification = true) =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiUnassignDevice !== undefined ? apiPaths[role].apiUnassignDevice : '',
    {
      headers: {},
      queryStringParameters: data,
    },
  )
    .then((res) => {
      if (showNotification) {
        notification.success({
          message: `Success`,
          description: 'Device unassigned successfully',
        })
      }
      return { res, success: true }
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return { success: false }
    })

export const updatePatient = async (role, data, showNotification = true) =>
  API.post('virtualClinic', apiPaths[role].apiPatientUpdate, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: data,
  })
    .then((res) => {
      if (showNotification) {
        notification.success({
          message: `Success`,
          description: 'Patient Updated Successfully',
        })
      }
      return { res, success: true }
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return { success: false }
    })

export const dischargePatient = async (role, data, showNotification = true) =>
  API.post('virtualClinic', apiPaths[role].apiPatientDischarge, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: data,
  })
    .then((res) => {
      if (showNotification) {
        notification.success({
          message: `Success`,
          description: 'Patient discharge Successfully',
        })
      }
      return { res, success: true }
    })
    .catch((err) => {
      const { response } = err
      console.log('response', JSON.stringify(response))
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return { success: false }
    })
export const reAdmitPatient = async (role, data, showNotification = true) =>
  API.post('virtualClinic', apiPaths[role].apiPatientReAdmit, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: data,
  })
    .then((res) => {
      if (showNotification) {
        notification.success({
          message: `Success`,
          description: 'Patient Updated Successfully',
        })
      }
      return { res, success: true }
    })
    .catch((err) => {
      const { response } = err
      notification.error({
        message: `Someting went wrong`,
        description: `${response && response.data ? response.data : err}`,
      })
      return { success: false }
    })

export const deletePatient = async (role, data) =>
  API.post('virtualClinic', apiPaths[role].apiPatientDelete, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: data,
  })
    .then((res) => res)
    .catch(() => [])

export const getPatientIdsForDevice = async (role, data) =>
  API.get(
    'virtualClinic',
    apiPaths[role].apiPatientIds !== undefined ? apiPaths[role].apiPatientIds : '',
    {
      headers: {},
      queryStringParameters: data,
    },
  )
    .then((res) => ({ response: res, success: true }))
    .catch((err) => {
      const { response } = err
      notification.error({
        description: `${response && response.data ? response.data : err}`,
      })
      return { response: err, success: false }
    })

export default actions
